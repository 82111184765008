import { useContext, useEffect, useRef, useState } from "react";

import { East } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Button, TableCell, Typography } from "@mui/material";
import { ContentState, convertFromRaw, Editor, EditorState } from "draft-js";
import { useLocation, useNavigate } from "react-router-dom";

import { Column } from "./PlanerTable";
import {
  AccessGroupContext,
  AccessGroupContextType,
  setGroup,
} from "../../../components/contexts/AccessGroupContext";
import {
  SoknContext,
  SoknContextType,
} from "../../../components/contexts/SoknContext";
import { decorator } from "../../../components/TekstEditor/decorator";
import kirkeTheme from "../../../utils/kirkeTheme";
import { PlanerData, SoknType } from "../../../utils/types";
import { useGetSoknByPlanId } from "../../AlleSoknPage/api/useGetSoknByPlanId";

const styles = {
  open: {
    height: "100%",
    transition: kirkeTheme.transitions.create(["height"], {
      duration: kirkeTheme.transitions.duration.short,
      easing: kirkeTheme.transitions.easing.easeInOut,
    }),
  },
  openButton: {
    color: kirkeTheme.palette.common.black,
    paddingLeft: 0,
  },
  buttonText: {
    marginLeft: kirkeTheme.spacing(1),
    paddingBottom: 0,
    textTransform: "none",
    textDecoration: "underline",
    color: "blue",
  },
  close: {
    maxHeight: "200px",
    overflow: "hidden",
    transition: kirkeTheme.transitions.create(["height"], {
      duration: kirkeTheme.transitions.duration.short,
      easing: kirkeTheme.transitions.easing.easeInOut,
    }),
  },
  btnWrapper: {
    width: kirkeTheme.spacing(14),
  },
  btn: {
    textTransform: "none",
    width: "100%",
    marginTop: kirkeTheme.spacing(2),
    marginBottom: kirkeTheme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    background: kirkeTheme.palette.common.black,
  },
};

interface Props {
  plan: PlanerData;
  column: Column;
}

interface CustomizedState {
  goBack: boolean;
}

const PlanerRow = ({ plan, column }: Props) => {
  const [showButton, setShowButton] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const textRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const [planId, setPlanId] = useState<string>("");
  const { setAccessGroup } = useContext(
    AccessGroupContext
  ) as AccessGroupContextType;
  const { setSokn, setPlaner, setValgtPlan } = useContext(
    SoknContext
  ) as SoknContextType;

  const isOverflow = (e: any) => e.offsetHeight < e.scrollHeight;
  const isPlanTekst =
    column.id === "beskrivelseLokalsamfunn" ||
    column.id === "beskrivelseRessurser" ||
    column.id === "beskrivelseUtfordringer";

  useEffect(() => {
    if (textRef) {
      setShowButton(isOverflow(textRef.current));
    }
  }, [textRef]);

  const editor = (value: string | null) => {
    if (value === null) {
      return EditorState.createEmpty();
    }
    if (!value.startsWith("{")) {
      return EditorState.createWithContent(ContentState.createFromText(value));
    }
    return EditorState.createWithContent(
      convertFromRaw(JSON.parse(value)),
      decorator
    );
  };

  const formaterPlanData = () => {
    if (column.id === "planperiode") {
      return `${plan.startAar} - ${plan.sluttAar}`;
    }
    if (
      column.id === "planlegging" ||
      column.id === "beskrivelseLokalsamfunn" ||
      column.id === "beskrivelseRessurser" ||
      column.id === "beskrivelseUtfordringer"
    ) {
      return (
        <Editor
          readOnly
          onChange={() => null}
          editorState={editor(plan[column.id])}
        />
      );
    } else {
      return plan[column.id];
    }
  };

  const { data: sokn } = useGetSoknByPlanId(planId);
  const convertHash = () => {
    switch (column.id) {
      case "planlegging":
        return "planlegging";
      case "beskrivelseLokalsamfunn":
        return "menighetOgLokalsamfunn";
      case "beskrivelseRessurser":
        return "menighetensRessurser";
      case "beskrivelseUtfordringer":
        return "utfordringer";
      default:
        return null;
    }
  };

  useEffect(() => {
    if (sokn) {
      setSokn && setSokn(sokn as SoknType);
      setPlaner && setPlaner([]);
      setValgtPlan && setValgtPlan(undefined);
      setGroup(sokn, setAccessGroup);
      if (location.state) {
        const { goBack } = location.state as CustomizedState;
        if (goBack) {
          navigate(-1);
        } else {
          navigate("/plan#" + convertHash());
        }
      } else {
        navigate("/plan#" + convertHash());
      }
    }
  }, [sokn]);

  return (
    <TableCell
      key={column.id}
      align={column.align}
      width={column.minWidth}
      sx={{ paddingLeft: 0 }}
    >
      <Box sx={showMore ? styles.open : styles.close} ref={textRef}>
        <Typography sx={{ wordBreak: "keep-all" }}>
          {formaterPlanData()}
        </Typography>
      </Box>
      {showButton && (
        <Box sx={styles.btnWrapper}>
          <Button
            variant="contained"
            sx={styles.btn}
            endIcon={
              showMore ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
            }
            onClick={() => setShowMore(!showMore)}
          >
            <b>{showMore ? "Mindre" : "Mer"}</b>
          </Button>
        </Box>
      )}
      {isPlanTekst && (
        <Button
          sx={styles.openButton}
          endIcon={<East />}
          onClick={() => setPlanId(plan.id)}
        >
          <Typography sx={styles.buttonText}>Gå til</Typography>
        </Button>
      )}
    </TableCell>
  );
};

export default PlanerRow;
