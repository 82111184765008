import { useContext, useState } from "react";

import { AccountInfo } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import ChurchIcon from "@mui/icons-material/Church";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import SettingsIcon from "@mui/icons-material/Settings";
import SummarizeIcon from "@mui/icons-material/Summarize";
import {
  MenuItem,
  Typography,
  Divider,
  Menu,
  Avatar,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { ledeteksterQuery, useFetchSanityData } from "../../api";
import { getBispedommeContext } from "../../pages/AlleTallPage/AlleTallPage";
import kirkeTheme from "../../utils/kirkeTheme";
import {
  AccessGroupContext,
  AccessGroupContextType,
} from "../contexts/AccessGroupContext";
import { SoknContext, SoknContextType } from "../contexts/SoknContext";
import { PERMISSIONS, SCOPES } from "../Tilgangsstyring/tilganger";
import Tilgangsstyring from "../Tilgangsstyring/Tilgangsstyring";

const styles = {
  settingsBtn: {
    color: kirkeTheme.palette.common.black,
    textTransform: "none",
    textAlign: "left",
  },
  menuItem: {
    borderRadius: kirkeTheme.spacing(1),
    margin: kirkeTheme.spacing(1),
    minWidth: "260px",
  },
  menuItemPerson: {
    "&.Mui-disabled": {
      opacity: "1",
    },
  },
  menuItemText: {
    paddingLeft: kirkeTheme.spacing(2),
  },
  displaySokn: {
    paddingTop: kirkeTheme.spacing(0.5),
    paddingRight: kirkeTheme.spacing(3),
    color: kirkeTheme.palette.common.black,
  },
  avatarSmall: {
    bgcolor: "#B4D6FC",
    color: "black",
    fontWeight: "1000",
    fontSize: "14px",
    height: "30px",
    width: "30px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  avatarBig: {
    bgcolor: "#B4D6FC",
    color: "black",
    fontWeight: "1000",
  },
};

const Innstillinger = () => {
  const { instance } = useMsal();
  const { accessGroup } = useContext(
    AccessGroupContext
  ) as AccessGroupContextType;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const { sokn, oneSoknAccess, zeroSoknAccess } = useContext(
    SoknContext
  ) as SoknContextType;
  const hasBispedommeAccess = getBispedommeContext().length > 0;
  const { data: ledetekster } = useFetchSanityData(
    ledeteksterQuery,
    "ledeteksterQuery"
  );

  const userInfo = instance.getActiveAccount() as AccountInfo;
  let userInitials = "UU";
  if (userInfo?.name != null) {
    const names = userInfo.name.split(" ");
    userInitials = names[0][0] + names[names.length - 1][0];
  }

  const handleLogout = (logoutType: string) => {
    localStorage.removeItem("sokn");
    localStorage.removeItem("groups");
    setAnchorEl(null);

    if (logoutType === "popup") {
      instance.logoutPopup({
        mainWindowRedirectUri: "/",
      });
    } else if (logoutType === "redirect") {
      instance.logoutRedirect();
    }
  };

  const isOnAlleSoknPage = window.location.pathname === "/";
  const isOnAlleDataPage = window.location.pathname === "/data";
  const isOnMobileDevice = useMediaQuery(kirkeTheme.breakpoints.down("md"));

  const tilgang = PERMISSIONS[accessGroup?.toUpperCase()];

  return (
    <>
      {/* Skal det vises hvilket sokn man er i?  */}
      {!isOnAlleSoknPage && !isOnAlleDataPage && !isOnMobileDevice && (
        <Typography
          className="data-hj-suppress"
          data-testid="innstillinger-soknnavn"
          sx={styles.displaySokn}
        >
          {sokn?.navn}
        </Typography>
      )}
      <Avatar
        className="data-hj-suppress"
        data-testid="innstillinger-avatar"
        onClick={(event) => setAnchorEl(event.currentTarget)}
        onKeyPress={(e) => {
          e.key === "Enter" && setAnchorEl(e.currentTarget);
        }}
        aria-label="settings-button"
        tabIndex={0}
        sx={styles.avatarSmall}
      >
        {userInitials}
      </Avatar>
      <Menu
        sx={{ marginTop: kirkeTheme.spacing(1.5) }}
        id="menu-appbar"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          sx={(styles.menuItem, styles.menuItemPerson)}
          disabled
          className="data-hj-suppress"
        >
          <Avatar sx={styles.avatarBig}>{userInitials}</Avatar>
          <Grid
            container
            flexDirection="column"
            display="flex"
            sx={styles.menuItemText}
          >
            <Grid item>
              <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                {userInfo?.name}
              </Typography>
            </Grid>
            {!isOnAlleSoknPage && !isOnAlleDataPage && (
              <Grid item>
                <Typography>{tilgang?.at && tilgang?.at(-1)}</Typography>
              </Grid>
            )}
          </Grid>
        </MenuItem>
        {!oneSoknAccess && <Divider />}
        {!isOnAlleSoknPage && !isOnAlleDataPage && isOnMobileDevice && (
          <Tilgangsstyring scopes={[SCOPES.adminTilgang]}>
            <MenuItem
              onClick={() => navigate("/Administrasjon")}
              sx={styles.menuItem}
            >
              <SettingsIcon />
              <Typography sx={styles.menuItemText}>
                {ledetekster?.administrerSide || "Administrer side"}
              </Typography>
            </MenuItem>
          </Tilgangsstyring>
        )}
        {!isOnAlleSoknPage && !oneSoknAccess && (
          <MenuItem onClick={() => navigate("/")} sx={styles.menuItem}>
            <ChurchIcon />
            <Typography sx={styles.menuItemText}>
              {ledetekster?.tilMineSokn || "Til mine sokn"}
            </Typography>
          </MenuItem>
        )}
        {isOnAlleSoknPage && !oneSoknAccess && !zeroSoknAccess && (
          <MenuItem onClick={() => navigate("/data")} sx={styles.menuItem}>
            <ChurchIcon />
            <Typography sx={styles.menuItemText}>
              {ledetekster?.seAlleData || "Se all data"}
            </Typography>
          </MenuItem>
        )}
        {isOnAlleSoknPage && hasBispedommeAccess && (
          <MenuItem onClick={() => navigate("/tall")} sx={styles.menuItem}>
            <SummarizeIcon />
            <Typography sx={styles.menuItemText}>
              {/* {ledetekster?.seAlleData ||*/ "Se alle tall"}
            </Typography>
          </MenuItem>
        )}
        <Divider />
        <MenuItem
          sx={styles.menuItem}
          onClick={() => handleLogout("redirect")}
          key="logoutRedirect"
        >
          <PowerSettingsNewIcon />
          <Typography
            data-testid="innstillinger-logg-ut"
            sx={styles.menuItemText}
          >
            Logg ut
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default Innstillinger;
