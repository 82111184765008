import { groq } from "next-sanity";

export const soknetITallQuery = groq`
*[_type == "soknetitall" && !(_id in path('drafts.**'))][0] {
  Statistikk {
    "tittelLokal":  coalesce(tittelLocale[$malform], tittelLocale.nb),
    "beskrivelseLokal": coalesce(beskrivelseLocale[$malform], beskrivelseLocale.nb),
    "menyNavn": coalesce(menyNavnLocale[$malform], menyNavnLocale.nb),
    menyNumber,
    leggHjelpRessurser,
    "hjelpRessurser": hjelpRessurser {
      bilde,
      "tittelLokal": coalesce(tittelLocale[$malform], tittelLocale.nb),
      linker [] {
        href,
        "lenketittelLokal": coalesce(lenketittelLocale[$malform], lenketittelLocale.nb),
        blank,
      }
    }
  },
  AktivitetStatistikk {
    "tittelLokal":  coalesce(tittelLocale[$malform], tittelLocale.nb),
    "beskrivelseLokal": coalesce(beskrivelseLocale[$malform], beskrivelseLocale.nb),
    "menyNavn": coalesce(menyNavnLocale[$malform], menyNavnLocale.nb),
    menyNumber,
    leggHjelpRessurser,
    "hjelpRessurser": hjelpRessurser {
      bilde,
      "tittelLokal": coalesce(tittelLocale[$malform], tittelLocale.nb),
      linker [] {
        href,
        "lenketittelLokal": coalesce(lenketittelLocale[$malform], lenketittelLocale.nb),
        blank,
      }
    }
  },
    Kommunefakta {
        "tittelLokal":  coalesce(tittelLocale[$malform], tittelLocale.nb),
        "beskrivelseLokal": coalesce(beskrivelseLocale[$malform], beskrivelseLocale.nb),
        "menyNavn": coalesce(menyNavnLocale[$malform], menyNavnLocale.nb),
        menyNumber,
        leggHjelpRessurser,
        "hjelpRessurser": hjelpRessurser {
          bilde,
          "tittelLokal": coalesce(tittelLocale[$malform], tittelLocale.nb),
          linker [] {
            href,
            "lenketittelLokal": coalesce(lenketittelLocale[$malform], lenketittelLocale.nb),
            blank,
          }
        }
      },
      Kart {
        "tittelLokal":  coalesce(tittelLocale[$malform], tittelLocale.nb),
        "beskrivelseLokal": coalesce(beskrivelseLocale[$malform], beskrivelseLocale.nb),
        "menyNavn": coalesce(menyNavnLocale[$malform], menyNavnLocale.nb),
        menyNumber,
        leggHjelpRessurser,
        "hjelpRessurser": hjelpRessurser {
          bilde,
          "tittelLokal": coalesce(tittelLocale[$malform], tittelLocale.nb),
          linker [] {
            href,
            "lenketittelLokal": coalesce(lenketittelLocale[$malform], lenketittelLocale.nb),
            blank,
          }
        }
      },
  }
`;

export default soknetITallQuery;
