import React from "react";

import { Box, Card, CardContent, Typography } from "@mui/material";

interface Props {
  message: string;
  icon: React.ReactNode;
  children?: React.ReactNode;
}

export const InfoBox: React.FC<Props> = ({ message, icon, children }) => {
  const styles = {
    root: {
      padding: "2rem",
    },
    icon: {
      textAlign: "center",
    },
    message: {
      textAlign: "center",
    },
  };
  return (
    <Card sx={styles.root}>
      <CardContent>
        <Box sx={styles.icon}>{icon}</Box>
      </CardContent>
      <CardContent>
        <Typography variant="h6" sx={styles.message}>
          {message}
        </Typography>
      </CardContent>
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default InfoBox;
