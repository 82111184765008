import { InfoOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

import kirkeTheme from "../../../utils/kirkeTheme";

const styles = {
  testWarning: {
    backgroundColor: kirkeTheme.palette.error.main,
    display: "flex",
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "1180px",
    width: "100%",
    displayPrint: "none",
  },
};

interface Props {
  sanityData?: string;
}

const TestWarning = ({ sanityData }: Props) => {
  return (
    <Box sx={styles.testWarning}>
      <InfoOutlined />
      <Typography marginLeft="1rem" fontWeight="bold">
        {sanityData || "Du ser nå testversjonen"}
      </Typography>
    </Box>
  );
};

export default TestWarning;
