import { useQuery } from "@tanstack/react-query";

import { httpGet } from "../../../services/HttpService";

const hentSokn = async (id: string) =>
  httpGet(`api/sokn/GetByGronnkirkeId/${id}`);

export const useGetSoknByGronnkirkeId = (gronnkirkeid: string) =>
  useQuery({
    queryKey: ["soknByGronnkirkeQuery", gronnkirkeid],
    queryFn: () => hentSokn(gronnkirkeid),
    enabled: Boolean(gronnkirkeid),
  });
