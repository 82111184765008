import React from "react";

import { Grid, Typography } from "@mui/material";

import kirkeTheme from "../../utils/kirkeTheme";

interface Props {
  title?: string;
  titleH2?: string;
  titleH4?: string;
  labeledTitle?: string;
  h1?: boolean;
  content?: string;
  subtitle1?: boolean;
  width?: string;
  children?: React.ReactNode;
  rest?: any[];
  background?: string;
  backgroundMobile?: string;
  padding?: string;
  paddingMobile?: string;
  marginBottom?: string;
  titleFont?: string;
  mobileCard?: boolean;
  dataTestId?: string;
}

export const KirkeCard = ({
  h1,
  title,
  titleH2,
  titleH4,
  content,
  subtitle1,
  width = "auto",
  children,
  background = kirkeTheme.palette.secondary.main,
  padding = kirkeTheme.spacing(2),
  paddingMobile = kirkeTheme.spacing(2),
  marginBottom = kirkeTheme.spacing(4),
  titleFont = "inherit",
  mobileCard = false,
  dataTestId,
  ...rest
}: Props) => (
  <Grid
    container
    direction="column"
    spacing={2}
    sx={{
      padding: { padding },
      background,
      width,
      marginBottom: { marginBottom },
      borderRadius: kirkeTheme.spacing(2),
      ...rest,
      [kirkeTheme.breakpoints.down("sm")]: {
        paddingLeft: mobileCard ? { paddingMobile } : "unset",
        paddingRight: mobileCard ? { paddingMobile } : "unset",
        marginTop: kirkeTheme.spacing(1),
        background: mobileCard ? kirkeTheme.palette.secondary.main : "none",
      },
    }}
    data-testid={dataTestId}
  >
    {title && (
      <Grid item>
        <Typography
          variant={h1 ? "h1" : "title"}
          sx={{
            fontFamily: titleFont,
          }}
        >
          {title}
        </Typography>
      </Grid>
    )}
    {titleH2 && (
      <Grid item>
        <Typography
          variant="h2"
          sx={{
            fontFamily: titleFont,
          }}
        >
          {titleH2}
        </Typography>
      </Grid>
    )}
    {titleH4 && (
      <Grid item>
        <Typography
          variant="h4"
          sx={{
            fontFamily: titleFont,
          }}
        >
          {titleH4}
        </Typography>
      </Grid>
    )}
    <Grid item>
      {content && (
        <Typography
          sx={{
            paddingTop: kirkeTheme.spacing(2),
            paddingBottom: kirkeTheme.spacing(1),
          }}
          variant={subtitle1 ? "subtitle1" : "body1"}
        >
          {content}
        </Typography>
      )}
      {children}
    </Grid>
  </Grid>
);

export default KirkeCard;
