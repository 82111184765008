import { useQuery } from "@tanstack/react-query";

import { httpGet } from "../../../services/HttpService";

const hentSokn = async (id: string) => httpGet(`api/sokn/GetByPlanId/${id}`);

export const useGetSoknByPlanId = (planId: string) =>
  useQuery({
    queryKey: ["soknQuery", planId],
    queryFn: () => hentSokn(planId),
    enabled: Boolean(planId),
  });
