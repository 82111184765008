import MenuIcon from "@mui/icons-material/Menu";
import { Grid, Typography, IconButton } from "@mui/material";

import kirkeTheme from "../../../utils/kirkeTheme";
import KirkeLogo from "../KirkeLogo";

const styles = {
  menuGridItem: {
    display: "flex",
    backgroundColor: kirkeTheme.palette.primary.main,
  },
  menuIcon: {
    color: kirkeTheme.palette.common.white,
    margin: "auto",
  },
};

interface Props {
  visNavn: boolean;
  handleOpenDialogBox: () => void;
  sanityData?: any;
}

const MobileHeader = ({ visNavn, handleOpenDialogBox, sanityData }: Props) => {
  return (
    <Grid container direction="row" sx={{ justifyContent: "space-between" }}>
      <Grid item xs={2} sx={{ pl: 1, pt: 0.5 }}>
        <KirkeLogo />
      </Grid>
      {visNavn && (
        <Grid item xs={8} sx={{ pl: 2, pt: 1 }}>
          <Typography variant="boldText" textAlign="center">
            {sanityData?.headerTittelLokal ||
              "Kirka vår - samlet plan for arbeidet i soknet"}
          </Typography>
        </Grid>
      )}
      <Grid item xs={2} style={styles.menuGridItem}>
        <IconButton
          edge="end"
          aria-label="menu"
          aria-haspopup="true"
          size="small"
          sx={styles.menuIcon}
          onClick={handleOpenDialogBox}
          data-testid="open-dialog-button"
        >
          <MenuIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default MobileHeader;
