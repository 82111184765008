import { useEffect, useState } from "react";

import { FormControl, Grid } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";

import { Column, GronnkirkeColumnIdType } from "./GronnkirkeTable";
import nullstillContext from "../../../components/contexts/nullstillContext";
import kirkeTheme from "../../../utils/kirkeTheme";
import { AlleDataLedetekster, GronnkirkeData } from "../../../utils/types";
import DataColumns from "../DataColumns";
import TilbakestillValgKnapp from "../TilbakestillValgKnapp";

const styles = {
  formControl: {
    top: kirkeTheme.spacing(2),
    margin: "0",
    padding: "0",
  },
};

interface Props {
  gronnkirkeData: GronnkirkeData[];
  setFilteredGronnkirkeData: (gronnekirker: GronnkirkeData[]) => void;
  showColumns: GronnkirkeColumnIdType[];
  setShowColumns: (columns: GronnkirkeColumnIdType[]) => void;
  searchValue?: string;
  columns: readonly Column[];
  ledetekster?: AlleDataLedetekster;
}

const GronnkirkeOptions = ({
  gronnkirkeData,
  setFilteredGronnkirkeData,
  showColumns,
  setShowColumns,
  searchValue,
  columns,
  ledetekster,
}: Props) => {
  const [showSelectColumns, setShowSelectColumns] = useState<boolean>(false);

  const allColumns: GronnkirkeColumnIdType[] = [
    "sokn",
    "fellesrad",
    "prosti",
    "bispedomme",
    "antallAvkryssedePunkter",
    "antallEgneTiltak",
    "tilsluttetGronnkirke",
    "innsendt",
  ];

  const tilbakestillBrukervalg = () => {
    setShowColumns([
      "sokn",
      "antallAvkryssedePunkter",
      "antallEgneTiltak",
      "tilsluttetGronnkirke",
      "innsendt",
    ]);
    nullstillContext("GronnkirkeAlleData");
  };

  const mapColumnToText = (column: string) => {
    const matchColumn = columns.find((c) => c.id === column);
    return matchColumn?.label;
  };

  const handleColumnChange = (event: SelectChangeEvent<string | string[]>) => {
    setShowColumns(event.target.value as unknown as GronnkirkeColumnIdType[]);
  };

  const filterBySearch = () => {
    let filteredBySearch: GronnkirkeData[] = [];
    if (!searchValue) {
      filteredBySearch = [...gronnkirkeData];
    } else {
      filteredBySearch = gronnkirkeData?.filter(
        (Aktivitet) =>
          Aktivitet?.sokn?.toLowerCase().includes(searchValue.toLowerCase())
      );
    }
    return filteredBySearch;
  };

  useEffect(() => {
    if (gronnkirkeData) {
      const filteredBySearch: GronnkirkeData[] = filterBySearch();
      setFilteredGronnkirkeData(filteredBySearch);
    }
  }, [gronnkirkeData, searchValue]);

  return (
    <Grid
      container
      display="flex"
      direction="row"
      spacing={2}
      mb={kirkeTheme.spacing(3)}
    >
      <Grid item xs={12} md={3.2}>
        <FormControl fullWidth sx={styles.formControl}>
          <DataColumns
            columns={allColumns}
            disabledColumns={["sokn"]}
            showColumns={showColumns}
            mapColumnToText={mapColumnToText}
            showSelectColumns={showSelectColumns}
            setShowSelectColumns={setShowSelectColumns}
            handleColumnChange={handleColumnChange}
            ledetekster={ledetekster}
          ></DataColumns>
        </FormControl>
      </Grid>
      <TilbakestillValgKnapp tilbakestillBrukervalg={tilbakestillBrukervalg} />
    </Grid>
  );
};
export default GronnkirkeOptions;
