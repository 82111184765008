export const saveFile = (href: string, filename: string) => {
  const link = document.createElement("a");
  link.href = href;
  link.download = filename;
  link.click();
};

export const saveFileBlob = (blob: Blob, filename: string) => {
  const href = URL.createObjectURL(new Blob([blob]));
  saveFile(href, filename);
};
