import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { httpGet } from "../../../services/HttpService";
import { AktivitetData } from "../../../utils/types";

const hentAlleAktiviteter = async (aar: string) => {
  const alleAktiviteter: AktivitetData[] = await httpGet(
    `api/aktivitet/GetAlleAktiviteter/${aar}`
  );
  return alleAktiviteter;
};

export const useGetAktiviteterForAlleSokn = (
  aar: string,
  onsuccess?: ((data: AktivitetData[]) => void) | undefined
) =>
  useQuery<AktivitetData[], AxiosError>({
    queryKey: ["AktiviteterAlleSoknQuery", aar],
    queryFn: () => hentAlleAktiviteter(aar),
    onSuccess: onsuccess,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
