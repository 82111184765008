import { MutableRefObject, useRef, useState } from "react";

import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { AppBar, Box, Drawer, IconButton, Toolbar } from "@mui/material";

import MenyValg from "./MenyValg";
import MobileHeader from "./MobileHeader";
import MobileProfileHeader from "./MobileProfileHeader";
import TestWarning from "./TestWarning";
import kirkeTheme from "../../../utils/kirkeTheme";
import useOnClickOutside from "../../../utils/onClickOutside";

const styles = {
  menyValgBox: {
    display: "flex",
    displayPrint: "none",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: "1.5rem",
    marginLeft: "auto",
    justifyContent: "flex-start",
  },
  exitButton: {
    color: "#245299",
    marginLeft: "1rem",
  },
  drawerBox: {
    backgroundColor: kirkeTheme.palette.background.default,
    minHeight: "auto",
    [kirkeTheme.breakpoints.down("sm")]: {
      minHeight: "30rem",
    },
  },
  closeIconBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: "2rem",
    marginRight: "2rem",
    alignItems: "center",
  },
};

interface Props {
  sanityData?: any;
  soknPage?: boolean;
  testEnv?: boolean;
  visNavn?: boolean;
  menyValg?: boolean;
}

const MobileNavBar = ({
  sanityData,
  soknPage = false,
  testEnv = false,
  visNavn = true,
  menyValg = true,
}: Props) => {
  const [open, setOpen] = useState(false);
  const ref = useRef() as MutableRefObject<HTMLDivElement>;
  useOnClickOutside(ref, () => handleCloseDialogBox());

  const handleOpenDialogBox = () => {
    setOpen(true);
  };
  const handleCloseDialogBox = () => {
    setOpen(false);
  };

  return (
    <AppBar
      sx={{
        backgroundColor: soknPage
          ? kirkeTheme.palette.common.white
          : kirkeTheme.palette.background.default,
        color: kirkeTheme.palette.common.black,
        [kirkeTheme.breakpoints.down("md")]: {
          paddingLeft: "0%",
        },
      }}
      position="sticky"
      elevation={0}
      data-testid="mobile-navbar-appbar"
    >
      {testEnv && (
        <TestWarning sanityData={sanityData?.ledetekster.testVersjonAdvarsel} />
      )}
      <MobileProfileHeader />
      <Toolbar
        sx={{
          paddingRight: "0 !important",
          paddingLeft: "0 !important",
          height: "auto",
        }}
      >
        <MobileHeader
          visNavn={visNavn}
          sanityData={sanityData}
          handleOpenDialogBox={handleOpenDialogBox}
        />
        <Drawer anchor="top" open={open}>
          <Box ref={ref} sx={styles.drawerBox}>
            <Box sx={styles.closeIconBox}>
              <IconButton
                onClick={handleCloseDialogBox}
                data-testid="close-dialog-button"
              >
                <CloseOutlinedIcon fontSize="large" style={styles.exitButton} />
              </IconButton>
            </Box>
            <Box sx={styles.menyValgBox}>{menyValg && <MenyValg />}</Box>
          </Box>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

export default MobileNavBar;
