import { useQuery } from "@tanstack/react-query";

import { httpGet } from "../../../services/HttpService";

const hentSokn = async (id: string) => httpGet(`api/sokn/GetBySatsingId/${id}`);

export const useGetSoknBySatsingId = (satsingid: string) =>
  useQuery({
    queryKey: ["soknBySatsingQuery", satsingid],
    queryFn: () => hentSokn(satsingid),
    enabled: Boolean(satsingid),
  });
