import { Divider, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";

import MenyValg from "./MenyValg";
import MobileNavBar from "./MobileNavBar";
import TestWarning from "./TestWarning";
import { forsideQuery } from "../../../api";
import { useFetchSanityData } from "../../../api/useFetchSanityData";
import kirkeTheme from "../../../utils/kirkeTheme";
import SignInSignOutButton from "../../Sign/SignInSignOutButton";
import KirkeLogo from "../KirkeLogo";

const styles = {
  appBarInner: {
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: kirkeTheme.spacing(1.5),
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    maxWidth: "1180px",
  },
  dividerBox: {
    maxWidth: "1180px",
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    displayPrint: "none",
    borderBottomWidth: 5,
    borderColor: kirkeTheme.palette.primary.dark,
  },
  headerTittel: {
    display: "flex",
    alignItems: "center",
    pl: 2,
  },
};

interface Props {
  menyValg?: boolean;
  undermeny?: boolean;
  visNavn?: boolean;
  soknPage?: boolean;
  alleSokn?: boolean;
}

const NavBar = ({
  menyValg = true,
  undermeny = false,
  visNavn = true,
  soknPage = false,
  alleSokn = false,
}: Props) => {
  const isOnMobileDevice = useMediaQuery(kirkeTheme.breakpoints.down("md"));
  const testEnv = window?.config?.ENV === "test";

  const { data: sanityData } = useFetchSanityData(forsideQuery, "forsideQuery");

  return (
    <>
      {isOnMobileDevice && (
        <MobileNavBar
          soknPage={soknPage}
          testEnv={testEnv}
          sanityData={sanityData}
          visNavn={visNavn}
          menyValg={menyValg}
        />
      )}
      {!isOnMobileDevice && (
        <AppBar
          sx={{
            paddingLeft: "5%",
            paddingRight: "5%",
            paddingTop: testEnv ? (alleSokn ? 0 : 2.7) : 3,
            backgroundColor: soknPage
              ? kirkeTheme.palette.common.white
              : kirkeTheme.palette.background.default,
            color: kirkeTheme.palette.common.black,
            [kirkeTheme.breakpoints.down("md")]: {
              paddingLeft: "0%",
            },
          }}
          elevation={0}
        >
          {testEnv && (
            <TestWarning
              sanityData={sanityData?.ledetekster.testVersjonAdvarsel}
            />
          )}
          <Grid container sx={styles.appBarInner}>
            <Grid item>
              <Grid container>
                <Grid item>
                  <KirkeLogo />
                </Grid>
                {visNavn && (
                  <Grid item sx={styles.headerTittel}>
                    <Typography variant="h2">
                      {sanityData?.headerTittelLokal ||
                        "Kirka vår - samlet plan for arbeidet i soknet"}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item>
              <Grid container sx={{ displayPrint: "none" }}>
                <SignInSignOutButton />
              </Grid>
            </Grid>
          </Grid>
          <Grid container sx={styles.appBarInner}>
            {menyValg && <MenyValg />}
          </Grid>
          {!undermeny && <Divider sx={styles.dividerBox} />}
        </AppBar>
      )}
    </>
  );
};

export default NavBar;
