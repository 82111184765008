import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { httpGet } from "../../../services/HttpService";
import { AlleTallPlanBispdomme } from "../../../utils/types";

const hentAlleAktiviteter = async (id: string) => {
  const alleTall: AlleTallPlanBispdomme = await httpGet(
    `api/plan/Getalletallbispedomme?bispedommeId=${id}`
  );
  return alleTall;
};

export const useGetPlanerTallForBispedomme = (
  id: string,
  onsuccess?: ((data: AlleTallPlanBispdomme) => void) | undefined
) =>
  useQuery<AlleTallPlanBispdomme, AxiosError>({
    queryKey: ["PlanerTallResultQuery", id],
    queryFn: () => hentAlleAktiviteter(id),
    onSuccess: onsuccess,
    // refetchOnWindowFocus: false,
    // refetchOnMount: false,
    // refetchOnReconnect: false,
  });
