import { groq } from "next-sanity";

export const ledeteksterQuery = groq`
*[_type == "ledetekster" && !(_id in path('drafts.**'))][0] {
    "alleSoknPageTitle": coalesce(alleSoknPageTitle[$malform], alleSoknPageTitle.nb),
    "helhetligMenighetsplanTekst": coalesce(helhetligMenighetsplan[$malform], helhetligMenighetsplan.nb),
    "underoverskriftSoknvelgerside": coalesce(underoverskriftSoknvelgerside[$malform], underoverskriftSoknvelgerside.nb),
    "loggBrukernavnPassordTekst": coalesce(loggBrukernavnPassord[$malform], loggBrukernavnPassord.nb),
    "beskrivelseTekst": coalesce(beskrivelse[$malform], beskrivelse.nb),
    "evaluereTekst": coalesce(evaluere[$malform], evaluere.nb),
    "visTekst": coalesce(vis[$malform], vis.nb),
    "aktivitetTekst": coalesce(aktivitet[$malform], aktivitet.nb),
    "tittelTekst": coalesce(tittel[$malform], tittel.nb),
    "detSkjeddeFeil": coalesce(detSkjeddeFeil[$malform], detSkjeddeFeil.nb),
    "kreverSkrivetilgang": coalesce(kreverSkrivetilgang[$malform], kreverSkrivetilgang.nb),
    "velgModulTekst": coalesce(velgModul[$malform], velgModul.nb),
    "lagreTekst": coalesce(lagre[$malform], lagre.nb),    
    "opprettTekst": coalesce(opprett[$malform], opprett.nb),
    "oppdaterTekst": coalesce(oppdater[$malform], oppdater.nb),
    "avbrytTekst": coalesce(avbryt[$malform], avbryt.nb),
    "tekstenBleLagretTekst": coalesce(tekstenBleLagret[$malform], tekstenBleLagret.nb),
    "overskriftTekst": coalesce(overskrift[$malform], overskrift.nb),
    "slettTekst": coalesce(slett[$malform], slett.nb),
    "redigerTekst": coalesce(rediger[$malform], rediger.nb),
    "forsettRedigereTekst": coalesce(forsettRedigere[$malform], forsettRedigere.nb),
    "forkastEndringerTekst": coalesce(forkastEndringer[$malform], forkastEndringer.nb),
    "tilbakeTekst": coalesce(tilbake[$malform], tilbake.nb),
    "planarTekst": coalesce(planar[$malform], planar.nb),
    "startaarTekst": coalesce(startaar[$malform], startaar.nb),
    "sluttaarTekst": coalesce(sluttaar[$malform], sluttaar.nb),
    "utskriftEllerPdfButtonTekst": coalesce(utskriftEllerPdf[$malform], utskriftEllerPdf.nb),
    "uttrekkWordButtonTekst": coalesce(uttrekkWordButtonTekst[$malform], uttrekkWordButtonTekst.nb),
    "problemerMedLoggInnTekst": coalesce(problemerMedLoggInn[$malform], problemerMedLoggInnTekst.nb),
    "ingenSoknTilgangTekst": coalesce(ingenSoknTilgangTekst[$malform], ingenSoknTilgangTekst.nb),
    "skjemaetBekreftTekst": coalesce(skjemaetBekreft[$malform], skjemaetBekreft.nb),
    "soknKolonneTekst": coalesce(soknKolonneTekst[$malform], soknKolonneTekst.nb),
    "bispedommeKolonneTekst": coalesce(bispedommeKolonneTekst[$malform], bispedommeKolonneTekst.nb),
    "sistEndretKolonneTekst": coalesce(sistEndretKolonneTekst[$malform], sistEndretKolonneTekst.nb),
    "administrerSide": coalesce(administrerSide[$malform], administrerSide.nb),
    "seAlleData": coalesce(seAlleData[$malform], seAlleData.nb),
    "giTilbakemelding": coalesce(giTilbakemelding[$malform], giTilbakemelding.nb),
    "alleData": coalesce(alleData[$malform], alleData.nb),
    "adminSide": coalesce(adminSide[$malform], adminSide.nb),
    "tilMineSokn": coalesce(tilMineSokn[$malform], tilMineSokn.nb),
    ledeteksterPlan {
      "planPageTitle": coalesce(planPageTitle[$malform], planPageTitle.nb),
      "velgPlanTekst": coalesce(velgPlan[$malform], velgPlan.nb),
      "opprettNyPlanperiodeTekst": coalesce(opprettNyPlanperiode[$malform], opprettNyPlanperiode.nb),
      "adminTilgangTekst": coalesce(adminTilgang[$malform], adminTilgang.nb),
      "leggBeskrivelseTekst": coalesce(leggBeskrivelse[$malform], leggBeskrivelse.nb),
      "redigerBeskrivelseTekst": coalesce(redigerBeskrivelse[$malform], redigerBeskrivelse.nb),
      "leggSatsingerTekst": coalesce(leggSatsinger[$malform], leggSatsinger.nb),
      "opprettNySatsingTekst": coalesce(opprettNySatsing[$malform], opprettNySatsing.nb),
      "satsingenBleOpprettetTekst": coalesce(satsingenBleOpprettet[$malform], satsingenBleOpprettet.nb),
      "satsingenBleSlettetTekst": coalesce(satsingenBleSlettet[$malform], satsingenBleSlettet.nb),
      "sletteSatsingTekst": coalesce(sletteSatsing[$malform], sletteSatsing.nb),
      "sikkerSletteSatsingenTekst": coalesce(sikkerSletteSatsingen[$malform], sikkerSletteSatsingen.nb),
      "linkTilAktiviteterVeileldning": coalesce(linkTilAktiviteterVeileldning[$malform], linkTilAktiviteterVeileldning.nb),
      "linkTilAktiviteter": coalesce(linkTilAktiviteter[$malform], linkTilAktiviteter.nb),
      "knyttAktivitetTilSatsingenTekst": coalesce(knyttAktivitetTilSatsingenTekst[$malform], knyttAktivitetTilSatsingenTekst.nb),
      "aktiviteterOgTiltak": coalesce(aktiviteterOgTiltak[$malform], aktiviteterOgTiltak.nb),
      "ikkeTilknyttetAktivitet": coalesce(ikkeTilknyttetAktivitet[$malform], ikkeTilknyttetAktivitet.nb),
      "satsingBleOppdatert": coalesce(satsingBleOppdatert[$malform], satsingBleOppdatert.nb),
      "satsingBleIkkeOppdatert": coalesce(satsingBleIkkeOppdatert[$malform], satsingBleIkkeOppdatert.nb),
      "satsingBleIkkeOppdatert2": coalesce(satsingBleIkkeOppdatert2[$malform], satsingBleIkkeOppdatert2.nb),
      "ingenTreffSok": coalesce(ingenTreffSok[$malform], ingenTreffSok.nb),
      "begynnASkrivSok": coalesce(begynnASkrivSok[$malform], begynnASkrivSok.nb),
      "sokForALeggeTil": coalesce(sokForALeggeTil[$malform], sokForALeggeTil.nb),
      ledeteksterLenker {
          "lenkerTittel": coalesce(lenkerTittel[$malform], lenkerTittel.nb),
          "lenkerUndertekst": coalesce(lenkerUndertekst[$malform], lenkerUndertekst.nb),
          "leggTilLenkeKnapp": coalesce(leggTilLenkeKnapp[$malform], leggTilLenkeKnapp.nb),
          "redigereLenkeKnapp": coalesce(redigereLenkeKnapp[$malform], redigereLenkeKnapp.nb),
          "sletteLenkeKnapp": coalesce(sletteLenkeKnapp[$malform], sletteLenkeKnapp.nb),
          "lenkeDialogboxTittel": coalesce(lenkeDialogboxTittel[$malform], lenkeDialogboxTittel.nb),
          "sikkerSletteLenke": coalesce(sikkerSletteLenke[$malform], sikkerSletteLenke.nb),
          "lenkeBleSlettet": coalesce(lenkeBleSlettet[$malform], lenkeBleSlettet.nb),
          "lenkeBleEndret": coalesce(lenkeBleEndret[$malform], lenkeBleEndret.nb),
          "lenkeBleOpprettet": coalesce(lenkeBleOpprettet[$malform], lenkeBleOpprettet.nb),
      },
    },
    ledeteksterMeny {
      "veiledningMenypunkt": coalesce(veiledningMenypunkt[$malform], veiledningMenypunkt.nb),
      "menighetenITallMenypunkt": coalesce(menighetenITallMenypunkt[$malform], menighetenITallMenypunkt.nb),
      "planMenypunkt": coalesce(planMenypunkt[$malform], planMenypunkt.nb),
      "aktiviteterMenypunkt": coalesce(aktiviteterMenypunkt[$malform], aktiviteterMenypunkt.nb),
      "hjemMenypunkt": coalesce(hjemMenypunkt[$malform], hjemMenypunkt.nb),
      "merMenypunkt": coalesce(merMenypunkt[$malform], merMenypunkt.nb),
      "gronnkirkeMenypunkt": coalesce(gronnkirkeMenypunkt[$malform], gronnkirkeMenypunkt.nb),
      "nyheterMenypunkt": coalesce(nyheterMenypunkt[$malform], nyheterMenypunkt.nb),
    },
  }
`;

export default ledeteksterQuery;
