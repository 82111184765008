import { AppBar, Box, Divider, Grid, Link } from "@mui/material";
import Typography from "@mui/material/Typography";

import { loginsideQuery } from "../../api";
import { useFetchSanityData } from "../../api/useFetchSanityData";
import { Video } from "../../components/common/Video";
import KirkeLogo from "../../components/layout/KirkeLogo";
import SignInSignOutButton from "../../components/Sign/SignInSignOutButton";
import renderModule from "../../sanityModules/factory";
import kirkeTheme from "../../utils/kirkeTheme";
import * as Types from "../../utils/types";
import useDocumentTitle from "../../utils/useDocumentTitle";

const styles = {
  container: {
    backgroundColor: kirkeTheme.palette.common.white,
    height: "100vh",
    width: "100%",
  },
  topSectionWrapper: {
    backgroundColor: kirkeTheme.palette.background.default,
    paddingLeft: "5%",
    paddingRight: "5%",
  },
  topSection: {
    paddingTop: kirkeTheme.spacing(17),
    paddingBottom: kirkeTheme.spacing(6),
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    maxWidth: "1180px",
    [kirkeTheme.breakpoints.down("sm")]: {
      paddingTop: kirkeTheme.spacing(15),
    },
  },
  logBoxWrapper: {
    backgroundColor: kirkeTheme.palette.common.white,
    paddingLeft: "5%",
    paddingRight: "5%",
  },
  logBox: {
    paddingTop: kirkeTheme.spacing(4),
    paddingBottom: kirkeTheme.spacing(6),
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    maxWidth: "1180px",
  },
  appBar: {
    paddingLeft: "5%",
    paddingRight: "5%",
    paddingTop: kirkeTheme.spacing(3),
    paddingBottom: kirkeTheme.spacing(3),
    color: kirkeTheme.palette.common.black,
    backgroundColor: kirkeTheme.palette.common.white,
  },
  appBarInner: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    maxWidth: "1180px",
  },
  verticalDivider: {
    borderColor: kirkeTheme.palette.common.black,
    marginRight: kirkeTheme.spacing(3),
    marginLeft: kirkeTheme.spacing(3),
    [kirkeTheme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  imageContainer: {
    paddingTop: kirkeTheme.spacing(3),
    position: "relative",
  },
  image: {
    marginTop: "-20px",
  },
  link: {
    cursor: "pointer",
  },
  index: {
    lineHeight: 2,
    fontWeight: "bold",
    position: "absolute",
    backgroundColor: "#DFE3E6",
    borderRadius: "50%",
    height: "33px",
    width: "33px",
    paddingTop: "auto",
    paddingBottom: "auto",
    display: "flex",
    justifyContent: "center",
    verticalAlign: "middle",
  },
};

const LoginPage = () => {
  const { data: sanityLogPage } = useFetchSanityData(
    loginsideQuery,
    "loginsideQuery"
  );

  useDocumentTitle(sanityLogPage?.loginPageTitle || "Logg inn");

  return (
    <Box sx={styles.container}>
      <AppBar sx={styles.appBar} elevation={0}>
        <Grid container sx={styles.appBarInner}>
          <Grid item>
            <KirkeLogo />
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            sx={styles.verticalDivider}
          />
          <Grid item sx={{ pt: 1 }}>
            <Typography variant="h2" fontWeight="bold">
              {sanityLogPage?.loginTittel ||
                "Kirka vår - samlet plan for arbeidet i soknet"}
            </Typography>
          </Grid>
        </Grid>
      </AppBar>

      <Box sx={styles.topSectionWrapper}>
        <Grid container justifyContent="center" sx={styles.topSection}>
          <Grid item>
            <Typography variant="h4" fontWeight="bold" p={2}>
              {sanityLogPage?.topSectionContent?.tittel ||
                "Kirka vår gjør det mer spennende å være kirke på tre måter:"}
            </Typography>
          </Grid>
          <Grid container flexDirection="row" justifyContent="center">
            {sanityLogPage?.topSectionContent?.imagesWithText?.map(
              (image: Types.ImageWithDescription, index: number) => (
                <Grid
                  container
                  item
                  flexDirection="column"
                  alignItems="center"
                  key={index}
                  sm={4}
                  xs={12}
                >
                  <Grid item sx={styles.imageContainer}>
                    <Typography sx={styles.index}>{index + 1}</Typography>
                    <Grid sx={styles.image}> {renderModule(image.bilde)}</Grid>
                  </Grid>
                  <Grid item>
                    <Typography variant="boldText">
                      {image.bildebeskrivelse}
                    </Typography>
                  </Grid>
                </Grid>
              )
            )}

            <Grid item mr="auto" pt={2} sx={styles.link}>
              <Link href={sanityLogPage?.topSectionContent?.link?.href}>
                <Typography fontWeight="bold">
                  {sanityLogPage?.topSectionContent.link.lenketittel ||
                    "Les mer om Kirka vår"}
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box sx={styles.logBoxWrapper}>
        <Grid container sx={styles.logBox} justifyContent="center">
          <Grid
            item
            container
            xs={12}
            md={4}
            rowSpacing={4}
            pr={{ xs: 0, md: 10 }}
            direction="column"
          >
            <Grid item>
              <Typography variant="h4" fontWeight="bold">
                {sanityLogPage?.loginSectionContent?.tittel ||
                  "Logg inn på Kirka vår"}
              </Typography>
              <Typography variant="body1">
                {sanityLogPage?.loginSectionContent?.subtittel ||
                  "Logg inn med brukernavn og passord"}
              </Typography>
            </Grid>
            <Grid item>
              <SignInSignOutButton />
            </Grid>
            <Grid item>
              {sanityLogPage?.loginSectionContent?.tekst &&
                renderModule(sanityLogPage?.loginSectionContent?.tekst)}
            </Grid>
          </Grid>

          <Grid item container xs={12} md={8}>
            <Grid container rowSpacing={2}>
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  {sanityLogPage?.loginSectionContent?.videoTittel ||
                    "Se infofilm om Kirka vår"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Video video={sanityLogPage?.loginSectionContent?.video} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
export default LoginPage;
