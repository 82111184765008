import { Typography, Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { feilmeldingerQuery } from "../api";
import { useFetchSanityData } from "../api/useFetchSanityData";
import Container from "../components/layout/Container";
import kirkeTheme from "../utils/kirkeTheme";
import useDocumentTitle from "../utils/useDocumentTitle";

const styles = {
  wrapper: {
    maxWidth: "660px",
    margin: "0 auto 5rem",
  },
  bigHeader: {
    fontSize: "5rem",
    fontWeight: 700,
    color: kirkeTheme.palette.primary.dark,
  },
  warningText: {
    fontSize: "2rem",
    fontWeight: 700,
  },
  icon: {
    width: "300px",
    height: "300px",
  },
};

const NotFoundPage = () => {
  const navigate = useNavigate();

  const { data: feilmeldinger } = useFetchSanityData(
    feilmeldingerQuery,
    "feilmeldingerQuery"
  );

  useDocumentTitle(feilmeldinger?.notFoundPageTitle || "404 not found");

  return (
    <Container>
      <Grid
        container
        display="flex"
        flexDirection="column"
        textAlign="center"
        alignItems="center"
        rowSpacing={2}
        sx={styles.wrapper}
      >
        <Typography sx={styles.bigHeader}>404</Typography>
        <img
          style={styles.icon}
          src="/Images/notFound.png"
          alt="Ikke funnet ikon"
        />
        <Grid item>
          <Typography sx={styles.warningText}>SIDE IKKE FUNNET</Typography>
          <Typography variant="subtitle1">
            {feilmeldinger?.http?.firenullfire ||
              "Denne siden eksisterer ikke. Sjekk om du har skrevet inn riktig addresse i addressefeltet."}
          </Typography>
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            sx={{ background: kirkeTheme.palette.success.main }}
            onClick={() => navigate("/sokn")}
          >
            Til forsiden
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default NotFoundPage;
