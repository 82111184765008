import { useContext, useState } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AppBar, Box, Grid, Link, Typography } from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import VelgSoknHeaderMeny from "./VelgSoknHeaderMeny";
import { useFetchSanityData, ledeteksterQuery } from "../../api";
import { getBispedommeContext } from "../../pages/AlleTallPage/AlleTallPage";
import kirkeTheme from "../../utils/kirkeTheme";
import ExternalLinkIcon from "../common/ExternalLinkIcon";
import { SoknContext, SoknContextType } from "../contexts/SoknContext";
import { SCOPES } from "../Tilgangsstyring/tilganger";
import Tilgangsstyring from "../Tilgangsstyring/Tilgangsstyring";

const styles = {
  footer: {
    backgroundColor: kirkeTheme.palette.primary.dark,
    color: kirkeTheme.palette.primary.contrastText,
    zIndex: 1150,
    display: "block",
    width: "100%",
    position: "fixed",
  } as React.CSSProperties,
  grid: {
    justifyContent: "right",
    alignItems: "center",
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    maxWidth: "1180px",
    displayPrint: "none",
  },
  link: {
    color: "white",
    textDecoration: "none",
  },
  soknItem: {
    paddingLeft: 4,
    "&:hover": {
      cursor: "pointer",
    },
  },
  soknDropdownText: {
    display: "flex",
    alignContent: "center",
  },
};

interface Props {
  alleData?: boolean;
}

const HeaderMeny = ({ alleData = false }: Props) => {
  const { sokn, setSokn, oneSoknAccess } = useContext(
    SoknContext
  ) as SoknContextType;
  const hasBispedommeAccess = getBispedommeContext().length > 0;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const { data: ledeteksterData } = useFetchSanityData(
    ledeteksterQuery,
    "ledeteksterQuery"
  );

  return (
    <AppBar style={styles.footer} elevation={0}>
      <Box>
        <Grid container sx={styles.grid}>
          {alleData && sokn && (
            <Grid item sx={styles.soknItem} onClick={() => navigate("/sokn")}>
              <Typography variant="caption">{sokn.navn}</Typography>
            </Grid>
          )}

          {!oneSoknAccess && !alleData && (
            <Grid
              item
              sx={styles.soknItem}
              onClick={(event) => setAnchorEl(event.currentTarget)}
            >
              <Typography variant="caption" sx={styles.soknDropdownText}>
                {sokn?.navn}{" "}
                <ExpandMoreIcon fontSize="small" sx={{ paddingTop: "2px" }} />
              </Typography>
            </Grid>
          )}
          {anchorEl && (
            <VelgSoknHeaderMeny
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              setSokn={setSokn}
            />
          )}

          <Grid item sx={{ paddingLeft: 4 }}>
            <Link
              component={RouterLink}
              to={
                "https://forms.office.com/Pages/ResponsePage.aspx?id=pCQgUYWGA0-AhhSmFzDoF1Wo4KKNzNBElngF7fDX-0FUN1ExMTNKVVMyVVg4WVEzTTNGWVVYV0pYTCQlQCN0PWcu"
              }
              target="_blank"
              style={styles.link}
            >
              <Typography variant="caption">
                {ledeteksterData?.giTilbakemelding || "Tilbakemelding"}
                <ExternalLinkIcon />
              </Typography>
            </Link>
          </Grid>

          <Grid item sx={{ paddingLeft: 4 }}>
            <Link component={RouterLink} to={"/data"} style={styles.link}>
              <Typography variant="caption">
                {ledeteksterData?.alleData || "Alle data"}
              </Typography>
            </Link>
          </Grid>
          {hasBispedommeAccess && (
            <Grid item sx={{ paddingLeft: 4 }}>
              <Link component={RouterLink} to={"/tall"} style={styles.link}>
                <Typography variant="caption">{"Alle tall"}</Typography>
              </Link>
            </Grid>
          )}

          <Tilgangsstyring scopes={[SCOPES.skriveTilgang]}>
            <Grid item sx={{ paddingLeft: 4 }}>
              <Link
                component={RouterLink}
                to={"/Administrasjon"}
                style={styles.link}
              >
                <Typography variant="caption">
                  {ledeteksterData?.adminSide || "Administrer side"}
                </Typography>
              </Link>
            </Grid>
          </Tilgangsstyring>
        </Grid>
      </Box>
    </AppBar>
  );
};

export default HeaderMeny;
