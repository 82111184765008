import { IPublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ToastContainer } from "react-toastify";

import RoutesComponent from "./components/layout/RoutesComponent";

type AppProps = {
  pca: IPublicClientApplication;
};

const queryClient = new QueryClient();

function App({ pca }: AppProps) {
  return (
    <MsalProvider instance={pca}>
      <QueryClientProvider client={queryClient}>
        <RoutesComponent />
        <ToastContainer />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </MsalProvider>
  );
}

export default App;
