import { TableRow, TableCell, Typography, Table } from "@mui/material";

import { AlleTallPlanerColumn, hasOwn } from "./PlanerTallTable";
import { formatertDato } from "../../../utils";
import kirkeTheme from "../../../utils/kirkeTheme";
import { AlleTallPlan } from "../../../utils/types";

interface Props {
  plan: AlleTallPlan;
  columns: readonly AlleTallPlanerColumn[];
}

const UnderliggendePlanRow = ({ plan, columns }: Props) => {
  const mappedData = columns.map((column) => {
    if (plan && hasOwn(plan, column.id)) {
      return plan[column.id];
    }
    return null;
  });
  return (
    <Table>
      <TableRow>
        {Object.entries(mappedData).map(([key, value]) => {
          if (value == null) {
            return <TableCell key={key}></TableCell>;
          } else {
            if (key === "0") {
              return (
                <TableCell
                  sx={{
                    minWidth: columns[parseInt(key)].minWidth ?? 0,
                  }}
                >
                  <Typography sx={{ marginLeft: kirkeTheme.spacing(12) }}>
                    {plan.navn}
                  </Typography>
                </TableCell>
              );
            } else {
              if (parseInt(key) == columns.length - 1) {
                return (
                  <TableCell
                    key={key}
                    sx={{
                      width: columns[parseInt(key)].minWidth ?? 0,
                    }}
                  >
                    {formatertDato(value as string, "long")}
                  </TableCell>
                );
              } else {
                return (
                  <TableCell
                    key={key}
                    sx={{
                      width: columns[parseInt(key)].minWidth ?? 0,
                    }}
                  >
                    {value}
                  </TableCell>
                );
              }
            }
          }
        })}
      </TableRow>
    </Table>
  );
};

export default UnderliggendePlanRow;
