import { Box, useMediaQuery } from "@mui/material";

import Footer from "./Footer";
import HeaderMeny from "./HeaderMeny";
import NavBar from "./NavBar/NavBar";
import kirkeTheme from "../../utils/kirkeTheme";

interface Props {
  children: React.ReactNode;
  menyValg?: boolean;
  undermeny?: boolean;
  alleSokn?: boolean;
  alleData?: boolean;
  alleTall?: boolean;
  soknPage?: boolean;
  maxWidth?: string;
}

const Container = ({
  children,
  menyValg,
  undermeny = false,
  alleSokn = false,
  alleData = false,
  alleTall = false,
  soknPage = false,
  maxWidth = "1180px",
}: Props) => (
  <>
    {!alleSokn && !useMediaQuery(kirkeTheme.breakpoints.down("md")) && (
      <HeaderMeny alleData={alleData || alleTall} />
    )}
    <NavBar
      menyValg={menyValg}
      undermeny={undermeny}
      visNavn={!alleSokn && !alleData && !alleTall}
      soknPage={soknPage}
      alleSokn={alleSokn}
    />
    <Box
      sx={{
        paddingTop:
          window?.config?.ENV === "test"
            ? kirkeTheme.spacing(27.5)
            : kirkeTheme.spacing(24.5),
        paddingBottom: "2%",
        paddingLeft: "5%",
        paddingRight: "5%",
        minHeight: "70vh",
        backgroundColor: soknPage ? kirkeTheme.palette.common.white : "none",
        [kirkeTheme.breakpoints.down("md")]: {
          paddingTop: undermeny ? "12rem" : kirkeTheme.spacing(3),
          paddingLeft: soknPage ? 0 : "5%",
          paddingRight: soknPage ? 0 : "5%",
        },
        [kirkeTheme.breakpoints.down("sm")]: {
          paddingTop: undermeny ? "12rem" : kirkeTheme.spacing(0),
        },
        "@media print": {
          marginTop: kirkeTheme.spacing(0),
        },
      }}
    >
      <Box
        sx={{
          marginLeft: "auto",
          marginRight: "auto",
          width: "100%",
          maxWidth: maxWidth,
        }}
      >
        {children}
      </Box>
    </Box>
    {!alleSokn && !alleData && !alleTall && <Footer />}
  </>
);

export default Container;
