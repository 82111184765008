import { useContext } from "react";

import { Box, Grid, Typography } from "@mui/material";

import kirkeTheme from "../../../utils/kirkeTheme";
import { SoknContext, SoknContextType } from "../../contexts/SoknContext";
import SignInSignOutButton from "../../Sign/SignInSignOutButton";

const MobileProfileHeader = () => {
  const { sokn } = useContext(SoknContext) as SoknContextType;
  return (
    <Box>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          backgroundColor: kirkeTheme.palette.secondary.light,
        }}
      >
        <Grid
          item
          xs={5}
          sx={{ display: "flex", justifyContent: "flex-end", marginRight: 1 }}
        >
          <Typography variant="body2" fontStyle="italic">
            {sokn?.navn}
          </Typography>
        </Grid>
        <Grid item xs={1} margin={0.5}>
          <SignInSignOutButton />
        </Grid>
      </Grid>
    </Box>
  );
};

export default MobileProfileHeader;
