import { TablePagination } from "@mui/material";

import kirkeTheme from "../../utils/kirkeTheme";

const styles = {
  tablePagination: {
    marginTop: "0",
    marginLeft: kirkeTheme.spacing(3),
    paddingRight: "3rem",
  },
};

interface Props {
  count: number;
  rowsPerPage: number;
  page: number;
  setPage: (page: number) => void;
  setRowsPerPage: (rows: number) => void;
}

const Pagination = ({
  count,
  rowsPerPage,
  page,
  setPage,
  setRowsPerPage,
}: Props) => {
  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <TablePagination
      rowsPerPageOptions={[10, 20, 50, 100]}
      component="div"
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      sx={styles.tablePagination}
    />
  );
};

export default Pagination;
