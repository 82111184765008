import { useContext } from "react";

import { PERMISSIONS } from "./tilganger";
import {
  AccessGroupContext,
  AccessGroupContextType,
} from "../contexts/AccessGroupContext";

export const hasPermission = (scopes: string[]) => {
  const { accessGroup } = useContext(
    AccessGroupContext
  ) as AccessGroupContextType;
  if (!accessGroup) {
    return false;
  }
  const permissions = PERMISSIONS[accessGroup.toUpperCase()];
  const scopesMap: { [key: string]: boolean } = {};
  scopes.forEach((scope: string) => {
    scopesMap[scope] = true;
  });

  return permissions
    ? permissions.some((permission) => scopesMap[permission])
    : false;
};

interface Props {
  children: React.ReactNode;
  scopes: string[];
  RenderError?: () => JSX.Element;
}

const Tilgangsstyring = ({
  children,
  scopes = [],
  RenderError = () => <div></div>,
}: Props) => {
  const permissionGranted = hasPermission(scopes);

  if (!permissionGranted) {
    return <RenderError />;
  }
  return <>{children}</>;
};

export default Tilgangsstyring;
