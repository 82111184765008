import { useContext, useState } from "react";

import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";

import { useGetAktiviteterForAlleSokn } from "./../api";
import AktiviteterOptions from "./AktiviteterOptions";
import AktiviteterRow from "./AktiviteterRow";
import { ErrorInfoBox, LoadingSpinner } from "../../../components/common";
import {
  UserPreferencesContext,
  UserPreferencesContextType,
} from "../../../components/contexts/UserPreferencesContext";
import kirkeTheme from "../../../utils/kirkeTheme";
import { sortAsc, sortDesc } from "../../../utils/tableHelper";
import { AlleDataLedetekster, AktivitetData } from "../../../utils/types";
import Pagination from "../Pagination";

export type AktivitetColumnIdType =
  | "sokn"
  | "fellesrad"
  | "prosti"
  | "bispedomme"
  | "organisasjonsnummer"
  | "tittel"
  | "aldersgrupper"
  | "kategorier"
  | "beskrivelse"
  | "ansvarlig"
  | "satsinger"
  | "evalueringTekst"
  | "antallDeltakere"
  | "antallSamlinger";

export interface Column {
  id: AktivitetColumnIdType;
  label: string;
  minWidth?: number;
  align?: "right" | "left" | "center";
}

const sortArray = (
  arr: AktivitetData[],
  orderBy: "asc" | "desc",
  columnId: AktivitetColumnIdType
) => {
  const sortMethod = orderBy === "asc" ? sortAsc : sortDesc;

  if (columnId === "antallSamlinger") {
    return arr.sort((a, b) =>
      sortMethod(a.antallSamlinger || 0, b.antallSamlinger || 0)
    );
  }

  if (columnId === "antallDeltakere") {
    return arr.sort((a, b) =>
      sortMethod(a.antallDeltakere || 0, b.antallDeltakere || 0)
    );
  }

  return arr.sort((a, b) => {
    const aValue = a[columnId];
    const bValue = b[columnId];
    if (!aValue && !bValue) {
      return 0;
    } else if (!aValue) {
      return 1;
    } else if (!bValue) {
      return -1;
    } else {
      return sortMethod(aValue.toString(), bValue.toString());
    }
  });
};

interface Props {
  searchValue?: string;
  ledetekster?: AlleDataLedetekster;
}

const AktiviteterTable = ({ searchValue, ledetekster }: Props) => {
  const [filteredAktiviteterData, setFilteredAktiviteterData] =
    useState<AktivitetData[]>();
  const [orderDirection, setOrderDirection] = useState<"asc" | "desc">("asc");
  const [page, setPage] = useState(0);
  const [activeColumn, setActiveColumn] = useState<
    AktivitetColumnIdType | undefined
  >();
  const { rowsPerPage = 20, setRowsPerPage } = useContext(
    UserPreferencesContext
  ) as UserPreferencesContextType;
  const currentAar = new Date().getFullYear().toString();
  const { aarFilter = currentAar, setAarFilter } = useContext(
    UserPreferencesContext
  ) as UserPreferencesContextType;
  if (aarFilter === "alleAar") {
    setAarFilter(currentAar);
  }
  const {
    showAktivitetColumns = [
      "sokn",
      "fellesrad",
      "prosti",
      "tittel",
      "beskrivelse",
    ],
    setShowAktivitetColumns,
  } = useContext(UserPreferencesContext) as UserPreferencesContextType;
  const {
    data: AktiviteterData,
    error,
    isLoading,
  } = useGetAktiviteterForAlleSokn(aarFilter, (data) =>
    setFilteredAktiviteterData(data)
  );

  const columns: readonly Column[] = [
    {
      id: "sokn",
      label: ledetekster?.soknKolonneTekst || "Sokn",
      minWidth: 200,
    },
    {
      id: "fellesrad",
      label: ledetekster?.fellesradKolonneTekst || "FR",
      minWidth: 200,
    },
    {
      id: "prosti",
      label: ledetekster?.prostiKolonneTekst || "Prosti",
      minWidth: 150,
    },
    {
      id: "bispedomme",
      label: ledetekster?.bispedommeKolonneTekst || "Bispedømme",
      minWidth: 150,
    },
    {
      id: "organisasjonsnummer",
      label: ledetekster?.orgnummerKolonneTekst || "Orgnummer",
      minWidth: 100,
    },
    {
      id: "tittel",
      label: ledetekster?.tittelKolonneTekst || "Tittel",
      minWidth: 100,
    },
    {
      id: "aldersgrupper",
      label: ledetekster?.aldersgruppeKolonneTekst || "Aldersgrupper",
      minWidth: 100,
    },
    {
      id: "kategorier",
      label: ledetekster?.kategorierKolonneTekst || "Kategorier",
      minWidth: 100,
    },
    {
      id: "beskrivelse",
      label: ledetekster?.beskrivelseKolonneTekst || "Beskrivelse",
      minWidth: 100,
    },
    {
      id: "ansvarlig",
      label: ledetekster?.ansvarligKolonneTekst || "Ansvarlig",
      minWidth: 100,
    },
    {
      id: "satsinger",
      label: ledetekster?.satsingerKolonneTekst || "Satsinger",
      minWidth: 100,
    },
    {
      id: "evalueringTekst",
      label: ledetekster?.evalueringKolonneTekst || "Evaluering",
      minWidth: 100,
    },
    {
      id: "antallDeltakere",
      label: ledetekster?.deltakereKolonneTekst || "Deltakere",
      minWidth: 100,
    },
    {
      id: "antallSamlinger",
      label: ledetekster?.antallSamlingerKolonneTekst || "Antall Samlinger",
      minWidth: 100,
    },
  ];

  const handleSortRequest = (columnId: AktivitetColumnIdType) => {
    setActiveColumn(columnId);
    if (filteredAktiviteterData) {
      sortArray(filteredAktiviteterData, orderDirection, columnId);
    }
    setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
  };

  const isActive = (columnId: AktivitetColumnIdType) =>
    activeColumn && activeColumn === columnId ? true : !activeColumn;

  const showColumn = (column: AktivitetColumnIdType) =>
    showAktivitetColumns.includes(column);

  return (
    <Box sx={{ paddingTop: kirkeTheme.spacing(5), paddingLeft: "16px" }}>
      {isLoading && <LoadingSpinner />}
      {error && <ErrorInfoBox error={error} />}
      {!isLoading && AktiviteterData && (
        <>
          <AktiviteterOptions
            AktiviteterData={AktiviteterData}
            setFilteredAktiviteterData={setFilteredAktiviteterData}
            showColumns={showAktivitetColumns}
            setShowColumns={setShowAktivitetColumns}
            searchValue={searchValue}
            columns={columns}
            aar={aarFilter}
            setAar={setAarFilter}
            ledetekster={ledetekster}
          />
          <TableContainer sx={{ overflowX: "visible" }}>
            <Table aria-label="alle data tabell">
              <TableHead>
                <TableRow
                  sx={{
                    borderBottom: "1px solid rgba(224, 224, 224)",
                  }}
                >
                  {columns?.map((column) => {
                    return (
                      showColumn(column.id) && (
                        <TableCell
                          key={column.id}
                          sx={{ minWidth: column.minWidth, paddingLeft: 0 }}
                        >
                          <TableSortLabel
                            onClick={() => handleSortRequest(column.id)}
                            active={isActive(column.id)}
                            direction={orderDirection}
                            IconComponent={ArrowDropDownSharpIcon}
                          >
                            <Typography variant="boldText">
                              {column?.label}
                            </Typography>
                          </TableSortLabel>
                        </TableCell>
                      )
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredAktiviteterData &&
                  filteredAktiviteterData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((Aktivitet) => (
                      <TableRow
                        tabIndex={0}
                        key={Aktivitet.id + Aktivitet.sokn}
                        data-testid="alledata-listitem"
                        sx={{
                          verticalAlign: "top",
                          "&:nth-of-type(even)": {
                            backgroundColor: "#E9EDF5",
                          },
                        }}
                      >
                        {columns?.map(
                          (column: Column) =>
                            showColumn(column.id) && (
                              <AktiviteterRow
                                key={column.id}
                                Aktivitet={Aktivitet}
                                column={column}
                              />
                            )
                        )}
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          {AktiviteterData && (
            <Pagination
              count={
                filteredAktiviteterData !== undefined
                  ? filteredAktiviteterData?.length
                  : AktiviteterData.length
              }
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              page={page}
              setPage={setPage}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default AktiviteterTable;
