import { Typography, Link } from "@mui/material";
import { PortableText as PortableTextComponent } from "@portabletext/react";
import { Link as RouterLink } from "react-router-dom";

import { ModuleFaktaboks } from "./moduleFaktaboks";
import { ModuleImage } from "./moduleImage";
import { ModuleVideo } from "./moduleVideo";
import ExternalLinkIcon from "../components/common/ExternalLinkIcon";
import kirkeTheme from "../utils/kirkeTheme";
import * as Types from "../utils/types";

function ImageRender(props: any) {
  return <ModuleImage content={props.value} />;
}
function VideoRender(props: any) {
  return <ModuleVideo content={props.value} />;
}
function FaktaboksRender(props: any) {
  return <ModuleFaktaboks content={props.value} />;
}

interface Props {
  content: Types.RichTextType;
  inheritTextColor?: boolean;
}

export const PortableText = ({ content, inheritTextColor = false }: Props) => (
  <PortableTextComponent
    value={content.content}
    components={{
      block: {
        normal: ({ children }) => (
          <Typography
            pt={kirkeTheme.spacing(1)}
            component="div"
            variant="body1"
          >
            {children}
          </Typography>
        ),
        title: ({ children }) => (
          <Typography variant="title">{children}</Typography>
        ),
        undertekst: ({ children }) => (
          <Typography variant="subtitle1">{children}</Typography>
        ),
        blockquote: ({ children }) => (
          <blockquote
            style={{
              fontSize: "16px",
              fontStyle: "italic",
              fontFamily: "Georgia",
            }}
          >
            {children}
          </blockquote>
        ),
      },

      types: {
        imageWithAlt: ImageRender,
        vimeoVideo: VideoRender,
        faktaboks: FaktaboksRender,
      },
      marks: {
        link: ({ children, value }) =>
          value.blank ? (
            <Link
              href={value.href}
              variant="link"
              target="_blank"
              rel="noreferrer"
              color={inheritTextColor ? "inherit" : undefined}
            >
              {children}
              <ExternalLinkIcon />
            </Link>
          ) : (
            <Link
              color={inheritTextColor ? "inherit" : undefined}
              href={value.href}
              target={"_self"}
              variant="link"
            >
              {children}
              <ExternalLinkIcon />
            </Link>
          ),
        internalLink: ({ children, value }) =>
          value.blank ? (
            <Link
              component={RouterLink}
              to={`${window.origin}/${value.href}`}
              variant="link"
              target="_blank"
              rel="noreferrer"
              color={inheritTextColor ? "inherit" : undefined}
            >
              {children}
            </Link>
          ) : (
            <Link
              component={RouterLink}
              to={`${window.origin}/${value.href}`}
              color={inheritTextColor ? "inherit" : undefined}
              variant="link"
            >
              {children}
            </Link>
          ),
      },
    }}
  />
);

export default PortableText;
