import { useState } from "react";

import { Construction as ConstructionIcon } from "@mui/icons-material";
import {
  MenuItem,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";

import PlanerTallTable from "./PlanerTallData/PlanerTallTable";
import { useFetchSanityData } from "../../api";
import alledataQuery from "../../api/alledata";
import Container from "../../components/layout/Container";
import kirkeTheme from "../../utils/kirkeTheme";
import { BispedommeType } from "../../utils/types";
import useDocumentTitle from "../../utils/useDocumentTitle";

const styles = {
  underConstructionBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: kirkeTheme.spacing(5),
    paddingLeft: kirkeTheme.spacing(2),
  },
  tabs: {
    minHeight: "40px",
    maxHeight: "40px",
    "& button": {
      backgroundColor: "#CED0D3",
      margin: "0 8px",
      borderRadius: "10px 10px 0px 0px",
      textTransform: "none",
      minHeight: "40px",
      maxHeight: "40px",
      width: "132px",
      padding: "0",
      color: "inherit",
    },
    "& button: active": {
      backgroundColor: kirkeTheme.palette.common.black,
    },
    "& button.Mui-selected": {
      backgroundColor: kirkeTheme.palette.common.black,
      color: "white",
    },
  },
};

export const getBispedommeContext = (): BispedommeType[] => {
  const item = window.localStorage.getItem("bispedommer");
  const bispedommer =
    item && item != "undefined" ? (JSON.parse(item) as BispedommeType[]) : [];
  return bispedommer;
};

const AlleTallPage = () => {
  const [tabValue, setTabValue] = useState(0);
  const bispedommer = getBispedommeContext();
  const [selectedBispedomme, setSelectedBispedomme] = useState(
    bispedommer[0].id
  );

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedBispedomme(event.target.value);
  };

  const handleChangeTab = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const { data: sanityData } = useFetchSanityData(
    alledataQuery,
    "alleDataQuery"
  );
  useDocumentTitle(
    /*sanityData?.alledataSideTitle ||*/ "Alle tall for Kirka vår"
  );

  return (
    <Container undermeny menyValg={false} alleTall maxWidth={"1340px"}>
      {!bispedommer && (
        <Box sx={{ paddingBottom: kirkeTheme.spacing(3) }}>
          <Typography>Du har ikke tilgang til noen bispedomme!</Typography>
        </Box>
      )}
      {bispedommer && (
        <>
          <Box sx={{ paddingBottom: kirkeTheme.spacing(3) }}>
            <Typography variant="title">
              {`${
                /*sanityData?.alledataTittelLokal || */ "Alle tall for Kirka vår"
              }`}
            </Typography>
          </Box>
          <Box sx={{ marginBottom: kirkeTheme.spacing(4) }}>
            {bispedommer.length > 1 && (
              <>
                <Typography variant="body1">
                  {
                    /*sanityData?.alledataBeskLokal ||*/
                    "Velg Bispedømme"
                  }
                </Typography>
                <Select value={selectedBispedomme} onChange={handleChange}>
                  {bispedommer.map((bispedomme: BispedommeType) => (
                    <MenuItem key={bispedomme.id} value={bispedomme.id}>
                      {bispedomme.navn}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}
          </Box>
          <Box sx={{ padding: 0, borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabValue}
              onChange={handleChangeTab}
              aria-label="alle data tabs"
              TabIndicatorProps={{ hidden: true }}
              sx={styles.tabs}
            >
              <Tab label="Planer" />
              <Tab label="Aktiviteter" />
              <Tab label="Grønn kirke" />
            </Tabs>
          </Box>
          {tabValue === 0 && selectedBispedomme && (
            <PlanerTallTable
              bispedommeId={selectedBispedomme}
              ledetekster={sanityData?.ledetekster}
            />
          )}

          {tabValue === 1 && (
            <Box sx={styles.underConstructionBox}>
              <Typography>
                Alle Tall for aktiviteter er under utvikling
              </Typography>
              <ConstructionIcon fontSize={"large"} />
            </Box>
          )}
          {tabValue === 2 && (
            <Box sx={styles.underConstructionBox}>
              <Typography>
                Alle Tall for Grønn kirke er under utvikling
              </Typography>
              <ConstructionIcon fontSize={"large"} />
            </Box>
          )}
        </>
      )}
    </Container>
  );
};

export default AlleTallPage;
