import { groq } from "next-sanity";

export const registrerBrukerQuery = groq`
*[_type == "registrerBruker" && !(_id in path('drafts.**'))][0] {
        "errorMessage": coalesce(errorMessage[$malform], errorMessage.nb),
        "valideringFornavn": coalesce(valideringFornavn[$malform], valideringFornavn.nb),
        "valideringMellomnavn": coalesce(valideringMellomnavn[$malform], valideringMellomnavn.nb),
        "valideringEtternavn": coalesce(valideringEtternavn[$malform], valideringEtternavn.nb),
        "valideringTelefon": coalesce(valideringTelefon[$malform], valideringTelefon.nb),
        "valideringRadioButtons": coalesce(valideringRadioButtons[$malform], valideringRadioButtons.nb),
        "valideringKirkenbruker": coalesce(valideringKirkenbruker[$malform], valideringKirkenbruker.nb),
        "valideringEpost": coalesce(valideringEpost[$malform], valideringEpost.nb),
        "valideringFodselsdato": coalesce(valideringFodselsdato[$malform], valideringFodselsdato.nb),
        "valideringSokn": coalesce(valideringSokn[$malform], valideringSokn.nb),
        "skjemaTitle": coalesce(skjemaTitle[$malform], skjemaTitle.nb),
        "skjemaUndertekst": coalesce(skjemaUndertekst[$malform], skjemaUndertekst.nb),
        "innsendtSkjemaTittel": coalesce(innsendtSkjemaTittel[$malform], innsendtSkjemaTittel.nb),
        "innsendtSkjemaUndertekst": coalesce(innsendtSkjemaUndertekst[$malform], innsendtSkjemaUndertekst.nb),
        ledetekster {
                "fulltNavn": coalesce(fulltNavn[$malform], fulltNavn.nb),
                "placeholderFornavn": coalesce(placeholderFornavn[$malform], placeholderFornavn.nb),
                "placeholderMellomnavn": coalesce(placeholderMellomnavn[$malform], placeholderMellomnavn.nb),
                "placeholderEtternavn": coalesce(placeholderEtternavn[$malform], placeholderEtternavn.nb),
                "telefonnummer": coalesce(telefonnummer[$malform], telefonnummer.nb),
                "placeholderTelefonnummer": coalesce(placeholderTelefonnummer[$malform], placeholderTelefonnummer.nb),
                "kirkenbrukerCheckbox": coalesce(kirkenbrukerCheckbox[$malform], kirkenbrukerCheckbox.nb),
                "kirkenbruker": coalesce(kirkenbruker[$malform], kirkenbruker.nb),
                "placeholderKirkenbruker": coalesce(placeholderKirkenbruker[$malform], placeholderKirkenbruker.nb),
                "epost": coalesce(epost[$malform], epost.nb),
                "placeholderEpost": coalesce(placeholderEpost[$malform], placeholderEpost.nb),
                "fodselsdato": coalesce(fodselsdato[$malform], fodselsdato.nb),
                "placeholderFodselsdato": coalesce(placeholderFodselsdato[$malform], placeholderFodselsdato.nb),
                "sokn": coalesce(sokn[$malform], sokn.nb),
                "undertekstSokn": coalesce(undertekstSokn[$malform], undertekstSokn.nb),
                "finnSoknKnapp": coalesce(finnSoknKnapp[$malform], finnSoknKnapp.nb),
                "endreSoknKnapp": coalesce(endreSoknKnapp[$malform], endreSoknKnapp.nb),
                "sendSkjemaKnapp": coalesce(sendSkjemaKnapp[$malform], sendSkjemaKnapp.nb),
              },
   }
`;

export default registrerBrukerQuery;
