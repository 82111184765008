import { Link as MUILink } from "@mui/material";
import {
  CharacterMetadata,
  CompositeDecorator,
  ContentBlock,
  ContentState,
} from "draft-js";

interface Props {
  entityKey: string;
  contentState: ContentState;
  children: React.ReactNode;
}

const Link = ({ entityKey, contentState, children }: Props) => {
  const { url, linkText } = contentState.getEntity(entityKey).getData();
  return (
    <MUILink href={url} target="_blank" rel="noreferrer">
      {linkText || children}
    </MUILink>
  );
};

function findLinkEntities(
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState
) {
  contentBlock.findEntityRanges((character: CharacterMetadata) => {
    const entityKey = character.getEntity();

    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
}

const createLinkDecorator = () =>
  new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
    },
  ]);

export const decorator = createLinkDecorator();
