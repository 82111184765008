import React from "react";

import DeleteOutline from "@mui/icons-material/DeleteOutline";
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  LinearProgress,
} from "@mui/material";

import kirkeTheme from "../../../utils/kirkeTheme";
import * as Types from "../../../utils/types";
import { DialogBoxAlert } from "../DialogBoxAlert";
import DialogTitle from "./DialogTitle";

interface Props {
  open: boolean;
  loading?: boolean;
  validInput?: boolean;
  error?: any;
  size?: any;
  stackBtnMobil?: boolean;

  handleMainClick?: () => void;
  handleClose: () => void;
  handleConfirm?: () => void;
  handleCancel?: () => void;
  validateInput?: () => void;

  children: React.ReactNode;

  dialogDataTestId?: string;
  title: string;
  mainBtnText?: string;
  cancelButtonText?: string;
  mainBtnColor?: string;
  ledetekster?: Types.Ledetekster;

  fortsettRedigere?: boolean;
  setOpenConfirmDialogBox?: React.Dispatch<React.SetStateAction<boolean>>;

  visSlettKnapp?: boolean;
  setOpenSlett?: React.Dispatch<React.SetStateAction<boolean>>;
  mainBtnDataTestid?: string;
  slettBtnDataTestid?: string;
}

const styles = {
  dialogWrapper: {
    padding: kirkeTheme.spacing(2),
    [kirkeTheme.breakpoints.down("sm")]: {
      padding: kirkeTheme.spacing(1),
    },
  },
  screenSize: {
    maxHeight: "65vh",
  },
};

export const DialogBox = ({
  open,
  error,
  size = "sm",
  loading,
  validInput = true,
  stackBtnMobil = false,

  handleMainClick,
  handleCancel,
  handleClose,
  handleConfirm,

  children,

  title,
  dialogDataTestId,
  mainBtnText = "Lagre",
  cancelButtonText = "Avbryt",
  mainBtnColor = kirkeTheme.palette.primary.main,
  ledetekster,

  fortsettRedigere,
  setOpenConfirmDialogBox,
  validateInput,

  visSlettKnapp = false,
  setOpenSlett,
  mainBtnDataTestid,
  slettBtnDataTestid,
}: Props) => {
  const cancelBtnVariant = fortsettRedigere ? "text" : "contained";
  const cancelBtnColor = fortsettRedigere ? "primary" : "secondary";
  const cancelBtnClick = handleCancel ? handleCancel : handleClose;
  const onCloseEvent = handleConfirm ? handleConfirm : handleClose;
  const handleMainClickIfValid = validInput ? handleMainClick : undefined;

  return (
    <Dialog
      open={open}
      onClose={onCloseEvent}
      aria-labelledby="dialogbox"
      data-testid={dialogDataTestId}
      fullWidth
      disableEnforceFocus
      maxWidth={size}
    >
      <Grid
        container
        display="flex"
        direction="column"
        sx={styles.dialogWrapper}
      >
        <Grid item>
          <DialogTitle onClose={onCloseEvent}>{title}</DialogTitle>
          <DialogContent sx={styles.screenSize}>{children}</DialogContent>
          {error && <DialogBoxAlert feilmelding={error} />}
        </Grid>
        <Grid item container justifyContent="flex-end">
          <DialogActions>
            {visSlettKnapp && setOpenSlett && (
              <Button
                onClick={() => setOpenSlett(true)}
                data-testid={slettBtnDataTestid}
              >
                <DeleteOutline />
                {ledetekster?.slettTekst || "Slett"}
              </Button>
            )}
            <Button
              variant={cancelBtnVariant}
              color={cancelBtnColor}
              onClick={cancelBtnClick}
              sx={{
                [kirkeTheme.breakpoints.down("sm")]: {
                  maxWidth: stackBtnMobil ? "130px" : "none",
                },
              }}
            >
              {cancelButtonText}
            </Button>
            {fortsettRedigere && setOpenConfirmDialogBox && (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setOpenConfirmDialogBox(false)}
              >
                {ledetekster?.forsettRedigereTekst || "Forsett å redigere"}
              </Button>
            )}
            <Button
              variant="contained"
              onClick={handleMainClickIfValid}
              onMouseEnter={validateInput}
              data-testid={mainBtnDataTestid}
              disabled={loading}
              sx={{
                background: mainBtnColor,
                [kirkeTheme.breakpoints.down("sm")]: {
                  maxWidth: stackBtnMobil ? "130px" : "none",
                },
              }}
            >
              {mainBtnText}
            </Button>
          </DialogActions>
        </Grid>
        {loading && <LinearProgress />}
      </Grid>
    </Dialog>
  );
};

export default DialogBox;
