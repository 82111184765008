import { useContext, useEffect, useState } from "react";

import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import { useGetSatsingerForAlleSokn, useGetSoknBySatsingId } from "./../api";
import SatsingerOptions from "./SatsingerOptions";
import { ErrorInfoBox, LoadingSpinner } from "../../../components/common";
import {
  AccessGroupContext,
  AccessGroupContextType,
  setGroup,
} from "../../../components/contexts/AccessGroupContext";
import {
  SoknContext,
  SoknContextType,
} from "../../../components/contexts/SoknContext";
import {
  UserPreferencesContext,
  UserPreferencesContextType,
} from "../../../components/contexts/UserPreferencesContext";
import kirkeTheme from "../../../utils/kirkeTheme";
import { sortAsc, sortDesc } from "../../../utils/tableHelper";
import {
  AlleDataLedetekster,
  SatsingData,
  SoknType,
} from "../../../utils/types";
import Pagination from "../Pagination";

export type SatsingColumnIdType =
  | "sokn"
  | "fellesrad"
  | "prosti"
  | "bispedomme"
  | "organisasjonsnummer"
  | "planperiode"
  | "overskrift"
  | "beskrivelse"
  | "antallAktiviteter";

export interface Column {
  id: SatsingColumnIdType;
  label: string;
  minWidth?: number;
  align?: "right" | "left" | "center";
}

const sortArray = (
  arr: SatsingData[],
  orderBy: "asc" | "desc",
  columnId: SatsingColumnIdType
) => {
  const sortMethod = orderBy === "asc" ? sortAsc : sortDesc;
  if (columnId === "planperiode") {
    return arr.sort((a, b) => sortMethod(a.planStartAar, b.planStartAar));
  } else {
    return arr.sort((a, b) => {
      const aValue = a[columnId];
      const bValue = b[columnId];
      if (!aValue && !bValue) {
        return 0;
      } else if (!aValue) {
        return 1;
      } else if (!bValue) {
        return -1;
      } else {
        return sortMethod(aValue, bValue);
      }
    });
  }
};

const styles = {
  link: {
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
};

interface CustomizedState {
  goBack: boolean;
}

interface Props {
  searchValue?: string;
  ledetekster?: AlleDataLedetekster;
}

const SatsingerTable = ({ searchValue, ledetekster }: Props) => {
  const [filteredSatsingerData, setFilteredSatsingerData] =
    useState<SatsingData[]>();
  const [orderDirection, setOrderDirection] = useState<"asc" | "desc">("asc");
  const [page, setPage] = useState(0);
  const [satsingId, setSatsingId] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const { setAccessGroup } = useContext(
    AccessGroupContext
  ) as AccessGroupContextType;
  const { setSokn, setPlaner } = useContext(SoknContext) as SoknContextType;
  const { rowsPerPage = 20, setRowsPerPage } = useContext(
    UserPreferencesContext
  ) as UserPreferencesContextType;
  const [activeColumn, setActiveColumn] = useState<
    SatsingColumnIdType | undefined
  >();
  const {
    showSatsingColumns = [
      "sokn",
      "fellesrad",
      "prosti",
      "overskrift",
      "beskrivelse",
    ],
    setShowSatsingColumns,
  } = useContext(UserPreferencesContext) as UserPreferencesContextType;

  const {
    data: satsingerData,
    error,
    isLoading,
  } = useGetSatsingerForAlleSokn((data) => setFilteredSatsingerData(data));

  const columns: readonly Column[] = [
    {
      id: "sokn",
      label: ledetekster?.soknKolonneTekst || "Sokn",
      minWidth: 200,
    },
    {
      id: "fellesrad",
      label: ledetekster?.fellesradKolonneTekst || "FR",
      minWidth: 200,
    },
    {
      id: "prosti",
      label: ledetekster?.prostiKolonneTekst || "Prosti",
      minWidth: 150,
    },
    {
      id: "bispedomme",
      label: ledetekster?.bispedommeKolonneTekst || "Bispedømme",
      minWidth: 150,
    },
    {
      id: "organisasjonsnummer",
      label: ledetekster?.orgnummerKolonneTekst || "Orgnummer",
      minWidth: 100,
    },
    {
      id: "planperiode",
      label: ledetekster?.planperiodeKolonneTekst || "Planperiode",
      minWidth: 80,
    },
    {
      id: "overskrift",
      label: ledetekster?.satsingnavnKolonneTekst || "Navn på satsing",
      minWidth: 200,
    },
    {
      id: "beskrivelse",
      label: ledetekster?.satsingbeskrivelseKolonneTekst || "Tekst for satsing",
      minWidth: 400,
    },
    {
      id: "antallAktiviteter",
      label:
        ledetekster?.antallaktiviteterKolonneTekst ||
        "Tilknyttede aktiviteter (antall)",
      minWidth: 200,
    },
  ];

  const handleSortRequest = (columnId: SatsingColumnIdType) => {
    setActiveColumn(columnId);
    if (filteredSatsingerData) {
      sortArray(filteredSatsingerData, orderDirection, columnId);
    }
    setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
  };

  const isActive = (columnId: SatsingColumnIdType) => {
    if (activeColumn && activeColumn === columnId) {
      return true;
    } else {
      return !activeColumn;
    }
  };

  const formaterSatingData = (satsingdata: SatsingData, column: Column) => {
    if (column.id === "planperiode") {
      return `${satsingdata.planStartAar} - ${satsingdata.planSluttAar}`;
    } else if (column.id === "overskrift") {
      return (
        <Grid
          sx={styles.link}
          onClick={() => {
            setSatsingId(satsingdata.id);
          }}
        >
          {satsingdata[column.id]}
        </Grid>
      );
    } else {
      return satsingdata[column.id];
    }
  };

  const showColumn = (column: SatsingColumnIdType) =>
    showSatsingColumns.includes(column);

  const { data: sokn } = useGetSoknBySatsingId(satsingId);
  useEffect(() => {
    if (sokn) {
      setSokn && setSokn(sokn as SoknType);
      setPlaner && setPlaner([]);
      setGroup(sokn, setAccessGroup);
      if (location.state) {
        const { goBack } = location.state as CustomizedState;
        if (goBack) {
          navigate(-1);
        } else {
          navigate("/plan#satsinger");
        }
      } else {
        navigate("/plan#satsinger");
      }
    }
  }, [sokn]);

  return (
    <Box sx={{ paddingTop: kirkeTheme.spacing(5), paddingLeft: "16px" }}>
      {isLoading && <LoadingSpinner />}
      {error && <ErrorInfoBox error={error} />}
      {!isLoading && satsingerData && (
        <>
          <SatsingerOptions
            satsingerData={satsingerData}
            setFilteredSatsingerData={setFilteredSatsingerData}
            showColumns={showSatsingColumns}
            setShowColumns={setShowSatsingColumns}
            searchValue={searchValue}
            columns={columns}
            ledetekster={ledetekster}
          />
          <TableContainer sx={{ overflowX: "visible" }}>
            <Table aria-label="alle data tabell">
              <TableHead>
                <TableRow
                  sx={{
                    borderBottom: "1px solid rgba(224, 224, 224)",
                  }}
                >
                  {columns?.map((column) => {
                    return (
                      showColumn(column.id) && (
                        <TableCell
                          key={column.id}
                          sx={{ minWidth: column.minWidth, paddingLeft: 0 }}
                        >
                          <TableSortLabel
                            onClick={() => handleSortRequest(column.id)}
                            active={isActive(column.id)}
                            direction={orderDirection}
                            IconComponent={ArrowDropDownSharpIcon}
                          >
                            <Typography variant="boldText">
                              {column?.label}
                            </Typography>
                          </TableSortLabel>
                        </TableCell>
                      )
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredSatsingerData &&
                  filteredSatsingerData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((satsing) => (
                      <TableRow
                        tabIndex={0}
                        key={satsing.id}
                        data-testid="alledata-listitem"
                        sx={{
                          verticalAlign: "top",
                          "&:nth-of-type(even)": {
                            backgroundColor: "#E9EDF5",
                          },
                        }}
                      >
                        {columns?.map((column: Column) => {
                          return (
                            showColumn(column.id) && (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                width={column.minWidth}
                                sx={{ paddingLeft: 0 }}
                              >
                                <Typography sx={{ wordBreak: "keep-all" }}>
                                  {formaterSatingData(satsing, column)}
                                </Typography>
                              </TableCell>
                            )
                          );
                        })}
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          {satsingerData && (
            <Pagination
              count={
                filteredSatsingerData !== undefined
                  ? filteredSatsingerData?.length
                  : satsingerData.length
              }
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              page={page}
              setPage={setPage}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default SatsingerTable;
