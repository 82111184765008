import { Box, Grid, Typography } from "@mui/material";

import { forsideQuery } from "../../api";
import { useFetchSanityData } from "../../api/useFetchSanityData";
import renderModule from "../../sanityModules/factory";
import kirkeTheme from "../../utils/kirkeTheme";
import * as Types from "../../utils/types";

const styles = {
  footer: {
    backgroundColor: kirkeTheme.palette.primary.dark,
    color: kirkeTheme.palette.primary.contrastText,
    padding: kirkeTheme.spacing(4),
    paddingBottom: kirkeTheme.spacing(4),
    zIndex: 950,
    position: "relative",
  } as React.CSSProperties,
  grid: {
    justifyContent: "center",
    maxWidth: "1180px",
    displayPrint: "none",
    [kirkeTheme.breakpoints.down("md")]: {
      justifyContent: "left",
    },
  },
  footerElement: {
    padding: kirkeTheme.spacing(4),
    [kirkeTheme.breakpoints.down("md")]: {
      paddingLeft: kirkeTheme.spacing(0),
      paddingRight: kirkeTheme.spacing(6),
      paddingBottom: kirkeTheme.spacing(3),
      paddingTop: kirkeTheme.spacing(3),
    },
  },
  elementTitle: {
    fontWeight: "bold",
    fontSize: "18px",
    marginBottom: kirkeTheme.spacing(1),
  },
};

interface Props {
  title?: string;
  content?: any;
  size?: number;
}

const FooterItem = ({ title, content, size = 4 }: Props) => (
  <Grid item md={size} sx={styles.footerElement}>
    <Grid container sx={{ flexDirection: "column" }} />
    <Grid item>
      <Typography sx={styles.elementTitle} variant="title">
        {title}
      </Typography>
    </Grid>
    <Grid item md={8}>
      {content && renderModule(content, true)}
    </Grid>
  </Grid>
);

const Footer = () => {
  const { data: sanityData } = useFetchSanityData(forsideQuery, "forsideQuery");

  return (
    <footer style={styles.footer}>
      <Box
        display="flex"
        justifyContent="center"
        paddingBottom={kirkeTheme.spacing(3)}
      >
        <Grid container sx={styles.grid}>
          {sanityData?.bunntekstBoks?.bunntekstBokser?.map(
            (boks: Types.EnkeltBunntekstBoxType, key: number) => (
              <FooterItem
                key={key}
                title={boks.tittel}
                content={boks.tekst ?? undefined}
                size={4}
              />
            )
          )}
        </Grid>
      </Box>
      <Typography align="center" fontWeight="bold">
        {sanityData?.bunntekstBoks?.copyrightText || "© 2022"}
      </Typography>
    </footer>
  );
};

export default Footer;
