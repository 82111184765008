import axios, { AxiosResponse, AxiosRequestConfig } from "axios";

import { getTokenSilent } from "../utils/msal/getTokenSilent";
import { HttpConfig, baseUrl, defaultHttpConfig } from "./HttpConfig";

axios.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => response,
  (error) => {
    const clientError =
      error.response &&
      error.response?.status >= 400 &&
      error.response?.status < 500;

    if (error.response?.status === 403) {
      console.log("Forbidden");
    }
    if (error.response?.status === 401) {
      console.log("Unauthorized");
    }

    if (clientError) {
      console.log("Client error");
    }
  }
);

export const httpClient = (config: HttpConfig = defaultHttpConfig) =>
  axios.create(config);

export const httpCall = async (
  url: string,
  options: AxiosRequestConfig
): Promise<any> => {
  const token = await getTokenSilent();

  const fil = "Fil";
  const bilde = "LastOppForsidebilde";
  const contentType =
    url.includes(fil) || url.includes(bilde)
      ? "multipart/form-data"
      : "application/json";

  const config = new HttpConfig(token, contentType);

  const response = await httpClient(config).request({
    url: baseUrl + url,
    ...options,
  });
  return response.data;
};

export const httpGet = async (url: string): Promise<any> =>
  httpCall(url, { method: "get" });

export const httpGetBlob = async (url: string): Promise<any> =>
  httpCall(url, { method: "get", responseType: "blob" });

export const httpPost = async (url: string, body?: any): Promise<any> =>
  httpCall(url, { data: body, method: "post" });

export const httpPut = async (url: string, body: any): Promise<any> =>
  httpCall(url, { data: body, method: "put" });

export const httpDelete = async (url: string): Promise<any> =>
  httpCall(url, { method: "delete" });
