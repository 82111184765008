import { useState } from "react";

import { FormControl, Grid, MenuItem, Select } from "@mui/material";

import kirkeTheme from "../../utils/kirkeTheme";

const styles = {
  uttrekkselect: {
    backgroundColor: kirkeTheme.palette.common.white,
    height: "40px",
    width: "271px",
    maxWidth: "100%",
  },
  formControl: {
    top: kirkeTheme.spacing(2),
    margin: "0",
    padding: "0",
  },
};

interface Props {
  downloadExcel: () => Promise<void>;
}

const DownloadExcel = ({ downloadExcel }: Props) => {
  const [showSelectUttrekk, setShowSelectUttrekk] = useState<boolean>(false);

  return (
    <Grid item xs={12} md={3}>
      <FormControl fullWidth sx={styles.formControl}>
        <Select
          open={showSelectUttrekk}
          onOpen={() => setShowSelectUttrekk(true)}
          onClose={() => setShowSelectUttrekk(false)}
          renderValue={() => "Eksporter"}
          value={""}
          displayEmpty
          onChange={() => downloadExcel()}
          sx={styles.uttrekkselect}
        >
          <MenuItem key={"alleFelter"} value={"alleFelter"}>
            {"Eksporter alle felter til Excel"}
          </MenuItem>
        </Select>
      </FormControl>
    </Grid>
  );
};

export default DownloadExcel;
