export const ROLES = {
  les: "LES",
  skriv: "SKRIV",
  admin: "ADMIN",
};

export const SCOPES = {
  leseTilgang: "lesetilgang",
  skriveTilgang: "skrivetilgang",
  adminTilgang: "admintilgang",
};

export const PERMISSIONS = {
  [ROLES.les]: [SCOPES.leseTilgang],
  [ROLES.skriv]: [SCOPES.leseTilgang, SCOPES.skriveTilgang],
  [ROLES.admin]: [
    SCOPES.leseTilgang,
    SCOPES.skriveTilgang,
    SCOPES.adminTilgang,
  ],
};
