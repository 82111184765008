import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { httpGet } from "../../../services/HttpService";
import * as Types from "../../../utils/types";

const hentAlleSokn = async () => {
  const res = await httpGet("api/sokn/getallesokn");
  if (res.length > 1) {
    return res.sort((a: Types.AlleSoknType, b: Types.AlleSoknType) =>
      a.navn.toLowerCase().localeCompare(b.navn.toLowerCase(), "no-NO")
    );
  } else {
    return res;
  }
};

export const useGetAlleSokn = () =>
  useQuery<Types.AlleSoknType[], AxiosError>({
    queryKey: ["alleSoknQuery"],
    queryFn: () => hentAlleSokn(),
  });
