export const config = {
  projectId: window.config.REACT_APP_SANITY_API_PROJECT_ID,
  dataset: window.config.REACT_APP_SANITY_API_DATASET,
  useCdn: true, // `false` if you want to ensure fresh data
  apiVersion: "2021-03-25",
  token: window.config.REACT_SANITY_API_TOKEN_READ,
  ignoreBrowserTokenWarning: true,
};

export default config;
