import { Box } from "@mui/material";
import imageUrlBuilder from "@sanity/image-url";

import { sanityClient } from "../lib/sanity.server";
import * as Types from "../utils/types";

interface ImageProps {
  content: Types.ImageWithAltType;
}

const builder = imageUrlBuilder(sanityClient);

const urlFor = (source: Types.ImageType) => builder.image(source);

export function ModuleImage({ content }: ImageProps) {
  const {
    bilde,
    alt,
    bredde = 630,
    fullbredde,
    bildetittel,
    bildebeskrivelse,
  } = content;
  return (
    <Box
      sx={{
        marginTop: "20px",
        marginBottom: "20px",
        width: fullbredde ? "100%" : bredde,
      }}
    >
      {bildetittel && (
        <p>
          <b>{bildetittel}</b>
        </p>
      )}
      <img
        src={urlFor(bilde).url()}
        alt={alt}
        width={fullbredde ? "100%" : bredde}
      />
      {bildebeskrivelse && (
        <p>
          <small>{bildebeskrivelse}</small>
        </p>
      )}
    </Box>
  );
}

export default ModuleImage;
