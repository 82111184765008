import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Typography, IconButton } from "@mui/material";
import DialogTitleMUI from "@mui/material/DialogTitle";

import kirkeTheme from "../../../utils/kirkeTheme";

interface DialogTitleProps {
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle: React.FC<DialogTitleProps> = ({
  children,
  onClose,
  ...other
}) => {
  const styles = {
    closeButton: {
      position: "absolute",
      right: kirkeTheme.spacing(0.5),
      top: kirkeTheme.spacing(0.5),
    },
  };
  return (
    <DialogTitleMUI {...other}>
      <Typography variant="boldText">{children}</Typography>
      {onClose ? (
        <IconButton
          sx={styles.closeButton}
          id="closeDialog"
          aria-label="close"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitleMUI>
  );
};

export default DialogTitle;
