import React from "react";

import "./index.css";
import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
} from "@azure/msal-browser";
import { ThemeProvider } from "@mui/system";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import * as ReactDOM from "react-dom/client";

import App from "./App";
import { AccessGroupContextProvider } from "./components/contexts/AccessGroupContext";
import { SoknContextProvider } from "./components/contexts/SoknContext";
import { UserPreferencesContextProvider } from "./components/contexts/UserPreferencesContext";
import reportWebVitals from "./reportWebVitals";
import kirkeTheme from "./utils/kirkeTheme";
import { msalConfig } from "./utils/msal/authConfig";

export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const { account } = payload;
    msalInstance.setActiveAccount(account);
  }
});

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container as HTMLElement);

Sentry.init({
  dsn: window.config.SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

root.render(
  <React.StrictMode>
    <ThemeProvider theme={kirkeTheme}>
      <SoknContextProvider>
        <UserPreferencesContextProvider>
          <AccessGroupContextProvider>
            <App pca={msalInstance} />
          </AccessGroupContextProvider>
        </UserPreferencesContextProvider>
      </SoknContextProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
