/* eslint-disable max-classes-per-file */
export const baseUrl = window.config.apiUrl;

export class HttpConfig {
  baseUrl: string;

  headers: {
    "Content-Type": string;
    Authorization?: string;
  };

  constructor(token: string, contentType: string) {
    this.baseUrl = baseUrl;

    this.headers = {
      "Content-Type": `${contentType}`,
      Authorization: `bearer ${token}`,
    };
  }
}

export const defaultHttpConfig = {
  baseUrl,
  headers: {
    "Content-Type": "application/json",
  },
};
