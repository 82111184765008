import { useState } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { FormControl } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";

interface Props {
  setSearchValue: (value: string | undefined) => void;
  placeholderText?: string;
}

const SearchBar = ({ setSearchValue, placeholderText = "Søk..." }: Props) => {
  const [value, setValue] = useState<string | undefined>();

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleClearClick = () => {
    setValue("");
    setSearchValue("");
  };

  return (
    <FormControl sx={{ display: "block" }}>
      <TextField
        id="search-bar"
        className="text"
        variant="outlined"
        value={value}
        placeholder={placeholderText}
        size="small"
        onChange={handleValueChange}
        onKeyDown={(e) => {
          e.key === "Enter" && setSearchValue(value);
        }}
        InputProps={{
          endAdornment: (
            <IconButton
              sx={{ visibility: value ? "visible" : "hidden", padding: 0 }}
              onClick={handleClearClick}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
        sx={{ backgroundColor: "white", maxWidth: "70%" }}
      />
      <IconButton
        type="submit"
        aria-label="search"
        sx={{ paddingTop: "16px" }}
        onClick={() => setSearchValue(value)}
      >
        <SearchIcon />
      </IconButton>
    </FormControl>
  );
};

export default SearchBar;
