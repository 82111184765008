import { useContext } from "react";

import { useQuery } from "@tanstack/react-query";

import {
  SoknContext,
  SoknContextType,
} from "../components/contexts/SoknContext";
import { sanityClient } from "../lib/sanity.server";
export const useFetchSanityData = (
  query: string,
  queryKey: string,
  enabled = true
) => {
  const { sokn } = useContext(SoknContext) as SoknContextType;
  return useQuery({
    queryKey: [queryKey],
    queryFn: () =>
      sanityClient
        .fetch(query, {
          malform: sokn?.malform || "nb",
        })
        .then((res) => res),
    enabled,
  });
};
