import Vimeo from "@u-wave/react-vimeo";

interface Props {
  video?: string;
}

export function Video({ video }: Props) {
  return <>{video && <Vimeo video={video} width="100%" responsive />} </>;
}

export default Video;
