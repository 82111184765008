export const currentYear = new Date().getFullYear();
export const currentDate = new Date();
const aarListeMaker = () => {
  const years = [];
  let startYear = currentYear - 2;
  while (startYear <= currentYear + 4) {
    years.push({ id: startYear.toString(), tittel: startYear.toString() });
    startYear++;
  }
  return years;
};
export const aarListe = aarListeMaker();

export const range = (fraAar: number, tilAar: number, step = 1) => {
  let period = [];
  if (fraAar === tilAar) {
    period.push(fraAar);
  } else {
    period = Array(Math.ceil((tilAar + 1 - fraAar) / step))
      .fill(fraAar)
      .map((x, y) => x + y * step);
  }
  return period;
};

export const formatertDato = (
  dato: string,
  style: "medium" | "short" | "full" | "long" | undefined
) => {
  const date = new Date(dato);
  return date.toLocaleDateString("nb-NO", { dateStyle: style });
};

export const formaterDatoSkjerikirken = (dato: Date) => {
  const date = new Date(dato);
  return new Intl.DateTimeFormat("no-NB", {
    dateStyle: "full",
    timeStyle: "short",
  }).format(date);
};
