import { Grid, Typography } from "@mui/material";

import { forsideQuery } from "../../api";
import { useFetchSanityData } from "../../api/useFetchSanityData";
import { KirkeCard } from "../../components/common";
import Container from "../../components/layout/Container";
import { renderModule } from "../../sanityModules/factory";
import * as Types from "../../utils/types";
import useDocumentTitle from "../../utils/useDocumentTitle";

const NyheterSide = () => {
  const { data: sanityData } = useFetchSanityData(forsideQuery, "forsideQuery");

  useDocumentTitle(sanityData?.sisteAktiviteter?.nyheterPageTitle || "Nyheter");

  return (
    <Container>
      <KirkeCard title="Nyheter">
        <Grid container spacing={2} direction="row">
          <Grid item container lg={8}>
            {sanityData?.sisteAktiviteter?.enkeltAktiviteter?.map(
              (aktivitet: Types.enkeltAktiviteter, i: number) => (
                <Grid item key={i} pb={4}>
                  {aktivitet.tittel && (
                    <Typography variant="boldText">
                      {aktivitet.tittel}
                    </Typography>
                  )}
                  {aktivitet.dato && (
                    <Typography variant="body2">{aktivitet.dato}</Typography>
                  )}
                  {aktivitet.tekstLokal && renderModule(aktivitet.tekstLokal)}
                </Grid>
              )
            )}
          </Grid>
        </Grid>
      </KirkeCard>
    </Container>
  );
};

export default NyheterSide;
