import { ReactNode } from "react";

import * as Types from "../utils/types";
import { ModuleImage } from "./moduleImage";
import { PortableText } from "./modulePortableText";

enum PageModule {
  RICH_TEXT = "richText",
  IMAGE = "imageWithAlt",
}

export function renderModule(
  pageModule: Types.SideModulType,
  inheritTextColor = false
): ReactNode {
  let pageElement;
  switch (pageModule._type) {
    case PageModule.RICH_TEXT:
      pageElement = pageModule as Types.RichTextType;
      return (
        <PortableText
          content={pageElement}
          inheritTextColor={inheritTextColor}
        />
      );
    case PageModule.IMAGE:
      pageElement = pageModule as Types.ImageWithAltType;
      return <ModuleImage content={pageElement} />;
    default:
      return null;
  }
}

export default renderModule;
