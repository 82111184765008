import { createTheme, responsiveFontSizes } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    link: React.CSSProperties;
    boldText: React.CSSProperties;
    title: React.CSSProperties;
    number: React.CSSProperties;
    redBoldText: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    link?: React.CSSProperties;
    boldText?: React.CSSProperties;
    title?: React.CSSProperties;
    number?: React.CSSProperties;
    redBoldText?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    link: true;
    boldText: true;
    title: true;
    number: true;
    redBoldText: true;
  }
}

const KirkeTheme = createTheme({
  palette: {
    primary: {
      main: "#C4291D",
      light: "#EAE7D8",
      dark: "#333333",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#E9ECF1",
      light: "rgba(0, 0, 0, 0.10)",
    },
    error: {
      main: "#D90011",
    },
    info: {
      main: "#100977",
    },
    success: {
      main: "#1C4228",
      light: "#82D177",
    },
    common: {
      black: "#000000",
      white: "#FFFFFF",
    },
    text: {
      primary: "#333333",
      disabled: "rgba(0, 0, 0, 0.50)",
    },
    background: {
      default: "#F5F7FB",
    },
  },
  typography: {
    fontFamily: "Arial",
    h1: {
      fontSize: "2rem",
      fontWeight: 400,
      lineHeight: 1.5,
      fontFamily: "Nunito sans",
    },
    h2: {
      fontSize: "1.70rem",
      fontWeight: 700,
      lineHeight: 1.5,
      fontFamily: "Nunito sans",
    },
    title: {
      fontSize: "1.375rem",
      fontWeight: 700,
      lineHeight: 1.5,
      fontFamily: "Nunito sans",
    },
    subtitle1: {
      fontSize: "1.25rem",
      fontWeight: 700,
      lineHeight: 1.5,
    },
    h4: {
      fontSize: "1.2rem",
      fontWeight: 700,
      lineHeight: 1.5,
      fontFamily: "Nunito sans",
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: "1rem",
      lineHeight: 1.5,
      color: "#333333",
    },
    button: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.5,
    },
    redBoldText: {
      fontWeight: 700,
      color: "#C4291D",
    },
    boldText: {
      fontWeight: 700,
      lineHeight: 1.5,
    },
    link: {
      color: "#C4291D",
      textDecoration: "underline",
      textTransform: "none",
      lineHeight: 1.5,
    },
    number: {
      fontSize: "2.5rem",
      fontWeight: 700,
      lineHeight: 1.5,
    },
    caption: {
      fontSize: "0.875rem",
    },
  },
  spacing: (factor: number) => `${8 * factor}px`,
  components: {
    MuiButton: {
      defaultProps: {
        size: "large",
      },
    },
    MuiFilledInput: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: "large",
      },
    },
    MuiInputBase: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiInputLabel: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiListItem: {
      defaultProps: {
        dense: true,
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiTable: {
      defaultProps: {
        size: "medium",
      },
    },
    MuiTextField: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiToolbar: {
      defaultProps: {
        variant: "dense",
      },
    },
  },
});

export default responsiveFontSizes(KirkeTheme);
