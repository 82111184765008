import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { httpGet } from "../../../services/HttpService";
import { GronnkirkeData } from "../../../utils/types";

const hentAlleGronnkirke = async () => {
  const alleGronnkirke: GronnkirkeData[] = await httpGet(
    "api/gronnkirke/GetAlleGronnkirke"
  );
  return alleGronnkirke;
};

export const useGetGronnkirkeForAlleSokn = (
  onsuccess?: ((data: GronnkirkeData[]) => void) | undefined
) =>
  useQuery<GronnkirkeData[], AxiosError>({
    queryKey: ["GronnkirkeAlleSoknQuery"],
    queryFn: () => hentAlleGronnkirke(),
    onSuccess: onsuccess,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
