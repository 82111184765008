import {
  PublicClientApplication,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";

import { loginRequest, msalConfig } from "./authConfig";

export const msalInstance = new PublicClientApplication(msalConfig);

const getAccount = () => {
  const currentAccounts = msalInstance.getAllAccounts();

  if (currentAccounts === null) {
    console.log("No accounts detected");
    return undefined;
  }

  if (currentAccounts.length > 1) {
    console.log("Multiple accounts detected, need to add choose account code.");
    return currentAccounts[0];
  }
  if (currentAccounts.length === 1) {
    return currentAccounts[0];
  }
};

export const getTokenSilent = async () => {
  const account = getAccount();
  if (account === undefined) {
    console.log(
      "[getTokenSilent] Account is undefined. Cannot do msalInstance.acquireTokenSilent without account"
    );
    return "";
  }
  const request = {
    ...loginRequest,
    account,
  };

  try {
    const res = await msalInstance.acquireTokenSilent(request);
    return res.accessToken;
  } catch (e) {
    if (e instanceof InteractionRequiredAuthError) {
      msalInstance
        .acquireTokenRedirect(request)
        .catch((error) => console.log(error));
    } else {
      console.log(e);
    }
  }
  return "";
};
