import { useQuery } from "@tanstack/react-query";

import { httpGet } from "../../../services/HttpService";
import { AlleSoknType } from "../../../utils/types";

export const useGetAlleSoknForRegistrering = (
  onsuccess?: ((data: AlleSoknType[]) => void) | undefined,
  onerror?: (() => void) | undefined
) =>
  useQuery({
    queryKey: ["alleSoknForRegistrering"],
    queryFn: () => httpGet(`api/Sokn/GetAlleSoknForRegistrering`),
    onSuccess: onsuccess,
    onError: onerror,
    // refetchOnWindowFocus: false,
    // refetchOnMount: false,
    // refetchOnReconnect: false,
  });
