import { groq } from "next-sanity";

export const forsideQuery = groq`
*[_type == "forside" && !(_id in path('drafts.**'))][0] {
    "soknPageTitle": coalesce(soknPageTitle[$malform], soknPageTitle.nb),
    "headerTittelLokal": coalesce(headerTittelLocale[$malform], headerTittelLocale.nb),
    "forsideTittelLokal": coalesce(forsideTittelLocale[$malform], forsideTittelLocale.nb),
    "forsideBeskrivelse": coalesce(forsideBeskrivelse[$malform], forsideBeskrivelse.nb),
    "kartBoksTittel": coalesce(kartBoksTittel[$malform], kartBoksTittel.nb),
    sisteAktiviteter {
      "nyheterPageTitle": coalesce(nyheterPageTitle[$malform], nyheterPageTitle.nb),
      "tittelLokal": coalesce(tittelLocale[$malform], tittelLocale.nb),
      "linkenTekst": coalesce(linkenTekst[$malform], linkenTekst.nb),
      enkeltAktiviteter [] {
        "tittel": coalesce(tittel[$malform], tittel.nb),
        dato,
        "tekstLokal": coalesce(tekstLocale[$malform], tekstLocale.nb),
      },
    },
    snarveier {
      "tittelLokal": coalesce(tittelLocale[$malform], tittelLocale.nb),
      enkeltSnarveier [] {
        "tittel": coalesce(tittel[$malform], tittel.nb),
        href,
        icon,
        "tooltip": coalesce(tooltip[$malform], tooltip.nb),
      },
    },
    statusTall {
      "tittelLokal": coalesce(tittelLocale[$malform], tittelLocale.nb),
      enkeltStatusTall [] {
        "tittel": coalesce(tittel[$malform], tittel.nb),
        "undertittel": coalesce(undertittel[$malform], undertittel.nb),
        href,
        "tooltip": coalesce(tooltip[$malform], tooltip.nb),
      },
    },
    nyttOmPlanprosessen {
      "tittel": coalesce(tittel[$malform], tittel.nb),
      enkeltPlanNyheter [] {
        "tittel": coalesce(tittel[$malform], tittel.nb),
        href,
      }
    },
    menighetensDokumenter {
      "tittel": coalesce(tittel[$malform], tittel.nb),
      "tekst": coalesce(tekst[$malform], tekst.nb),
    },
    bunntekstBoks {
      bunntekstBokser [] {
        "tittel": coalesce(tittel[$malform], tittel.nb),
        "tekst": coalesce(tekst[$malform], tekst.nb),
      },
      "copyrightText": coalesce(copyrightText[$malform], copyrightText.nb)
    },
    ledetekster {
      "nyligEndretTekst": coalesce(nyligEndret[$malform], nyligEndret.nb),
      "planTekst": coalesce(plan[$malform], plan.nb),
      "aktivitetTekst": coalesce(aktivitet[$malform], aktivitet.nb),
      "satsingTekst": coalesce(satsing[$malform], satsing.nb),
      "aktiviteterFraTekst": coalesce(aktiviteterFra[$malform], aktiviteterFra.nb),
      "hvaSkjerNesteUkeTekst": coalesce(hvaSkjerNesteUke[$malform], hvaSkjerNesteUke.nb),
      "menighetenTallTekst": coalesce(menighetenTall[$malform], menighetenTall.nb),
      "antallTekst": coalesce(antall[$malform], antall.nb),
      "endringTekst": coalesce(endring[$malform], endring.nb),
      "dapshandlingerTekst": coalesce(dapshandlinger[$malform], dapshandlinger.nb),
      "medlemmerTekst": coalesce(medlemmer[$malform], medlemmer.nb),
      "konfirmanterTekst": coalesce(konfirmanter[$malform], konfirmanter.nb),
      "seFlereTallForMenighetenanterTekst": coalesce(seFlereTallForMenighetenanter[$malform], seFlereTallForMenighetenanter.nb),
      "testVersjonAdvarsel": coalesce(testVersjonAdvarsel[$malform], testVersjonAdvarsel.nb),
      "seKartOverMenigheten": coalesce(seKartOverMenigheten[$malform], seKartOverMenigheten.nb),
      "statusForSoknetsPlan": coalesce(statusForSoknetsPlan[$malform], statusForSoknetsPlan.nb),
      "ikkeOpprettetPlanTekst": coalesce(ikkeOpprettetPlanTekst[$malform], ikkeOpprettetPlanTekst.nb),
      "linkTilOpprettPlanTekst": coalesce(linkTilOpprettPlanTekst[$malform], linkTilOpprettPlanTekst.nb),
    },
  }
`;

export default forsideQuery;
