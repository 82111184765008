import { Button, Grid, Typography } from "@mui/material";

import kirkeTheme from "../../utils/kirkeTheme";

const styles = {
  openButton: {
    color: kirkeTheme.palette.common.black,
    paddingLeft: 0,
  },
  buttonText: {
    marginLeft: kirkeTheme.spacing(1),
    paddingTop: kirkeTheme.spacing(2),
    paddingBottom: kirkeTheme.spacing(0),
    height: "40px",
    textTransform: "none",
    textDecoration: "underline",
  },
};

interface Props {
  tilbakestillBrukervalg: () => void;
}

const TilbakestillValgKnapp = ({ tilbakestillBrukervalg }: Props) => {
  return (
    <Grid item xs={12} md={3.2}>
      <Button sx={styles.openButton} onClick={() => tilbakestillBrukervalg()}>
        <Typography sx={styles.buttonText}>Nullstill valg</Typography>
      </Button>
    </Grid>
  );
};
export default TilbakestillValgKnapp;
