import { Grid } from "@mui/material";

import { KirkeCard } from "../../components/common";
import kirkeTheme from "../../utils/kirkeTheme";
import { LedeteksterRegistrerBrukerType } from "../../utils/types";

interface Props {
  sanityData: LedeteksterRegistrerBrukerType;
}

const TakkForRegistrering = ({ sanityData }: Props) => {
  return (
    <Grid
      container
      item
      direction="column"
      flexDirection="column"
      marginBottom={kirkeTheme.spacing(16)}
      spacing={4}
      xs={12}
      sm={12}
      md={12}
      lg={8}
    >
      <Grid item>
        <KirkeCard
          title={sanityData?.innsendtSkjemaTittel || "Takk skal du ha"}
          h1
          background="none"
          padding="0"
          dataTestId="aktiviteterpage-kirkecard"
        >
          {sanityData?.innsendtSkjemaUndertekst ||
            "Din info er sendt inn og vi jobber nå med å opprette bruker til deg. Vi sjekker din info opp med vår database og vil sjekke om du allerede har en eksisterende bruker eller ikke, samt hvilke tilganger du skal ha. Merk at dette kan ta litt tid."}
        </KirkeCard>
      </Grid>
    </Grid>
  );
};
export default TakkForRegistrering;
