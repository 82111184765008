import { Link } from "@mui/material";
import Box from "@mui/material/Box";
import { Link as RouterLink } from "react-router-dom";

const styles = {
  logo: {
    cursor: "pointer",
  },
  box: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    maxWidth: "100%",
    minWidth: "auto",
  },
};

const KirkeLogo = () => (
  <Box>
    <Link
      component={RouterLink}
      to="/sokn"
      style={{ display: "flex", textDecoration: "none", color: "black" }}
      data-testid="kirkelogo"
    >
      <img
        style={styles.logo}
        src="/Images/Kirka-var-logo.svg"
        alt="kirke logo"
        width="61px"
        height="61px"
      />
    </Link>
  </Box>
);

export default KirkeLogo;
