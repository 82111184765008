import { useState, useRef, useEffect } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Button, Typography } from "@mui/material";

import { renderModule } from "./factory";
import kirkeTheme from "../utils/kirkeTheme";
import * as Types from "../utils/types";

interface FaktaboksProps {
  content: Types.FaktaboksType;
}

const styles = {
  container: {
    backgroundColor: kirkeTheme.palette.primary.light,
    padding: kirkeTheme.spacing(2),
    marginTop: kirkeTheme.spacing(2),
    borderBottomWidth: "1px",
    borderBottomColor: kirkeTheme.palette.info.main,
    borderBottomStyle: "solid",
  },
  open: {
    height: "100%",
    transition: kirkeTheme.transitions.create(["height"], {
      duration: kirkeTheme.transitions.duration.short,
      easing: kirkeTheme.transitions.easing.easeInOut,
    }),
  },
  close: {
    height: "200px",
    overflow: "hidden",
    transition: kirkeTheme.transitions.create(["height"], {
      duration: kirkeTheme.transitions.duration.short,
      easing: kirkeTheme.transitions.easing.easeInOut,
    }),
  },
  btnWrapper: {
    width: kirkeTheme.spacing(14),
  },
  btn: {
    textTransform: "none",
    width: "100%",
    marginTop: kirkeTheme.spacing(2),
    marginBottom: kirkeTheme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    background: kirkeTheme.palette.common.black,
  },
};

export function ModuleFaktaboks({ content }: FaktaboksProps) {
  const { tittel, tekst } = content;
  const [showButton, setShowButton] = useState(false);
  const textRef = useRef();
  const textRefCurrent: any = textRef.current;

  const isOverflow = (e: any) => {
    return e.offsetHeight > 300;
  };

  useEffect(() => {
    if (textRefCurrent) {
      setShowButton(isOverflow(textRefCurrent));
      setOpen(!isOverflow(textRefCurrent));
    }
  }, [textRefCurrent?.offsetHeight && !showButton]);

  const [open, setOpen] = useState(true);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Box sx={styles.container}>
      <Box sx={open ? styles.open : styles.close} ref={textRef}>
        <Typography variant="title">{tittel}</Typography>
        {renderModule(tekst)}
      </Box>
      {showButton && (
        <Box sx={styles.btnWrapper}>
          <Button
            variant="contained"
            sx={styles.btn}
            endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            onClick={() => handleClick()}
          >
            <b>{open ? "Mindre" : "Mer"}</b>
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default ModuleFaktaboks;
