import "react-toastify/dist/ReactToastify.css";
import CloseIcon from "@mui/icons-material/Close";
import { toast, ToastOptions } from "react-toastify";

import kirkeTheme from "../../utils/kirkeTheme";

const defaultProps: ToastOptions = {
  position: "bottom-center",
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  theme: "colored",
};

export const varslingPopup = {
  success(message: string, options: ToastOptions = {}) {
    toast.success(message, {
      ...options,
      ...defaultProps,
      autoClose: 3000,
      style: { backgroundColor: kirkeTheme.palette.success.main },
    });
  },
  warning(message: string, options: ToastOptions = {}) {
    toast.warning(message, {
      ...options,
      ...defaultProps,
      autoClose: 5000,
      style: { backgroundColor: kirkeTheme.palette.warning.main },
      className: "snackbarWarning",
      closeButton: <CloseIcon fontSize="small" />,
    });
  },
  error(message: string, options: ToastOptions = {}) {
    if (!toast.isActive(message)) {
      toast.error(message, {
        ...options,
        ...defaultProps,
        autoClose: 5000,
        style: { backgroundColor: kirkeTheme.palette.error.main },
        className: "snackbarError",
        toastId: message,
      });
    }
  },
  info(message: string, options: ToastOptions = {}) {
    toast.info(message, {
      ...options,
      ...defaultProps,
      autoClose: 5000,
      style: { backgroundColor: kirkeTheme.palette.info.main },
      className: "snackbarInfo",
    });
  },
};

export default varslingPopup;
