import { useState } from "react";

import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import {
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Typography,
  Table,
} from "@mui/material";

import { AlleTallPlanerColumn, hasOwn } from "./PlanerTallTable";
import UnderliggendeSoknRow from "./UnderliggendeSoknRow";
import { formatertDato } from "../../../utils/datoModifikasjoner";
import kirkeTheme from "../../../utils/kirkeTheme";
import { AlleTallPlanFellesrad } from "../../../utils/types";

interface Props {
  fellesrad: AlleTallPlanFellesrad;
  columns: readonly AlleTallPlanerColumn[];
}

const UnderliggendeFellesradRow = ({ fellesrad, columns }: Props) => {
  const [open, setOpen] = useState<boolean>();
  const mappedData = columns.map((column) => {
    if (fellesrad && hasOwn(fellesrad, column.id)) {
      return fellesrad[column.id];
    }
    return null;
  });
  return (
    <Table>
      <TableRow>
        {Object.entries(mappedData).map(([key, value]) => {
          if (value == null) {
            return <TableCell key={key}></TableCell>;
          } else {
            if (key === "0") {
              return (
                <TableCell key={key}>
                  <Typography sx={{ marginLeft: kirkeTheme.spacing(4) }}>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => setOpen(!open)}
                    >
                      {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                    </IconButton>
                    {value}
                  </Typography>
                </TableCell>
              );
            } else {
              if (parseInt(key) == columns.length - 1) {
                return (
                  <TableCell
                    key={key}
                    sx={{
                      width: columns[parseInt(key)].minWidth ?? 0,
                    }}
                  >
                    {formatertDato(value as string, "long")}
                  </TableCell>
                );
              } else {
                return (
                  <TableCell
                    key={key}
                    sx={{
                      width: columns[parseInt(key)].minWidth ?? 0,
                    }}
                  >
                    {value}
                  </TableCell>
                );
              }
            }
          }
        })}
      </TableRow>
      {/* <TableRow
        sx={{
          borderBottom: "1px solid rgba(224, 224, 224)",
        }}
      >
        <TableCell
          sx={{
            width: columns[0].minWidth ?? 0,
          }}
        >
          <Typography sx={{ marginLeft: kirkeTheme.spacing(4) }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
            </IconButton>
            {fellesrad.navn}
          </Typography>
        </TableCell>
        <TableCell
          sx={{
            minWidth: columns[1].minWidth,
          }}
        >
          {fellesrad.sumHarPlan}
        </TableCell>
        <TableCell
          sx={{
            minWidth: columns[2].minWidth,
          }}
        >
          {fellesrad.sumHarPlanlegging}
        </TableCell>
        <TableCell
          sx={{
            minWidth: columns[3].minWidth,
          }}
        >
          {fellesrad.sumHarBeskrivelse}
        </TableCell>
        <TableCell
          sx={{
            minWidth: columns[4].minWidth,
          }}
        >
          {fellesrad.sumHarRessurser}
        </TableCell>
        <TableCell
          sx={{
            minWidth: columns[5].minWidth,
          }}
        >
          {fellesrad.sumHarUtfordringer}
        </TableCell>
        <TableCell
          sx={{
            minWidth: columns[6].minWidth,
          }}
        >
          {fellesrad.sumAntallSatsinger}
        </TableCell>
        <TableCell
          sx={{
            minWidth: columns[7].minWidth,
          }}
        >
          <Typography>{formatertDato(fellesrad.sistEndret, "long")}</Typography>
        </TableCell>
      </TableRow> */}
      <TableRow>
        <TableCell colSpan={columns.length} sx={{ padding: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {fellesrad.underliggendeSokn?.map((sokn) => (
              <UnderliggendeSoknRow
                key={sokn.navn}
                sokn={sokn}
                columns={columns}
              />
            ))}
          </Collapse>
        </TableCell>
      </TableRow>
    </Table>
  );
};

export default UnderliggendeFellesradRow;
