import { Grid, Link, List, ListItem, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import { KirkeCard } from ".";
import ExternalLinkIcon from "./ExternalLinkIcon";
import renderModule from "../../sanityModules/factory";
import { externalLinkRegex } from "../../utils/constants";
import kirkeTheme from "../../utils/kirkeTheme";
import * as Types from "../../utils/types";
interface Props {
  hjelpRessurser?: Types.HjelpRessurserType;
  marginTop?: string;
}

const styles = {
  wrapper: {
    maxWidth: "300px",
  },
  listItem: {
    paddingLeft: 0,
    color: kirkeTheme.palette.primary.main,
  },
};

export const InfoCard = ({ hjelpRessurser, marginTop }: Props) => (
  <Grid container style={styles.wrapper} display="flex">
    {hjelpRessurser?.bilde && (
      <Grid style={styles.wrapper}>{renderModule(hjelpRessurser.bilde)}</Grid>
    )}
    <KirkeCard
      title={hjelpRessurser?.tittelLokal}
      background="none"
      padding="0"
      margin-top={marginTop}
    >
      <List>
        {hjelpRessurser?.linker &&
          hjelpRessurser.linker.map((item, i) => (
            <ListItem key={i} style={styles.listItem}>
              <Link
                component={RouterLink}
                to={item.href}
                target={item?.blank ? "_blank" : "_self"}
                rel="noreferrer"
              >
                <Typography variant="body1">{item.lenketittelLokal}
                  {externalLinkRegex.test(item.href) && <ExternalLinkIcon />}
                </Typography>
              </Link>
            </ListItem>
          ))}
      </List>
    </KirkeCard>
  </Grid>
);

export default InfoCard;
