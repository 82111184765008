import { groq } from "next-sanity";

export const feilmeldingerQuery = groq`
*[_type == "feilmeldinger" && !(_id in path('drafts.**'))][0] {
    "notFoundPageTitle": coalesce(notFoundPageTitle[$malform], notFoundPageTitle.nb),
    "validationError": coalesce(validationError[$malform], validationError.nb),
    "notFoundError": coalesce(notFoundError[$malform], notFoundError.nb),
    "planperiodeError" : coalesce(planperiodeError[$malform], planperiodeError.nb),
    "http": http {
      "firenullfire": coalesce(firenullfire[$malform], firenullfire.nb),
      "firenullen": coalesce(firenullen[$malform], firenullen.nb),
      "nettverksfeil": coalesce(nettverksfeil[$malform], nettverksfeil.nb),
      "femhundre": coalesce(femhundre[$malform], femhundre.nb),
    },
    "prostErIkkeLagtTilIBispedommet": prostErIkkeLagtTilIBispedommet {
      "ikkeRegistertIBispedomme": coalesce(ikkeRegistertIBispedomme[$malform], ikkeRegistertIBispedomme.nb),
      "sjekkEpost": coalesce(sjekkEpost[$malform], sjekkEpost.nb),
      "forsattFeil": coalesce(forsattFeil[$malform], forsattFeil.nb),
    },
    "uventetFeil": coalesce(uventetFeil[$malform], uventetFeil.nb),
  }
`;

export default feilmeldingerQuery;
