import { useState } from "react";

import { AppBar, Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";

import {
  loginsideQuery,
  registrerBrukerQuery,
  useFetchSanityData,
} from "../../api";
import KirkeLogo from "../../components/layout/KirkeLogo";
import kirkeTheme from "../../utils/kirkeTheme";
import useDocumentTitle from "../../utils/useDocumentTitle";
import RegistrerBrukerForm from "./RegistrerBrukerForm";
import TakkForRegistrering from "./TakkForRegistrering";

const styles = {
  container: {
    backgroundColor: kirkeTheme.palette.common.white,
    width: "100%",
  },
  appBar: {
    paddingLeft: "5%",
    paddingRight: "5%",
    paddingTop: kirkeTheme.spacing(3),
    paddingBottom: kirkeTheme.spacing(3),
    color: kirkeTheme.palette.common.black,
    backgroundColor: kirkeTheme.palette.common.white,
  },
  appBarInner: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    maxWidth: "1180px",
  },
  topSectionWrapper: {
    backgroundColor: kirkeTheme.palette.background.default,
    paddingLeft: "5%",
    paddingRight: "5%",
  },
  topSection: {
    marginTop: kirkeTheme.spacing(18),
    paddingBottom: kirkeTheme.spacing(6),
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    maxWidth: "1180px",
    [kirkeTheme.breakpoints.down("sm")]: {
      paddingTop: kirkeTheme.spacing(15),
    },
  },
  headerTittel: {
    display: "flex",
    alignItems: "center",
    pl: 2,
  },
};

const RegistrerBrukerPage = () => {
  useDocumentTitle("Registrer");

  const [harRegistrert, setHarRegistrert] = useState<boolean>(false);

  const { data: sanityLogPage } = useFetchSanityData(
    loginsideQuery,
    "loginsideQuery"
  );
  const { data: sanityNyBruker } = useFetchSanityData(
    registrerBrukerQuery,
    "registrerNyBrukerQuery"
  );

  return (
    <Box sx={styles.container}>
      <AppBar sx={styles.appBar} elevation={0}>
        <Grid container sx={styles.appBarInner}>
          <Grid item>
            <KirkeLogo />
          </Grid>
          <Grid item sx={styles.headerTittel}>
            <Typography variant="h2">
            {sanityLogPage?.loginTittel || "Kirka vår - samlet plan for arbeidet i soknet"}
            </Typography>
          </Grid>
        </Grid>
      </AppBar>

      <Box sx={styles.topSectionWrapper}>
        <Grid sx={styles.topSection}>
          {harRegistrert ? (
            <TakkForRegistrering sanityData={sanityNyBruker} />
          ) : (
            <RegistrerBrukerForm
              setHarRegistrert={setHarRegistrert}
              sanityData={sanityNyBruker}
            />
          )}
        </Grid>
      </Box>
    </Box>
  );
};
export default RegistrerBrukerPage;
