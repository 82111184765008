import { useState } from "react";

import { Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";

import AktiviteterTable from "./AktiviteterData/AktiviteterTable";
import GronnkirkeTable from "./GronnkirkeData/GronnkirkeTable";
import PlanerTable from "./PlanerData/PlanerTable";
import SatsingerTable from "./SatsingerData/SatsingerTable";
import { alledataQuery } from "../../api/alledata";
import { useFetchSanityData } from "../../api/useFetchSanityData";
import Container from "../../components/layout/Container";
import SearchBar from "../../components/SearchBar/SearchBar";
import kirkeTheme from "../../utils/kirkeTheme";
import useDocumentTitle from "../../utils/useDocumentTitle";

const styles = {
  tabs: {
    minHeight: "40px",
    maxHeight: "40px",
    "& button": {
      backgroundColor: "#CED0D3",
      margin: "0 8px",
      borderRadius: "10px 10px 0px 0px",
      textTransform: "none",
      minHeight: "40px",
      maxHeight: "40px",
      width: "132px",
      padding: "0",
      color: "inherit",
    },
    "& button: active": {
      backgroundColor: kirkeTheme.palette.common.black,
    },
    "& button.Mui-selected": {
      backgroundColor: kirkeTheme.palette.common.black,
      color: "white",
    },
  },
};

const AlleDataPage = () => {
  const [tabValue, setTabValue] = useState(0);
  const [searchValue, setSearchValue] = useState<string | undefined>();
  const { data: sanityData } = useFetchSanityData(
    alledataQuery,
    "alleDataQuery"
  );

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  useDocumentTitle(sanityData?.alledataSideTitle || "Alle data for Kirka vår");

  return (
    <Container undermeny menyValg={false} alleData>
      <Box sx={{ paddingBottom: kirkeTheme.spacing(3) }}>
        <Typography variant="title">
          {`${sanityData?.alledataTittelLokal || "Alle data for Kirka vår"}`}
        </Typography>
        <Typography variant="body1">
          {sanityData?.alledataBeskLokal ||
            "Velg plan, eller velg år. Velg felt."}
        </Typography>
      </Box>
      <Box sx={{ marginBottom: kirkeTheme.spacing(4) }}>
        <SearchBar setSearchValue={setSearchValue} />
      </Box>
      <Box sx={{ padding: 0, borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="alle data tabs"
          TabIndicatorProps={{ hidden: true }}
          sx={styles.tabs}
        >
          <Tab label="Plan" />
          <Tab label="Satsinger" />
          <Tab label="Aktiviteter" />
          <Tab label="Grønn kirke" />
        </Tabs>
      </Box>
      {tabValue === 0 && (
        <PlanerTable
          searchValue={searchValue}
          ledetekster={sanityData?.ledetekster}
        />
      )}
      {tabValue === 1 && (
        <SatsingerTable
          searchValue={searchValue}
          ledetekster={sanityData?.ledetekster}
        />
      )}
      {tabValue === 2 && (
        <AktiviteterTable
          searchValue={searchValue}
          ledetekster={sanityData?.ledetekster}
        />
      )}
      {tabValue === 3 && (
        <GronnkirkeTable
          searchValue={searchValue}
          ledetekster={sanityData?.ledetekster}
        />
      )}
    </Container>
  );
};

export default AlleDataPage;
