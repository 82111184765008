import { groq } from "next-sanity";

export const loginsideQuery = groq`
*[_type == "loginside" && !(_id in path('drafts.**'))][0] {
    "loginPageTitle": coalesce(loginPageTitle[$malform], loginPageTitle.nb),
    "loginTittel": coalesce(loginTittel[$malform], loginTittel.nb),
    topSectionContent {
        "tittel": coalesce(tittel[$malform], tittel.nb),
        imagesWithText[] {
            "bildetittel": coalesce(bildetittel[$malform], bildetittel.nb),
            "bildebeskrivelse": coalesce(bildebeskrivelse[$malform], bildebeskrivelse.nb),
            bilde,
        },
        link {
            "lenketittel": coalesce(lenketittel[$malform], lenketittel.nb),
            href,
        },
    },
    loginSectionContent {
        "tittel": coalesce(tittel[$malform], tittel.nb),
        "subtittel": coalesce(subtittel[$malform], subtittel.nb),
        "tekst": coalesce(tekst[$malform], tekst.nb),
        "videoTittel": coalesce(videoTittel[$malform], videoTittel.nb),
        video,
    },
  }
`;

export default loginsideQuery;
