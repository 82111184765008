import { useEffect, useState } from "react";

import AnnouncementIcon from "@mui/icons-material/Announcement";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import RecyclingIcon from "@mui/icons-material/Recycling";
import { MenuItem, Box, Grid, Link, Menu, Typography } from "@mui/material";
import { useNavigate, Link as RouterLink } from "react-router-dom";

import { ledeteksterQuery } from "../../../api";
import { useFetchSanityData } from "../../../api/useFetchSanityData";
import kirkeTheme from "../../../utils/kirkeTheme";

const styles = {
  active: {
    paddingRight: kirkeTheme.spacing(3),
    paddingLeft: kirkeTheme.spacing(3),
    paddingBottom: kirkeTheme.spacing(0.5),
    paddingTop: kirkeTheme.spacing(0.5),
    display: "flex",
    displayPrint: "none",
    alignItems: "center",
    color: kirkeTheme.palette.primary.contrastText,
    backgroundColor: kirkeTheme.palette.primary.dark,
    textDecoration: "none",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    justifyContent: "center",
    fontWeight: "bold",
    [kirkeTheme.breakpoints.down("md")]: {
      backgroundColor: kirkeTheme.palette.background.default,
      color: kirkeTheme.palette.common.black,
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
  notActive: {
    paddingRight: kirkeTheme.spacing(3),
    paddingLeft: kirkeTheme.spacing(3),
    paddingBottom: kirkeTheme.spacing(0.5),
    paddingTop: kirkeTheme.spacing(0.5),
    display: "flex",
    displayPrint: "none",
    alignItems: "center",
    justifyContent: "center",
    color: kirkeTheme.palette.common.black,
    textDecoration: "none",
    fontWeight: "bold",
    "&:hover": {
      cursor: "pointer",
    },
  },
  menuGrid: {
    display: "flex",
    displayPrint: "none",
    justifyContent: "center",
    [kirkeTheme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  menuBox: {
    marginRight: "0.1rem",
    display: "flex",
    [kirkeTheme.breakpoints.down("lg")]: {
      display: "none",
    },
    [kirkeTheme.breakpoints.down("md")]: {
      display: "block",
      marginRight: "0.5rem",
    },
  },
  underMenuItem: {
    borderRadius: kirkeTheme.spacing(1),
    margin: kirkeTheme.spacing(1),
    minWidth: "260px",
  },
  menuItemText: {
    paddingLeft: kirkeTheme.spacing(2),
  },
  merBox: {
    [kirkeTheme.breakpoints.up("md")]: {
      marginLeft: `${kirkeTheme.spacing(15)} !important`,
    },
  },
};

const MenyValg = () => {
  const [currentActiveMenuItemId, setCurrentActiveMenuItemId] =
    useState<number>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpenDropDown = Boolean(anchorEl);

  const navigate = useNavigate();
  const merId = 20;

  const { data: ledeteksterData } = useFetchSanityData(
    ledeteksterQuery,
    "ledeteksterQuery"
  );

  useEffect(() => {
    menyvalgListe?.forEach((valg) => {
      if (window.location.pathname.startsWith(valg.link) && valg.id !== merId) {
        setCurrentActiveMenuItemId(valg.id);
      }
    });
    undermenyvalgListe?.forEach((valg) => {
      if (window.location.pathname.startsWith(valg.link)) {
        setCurrentActiveMenuItemId(merId);
      }
    });
  }, []);

  const menyvalgListe = [
    {
      name: ledeteksterData?.ledeteksterMeny?.hjemMenypunkt || "Hjem",
      id: 1,
      link: "/sokn",
      icon: <HomeOutlinedIcon />,
    },
    {
      name:
        ledeteksterData?.ledeteksterMeny?.veiledningMenypunkt ||
        "Kom i gang med Kirka vår",
      id: 2,
      link: "/veiledning",
      icon: <></>,
    },
    {
      name:
        ledeteksterData?.ledeteksterMeny?.menighetenITallMenypunkt ||
        "Soknet i tall",
      id: 3,
      link: "/soknetitall",
      icon: <></>,
    },
    {
      name:
        ledeteksterData?.ledeteksterMeny?.planMenypunkt ||
        "Hvem vi er og hva vi vil",
      id: 4,
      link: "/plan",
      icon: <></>,
    },
    {
      name:
        ledeteksterData?.ledeteksterMeny?.aktiviteterMenypunkt || "Aktiviteter",
      id: 5,
      link: "/aktivitet",
      icon: <></>,
    },
    {
      name: ledeteksterData?.ledeteksterMeny?.merMenypunkt || "Mer",
      id: merId,
      link: "",
      icon: <KeyboardArrowDownIcon />,
    },
  ];

  const undermenyvalgListe = [
    {
      name:
        ledeteksterData?.ledeteksterMeny?.gronnkirkeMenypunkt || "Grønn kirke",
      id: 21,
      link: "/gronnkirke",
      icon: <RecyclingIcon />,
    },
    {
      name: ledeteksterData?.ledeteksterMeny?.nyheterMenypunkt || "Siste nytt",
      id: 22,
      link: "/nyheter",
      icon: <AnnouncementIcon />,
    },
  ];

  const isActive = (menyItemId: number) =>
    menyItemId === currentActiveMenuItemId;

  return (
    <>
      {menyvalgListe.map((menyvalg) => (
        <Grid key={menyvalg.id} item sx={styles.menuGrid}>
          {menyvalg.id === 20 ? (
            <>
              <Box
                onClick={(event) => setAnchorEl(event.currentTarget)}
                sx={{
                  ...(isActive(menyvalg.id) ? styles.active : styles.notActive),
                  ...styles.merBox,
                }}
              >
                <Box sx={styles.menuBox}>{menyvalg.icon}</Box>
                <Box sx={{ padding: "4px" }}>{menyvalg.name}</Box>
              </Box>
              <Menu
                id="menu-mer"
                anchorEl={anchorEl}
                keepMounted
                open={isOpenDropDown}
                onClose={() => setAnchorEl(null)}
              >
                {undermenyvalgListe.map((item) => (
                  <MenuItem
                    key={item.id}
                    sx={styles.underMenuItem}
                    onClick={() => navigate(item.link)}
                  >
                    {item.icon}
                    <Typography sx={styles.menuItemText}>
                      {item.name}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </>
          ) : (
            <Link
              component={RouterLink}
              key={menyvalg.id}
              to={menyvalg.link}
              sx={isActive(menyvalg.id) ? styles.active : styles.notActive}
              data-testid="menu-option"
            >
              <Box sx={styles.menuBox}>{menyvalg.icon}</Box>
              <Box sx={{ padding: "4px" }}>{menyvalg.name}</Box>
            </Link>
          )}
        </Grid>
      ))}
    </>
  );
};

export default MenyValg;
