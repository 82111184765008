import { groq } from "next-sanity";

export const aktivitetertiltakQuery = groq`
*[_type == "aktivitetertiltak" && !(_id in path('drafts.**'))][0] {
    _id,
    "aktiviteterPageTitle": coalesce(aktiviteterPageTitle[$malform], aktiviteterPageTitle.nb),
    "tittelLokal": coalesce(tittelLocale[$malform], tittelLocale.nb),
    "beskrivelseLokal": coalesce(beskrivelseLocale[$malform], beskrivelseLocale.nb),
    evalueringSeksjon{
      "evalueringPageTitle": coalesce(evalueringPageTitle[$malform],evalueringPageTitle.nb),
      "tittel": coalesce(tittel[$malform],tittel.nb),
      "beskrivelse": coalesce(beskrivelse[$malform],beskrivelse.nb),
      "velgEvaluering": coalesce(velgEvaluering[$malform],velgEvaluering.nb),
      "finnesIkke1": coalesce(finnesIkke1[$malform],finnesIkke1.nb),
      "finnesIkke2": coalesce(finnesIkke2[$malform],finnesIkke2.nb),
      "registrerEvaluering": coalesce(registrerEvaluering[$malform],registrerEvaluering.nb),
      "oppdaterEvaluering": coalesce(oppdaterEvaluering[$malform],oppdaterEvaluering.nb), 
      "redigerEvalueringTekst": coalesce(redigerEvalueringTekst[$malform],redigerEvalueringTekst.nb), 
      "redigerAntallDeltakere": coalesce(redigerAntallDeltakere[$malform],redigerAntallDeltakere.nb), 
      "visEvalueringTilbake": coalesce(visEvalueringTilbake[$malform],visEvalueringTilbake.nb), 
      "visEvalueringTittel": coalesce(visEvalueringTittel[$malform],visEvalueringTittel.nb), 
      "visEvalueringAar": coalesce(visEvalueringAar[$malform],visEvalueringAar.nb), 
      "visAntallDeltakere": coalesce(visAntallDeltakere[$malform],visAntallDeltakere.nb),
      "visEvalueringTekst": coalesce(visEvalueringTekst[$malform],visEvalueringTekst.nb),
      "visEvalueringHentetFraTrosopplaring": coalesce(visEvalueringHentetFraTrosopplaring[$malform],visEvalueringHentetFraTrosopplaring.nb), 
      "visEvalueringSistEndret1": coalesce(visEvalueringSistEndret1[$malform],visEvalueringSistEndret1.nb),  
      "visEvalueringSistEndret2": coalesce(visEvalueringSistEndret2[$malform],visEvalueringSistEndret2.nb),  
      "visEvalueringTekstTom": coalesce(visEvalueringTekstTom[$malform],visEvalueringTekstTom.nb),  
      "popupRegistrert": coalesce(popupRegistrert[$malform],popupRegistrert.nb),  
      "popupOppdatert": coalesce(popupOppdatert[$malform],popupOppdatert.nb),  
      "popupSlettet": coalesce(popupSlettet[$malform],popupSlettet.nb),  
      "slettDialogBoxTittel": coalesce(slettDialogBoxTittel[$malform],slettDialogBoxTittel.nb),  
      "erDuSikkerSlett": coalesce(erDuSikkerSlett[$malform],erDuSikkerSlett.nb),  
      "EvalueringMaaEndresITrosopplaring": coalesce(EvalueringMaaEndresITrosopplaring[$malform], EvalueringMaaEndresITrosopplaring.nb),
    },
    leggHjelpRessurser, 
    "hjelpRessurser": hjelpRessurser {
      bilde,
      "tittelLokal": coalesce(tittelLocale[$malform], tittelLocale.nb),
      linker [] {
        href,
        "lenketittelLokal": coalesce(lenketittelLocale[$malform], lenketittelLocale.nb),
        blank,
      }
    },
    ledetekster {
      "leggTilAktivitetTekst": coalesce(leggTilAktivitet[$malform], leggTilAktivitet.nb),
      "registrerAktivitetTekst": coalesce(registrerAktivitet[$malform], registrerAktivitet.nb),
      "aktivitetFinnesIkke": coalesce(aktivitetFinnesIkke[$malform], aktivitetFinnesIkke.nb),
      "samarbeidVisAktivitetSide": coalesce(samarbeidVisAktivitetSide[$malform], samarbeidVisAktivitetSide.nb),
      "detteErEtSamarbeid": coalesce(detteErEtSamarbeid[$malform], detteErEtSamarbeid.nb),
      "navnPaTiltaketTekst": coalesce(navnPaTiltaket[$malform], navnPaTiltaket.nb),
      "redigereTrosopplaeringTekst": coalesce(redigereTrosopplaering[$malform], redigereTrosopplaering.nb),
      "aldersgruppeTekst": coalesce(aldersgruppe[$malform], aldersgruppe.nb),
      "aldersgrupperTekst": coalesce(aldersgrupper[$malform], aldersgrupper.nb),
      "alleAldersgrupperTekst": coalesce(alleAldersgrupper[$malform], alleAldersgrupper.nb),
      "velgAldersgrupperTekst": coalesce(velgAldersgrupper[$malform], velgAldersgrupper.nb),
      "kategoriTekst": coalesce(kategori[$malform], kategori.nb),
      "kategorierTekst": coalesce(kategorier[$malform], kategorier.nb),
      "alleKategorierTekst": coalesce(alleKategorier[$malform], alleKategorier.nb),
      "velgKategoriTekst": coalesce(velgKategori[$malform], velgKategori.nb),
      "trosopplaringTekst": coalesce(trosopplaring[$malform], trosopplaring.nb),
      "gudstjenesteTekst": coalesce(gudstjeneste[$malform], gudstjeneste.nb),
      "religionsdialogTekst": coalesce(religionsdialog[$malform], religionsdialog.nb),
      "musikkKulturTekst": coalesce(musikkKultur[$malform], musikkKultur.nb),
      "barnUngeTekst": coalesce(barnUnge[$malform], barnUnge.nb),
      "barnehageSkoleKirkeSamarbeidTekst": coalesce(barnehageSkoleKirkeSamarbeid[$malform], barnehageSkoleKirkeSamarbeid.nb),
      "okumenikkTekst": coalesce(okumenikk[$malform], okumenikk.nb),
      "misjonTekst": coalesce(misjon[$malform], misjon.nb),
      "diakoniTekst": coalesce(diakoni[$malform], diakoni.nb),
      "pilegrimTekst": coalesce(pilegrim[$malform], pilegrim.nb),
      "knyttTilSatsingTekst": coalesce(knyttTilSatsing[$malform], knyttTilSatsing.nb),
      "velgSatsingTekst": coalesce(velgSatsing[$malform], velgSatsing.nb),
      "oppstartsmanedTekst": coalesce(oppstartsmaned[$malform], oppstartsmaned.nb),
      "antallSamlingerTekst": coalesce(antallSamlinger[$malform], antallSamlinger.nb),
      "velgManedTekst": coalesce(velgManed[$malform], velgManed.nb),
      "settVarighetPaaAktivitetTekst": coalesce(settVarighetPaaAktivitet[$malform], settVarighetPaaAktivitetTekst.nb),
      "aktivitetRegistrertTekst": coalesce(aktivitetRegistrert[$malform], aktivitetRegistrert.nb),
      "oppdaterTekst":   coalesce(oppdater[$malform], oppdater.nb),
      "aktivitetOppdatertTekst": coalesce(aktivitetOppdatert[$malform], aktivitetOppdatert.nb),
      "sletteAktivitetTekst": coalesce(sletteAktivitet[$malform], sletteAktivitet.nb),
      "sikkerSletteAktivitetenTekst": coalesce(sikkerSletteAktiviteten[$malform], sikkerSletteAktiviteten.nb),
      "aktivitetenBleSlettetTekst": coalesce(aktivitetenBleSlettet[$malform], aktivitetenBleSlettet.nb),
      "vedleggTekst": coalesce(vedlegg[$malform], vedlegg.nb),
      "satsingTekst": coalesce(satsing[$malform], satsing.nb),
      "finnerIngenAktiviteterTekst": coalesce(finnerIngenAktiviteter[$malform], finnerIngenAktiviteter.nb),
      "finnerIkkeSatsingTekst": coalesce(finnerIkkeSatsing[$malform], finnerIkkeSatsing.nb),
      "harIkkeTilgangTekst": coalesce(harIkkeTilgang[$malform], harIkkeTilgang.nb),
      "opprettSatsingLinkTekst": coalesce(opprettSatsingLink[$malform], opprettSatsingLink.nb),
      "ansvarligTekst": coalesce(ansvarlig[$malform], ansvarlig.nb),
      "dataErImportertTekst": coalesce(dataErImportert[$malform], dataErImportert.nb),
      "varighetTekst": coalesce(varighet[$malform], varighet.nb),
      "opprettSamarbeid": coalesce(opprettSamarbeid[$malform], opprettSamarbeid.nb),
      "redigerAlderOgKategori": coalesce(redigerAlderOgKategori[$malform], redigerAlderOgKategori.nb),
      "redigerAndreFelter": coalesce(redigerAndreFelter[$malform], redigerAndreFelter.nb),
      "samarbeidCheckTekst": coalesce(samarbeidCheck[$malform], samarbeidCheck.nb),
      "aktivitetenErSamarbeid": coalesce(aktivitetenErSamarbeid[$malform], aktivitetenErSamarbeid.nb),
      "aktivitetenErSamarbeidMellom": coalesce(aktivitetenErSamarbeidMellom[$malform], aktivitetenErSamarbeidMellom.nb),
      "aktivitetOpprettetAv": coalesce(aktivitetOpprettetAv[$malform], aktivitetOpprettetAv.nb),
      "kanIkkeAvslutteSamarbeid": coalesce(kanIkkeAvslutteSamarbeid[$malform], kanIkkeAvslutteSamarbeid.nb),
      "enesteSoknIProsti": coalesce(enesteSoknIProsti[$malform], enesteSoknIProsti.nb),
      "trosopplaringKanIkkeFjernes": coalesce(trosopplaringKanIkkeFjernes[$malform], trosopplaringKanIkkeFjernes.nb),
      "alleredeInngattSamarbeid": coalesce(alleredeInngattSamarbeid[$malform], alleredeInngattSamarbeid.nb),
      "svarIkkeRegistrert": coalesce(svarIkkeRegistrert[$malform], svarIkkeRegistrert.nb),
      "henteAktivitetError": coalesce(henteAktivitetError[$malform], henteAktivitetError.nb),
      "samarbeidGodkjentTekst": coalesce(samarbeidGodkjent[$malform], samarbeidGodkjent.nb),
      "samarbeidAvslattTekst": coalesce(samarbeidAvslatt[$malform], samarbeidAvslatt.nb),
      "samarbeidForesporsel": coalesce(samarbeidForesporsel[$malform], samarbeidForesporsel.nb),
      "avslaSamarbeid": coalesce(avslaSamarbeid[$malform], avslaSamarbeid.nb),
      "godtaSamarbeid": coalesce(godtaSamarbeid[$malform], godtaSamarbeid.nb),
      "forespurtSamarbeid": coalesce(forespurtSamarbeid[$malform], forespurtSamarbeid.nb),
      "inngaSamarbeid": coalesce(inngaSamarbeid[$malform], inngaSamarbeid.nb),
      "aktivitetIkkeRegistrert": coalesce(aktivitetIkkeRegistrert[$malform], aktivitetIkkeRegistrert.nb),
      "planKunneIkkeHentes": coalesce(planKunneIkkeHentes[$malform], planKunneIkkeHentes.nb),
      "aktivitetMaHaTittel": coalesce(aktivitetMaHaTittel[$malform], aktivitetMaHaTittel.nb),
      "aktivitetKunneIkkeOppdateres": coalesce(aktivitetKunneIkkeOppdateres[$malform], aktivitetKunneIkkeOppdateres.nb),
      "aktivitetenKunneIkkeSlettes": coalesce(aktivitetenKunneIkkeSlettes[$malform], aktivitetenKunneIkkeSlettes.nb),
      "typeTiltak": coalesce(typeTiltak[$malform], typeTiltak.nb),
      "typeTiltak2": coalesce(typeTiltak2[$malform], typeTiltak2.nb),
      "typeTiltakUtvidet": coalesce(typeTiltakUtvidet[$malform], typeTiltakUtvidet.nb),
      "importertTekst": coalesce(importertTekst[$malform], importertTekst.nb),
      "samarbeidsoknTekst": coalesce(samarbeidsoknTekst[$malform], samarbeidsoknTekst.nb),
      "trosopplaringstypeTekst": coalesce(trosopplaringstypeTekst[$malform], trosopplaringstypeTekst.nb),
      "visAarshjul": coalesce(visAarshjul[$malform], visAarshjul.nb),
      "maaned": coalesce(maaned[$malform], maaned.nb),
      "lastNedUtenEvaluering": coalesce(lastNedUtenEvaluering[$malform], lastNedUtenEvaluering.nb),
      "lastNedMedEvaluering": coalesce(lastNedMedEvaluering[$malform], lastNedMedEvaluering.nb),
      "lastNedTabell": coalesce(lastNedTabell[$malform], lastNedTabell.nb),
      "lastNedAarshjul": coalesce(lastNedAarshjul[$malform], lastNedAarshjul.nb),
    }
  }
`;

export default aktivitetertiltakQuery;
