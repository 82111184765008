import React from "react";

import ErrorIcon from "@mui/icons-material/Error";
import { Box, Container, Typography } from "@mui/material";

interface Props {
  feilmelding: string;
}

export const DialogBoxAlert: React.FC<Props> = ({ feilmelding }) => {
  const styles = {
    root: {
      display: "flex",
      margin: "auto",
      paddingRight: "1.5rem",
      paddingLeft: "1.5rem",
      paddingTop: "1rem",
      paddingBottom: "1rem",
    },
    content: {
      paddingRight: "1.5rem",
      paddingLeft: "1.5rem",
      display: "flex",
    },
    icon: {
      textAlign: "center",
      marginRight: 8,
    },
  };
  return (
    <Box sx={styles.root}>
      <Container sx={styles.content}>
        <ErrorIcon sx={styles.icon} color="error" />
        <Typography variant="body1">{feilmelding}</Typography>
      </Container>
    </Box>
  );
};

export default DialogBoxAlert;
