import {
  Checkbox,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
} from "@mui/material";

import { AktivitetColumnIdType } from "./AktiviteterData/AktiviteterTable";
import { GronnkirkeColumnIdType } from "./GronnkirkeData/GronnkirkeTable";
import { PlanColumnIdType } from "./PlanerData/PlanerTable";
import { SatsingColumnIdType } from "./SatsingerData/SatsingerTable";
import kirkeTheme from "../../utils/kirkeTheme";
import { AlleDataLedetekster } from "../../utils/types";

const styles = {
  columnselect: {
    backgroundColor: kirkeTheme.palette.common.white,
    height: "40px",
    width: "266px",
    maxWidth: "100%",
  },
  disabledMenuItem: {
    backgroundColor: "rgba(196, 41, 29, 0.08)",
  },
};

interface Props {
  columns:
    | AktivitetColumnIdType[]
    | SatsingColumnIdType[]
    | GronnkirkeColumnIdType[]
    | PlanColumnIdType[];
  disabledColumns:
    | AktivitetColumnIdType[]
    | SatsingColumnIdType[]
    | GronnkirkeColumnIdType[]
    | PlanColumnIdType[];
  showColumns:
    | AktivitetColumnIdType[]
    | SatsingColumnIdType[]
    | GronnkirkeColumnIdType[]
    | PlanColumnIdType[];
  mapColumnToText: (column: string) => string | undefined;
  showSelectColumns: boolean;
  setShowSelectColumns: (showSelectColumns: boolean) => void;
  handleColumnChange: (event: SelectChangeEvent<string | string[]>) => void;
  ledetekster?: AlleDataLedetekster;
}
const DataColumns = ({
  columns,
  disabledColumns,
  showColumns,
  mapColumnToText,
  showSelectColumns,
  setShowSelectColumns,
  handleColumnChange,
  ledetekster,
}: Props) => {
  return (
    <Select
      multiple
      open={showSelectColumns}
      onOpen={() => setShowSelectColumns(true)}
      onClose={() => setShowSelectColumns(false)}
      renderValue={() => "Legg til felt"}
      value={showColumns}
      displayEmpty
      onChange={handleColumnChange}
      sx={styles.columnselect}
    >
      {columns?.map((column) =>
        !disabledColumns.some((x) => x === column) ? (
          <MenuItem key={column} value={column}>
            <ListItemIcon>
              <Checkbox checked={showColumns.some((x) => x === column)} />
            </ListItemIcon>
            <ListItemText primary={mapColumnToText(column)} />
          </MenuItem>
        ) : (
          <Tooltip
            title={
              ledetekster?.feltKanIkkeFjernes || "Dette feltet kan ikke fjernes"
            }
            arrow={true}
            placement="right"
          >
            <div>
              <MenuItem
                key={column}
                value={column}
                disabled
                sx={styles.disabledMenuItem}
              >
                <ListItemIcon>
                  <Checkbox checked={showColumns.some((x) => x === column)} />
                </ListItemIcon>
                <ListItemText primary={mapColumnToText(column)} />
              </MenuItem>
            </div>
          </Tooltip>
        )
      )}
    </Select>
  );
};
export default DataColumns;
