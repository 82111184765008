import { MutableRefObject, useRef, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import {
  Box,
  Grid,
  InputAdornment,
  List,
  ListItem,
  OutlinedInput,
  Checkbox,
  FormGroup,
  FormControlLabel,
  ListItemButton,
} from "@mui/material";

import kirkeTheme from "../../utils/kirkeTheme";
import { useOnClickOutside } from "../../utils/onClickOutside";
import * as Types from "../../utils/types";

const searchWidth = "512px";

const styles = {
  wrapper: {
    width: searchWidth,
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
    [kirkeTheme.breakpoints.down("md")]: {
      width: "90vw",
    },
  },
  textField: {
    width: searchWidth,
    background: kirkeTheme.palette.common.white,
    [kirkeTheme.breakpoints.down("md")]: {
      width: "90vw",
    },
  },
  listContainer: {
    position: "relative",
    width: "100%",
    zIndex: "1",
  },
  closeIconBox: {
    height: kirkeTheme.spacing(2),
    zIndex: 10,
    [kirkeTheme.breakpoints.down("md")]: {
      width: "100vw",
    },
  },
  list: {
    paddingTop: kirkeTheme.spacing(3),
    position: "absolute",
    maxHeight: "max(400px,30vh)",
    width: "100%",
    background: kirkeTheme.palette.common.white,
    overflowY: "auto",
    overscrollBehavior: "contain",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  listItem: {
    width: "100%",
    cursor: "pointer",
    "&:hover": {
      background: kirkeTheme.palette.secondary.main,
    },
  },
  icon: {
    cursor: "pointer",
  },
};

interface Props {
  filteredOptions: Types.AlleSoknType[] | undefined;
  handleOnChange: (changeEvent: string) => void;
  value: string;
  setSoknId: React.Dispatch<React.SetStateAction<string>>;
  ingenTreff: boolean;
  defaultCheckedBox: boolean;
}

const SearchInput = ({
  filteredOptions,
  handleOnChange,
  value,
  setSoknId,
  ingenTreff,
  defaultCheckedBox = false,
}: Props) => {
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [showBispedomme, setShowBispedomme] = useState(defaultCheckedBox);
  const onChange = (e: any) => {
    handleOnChange(e.target.value);
    setShowOptions(true);
  };

  const ref = useRef() as MutableRefObject<HTMLDivElement>;
  useOnClickOutside(ref, () => setShowOptions(false));

  return (
    <Grid
      ref={ref}
      container
      item
      direction="row"
      justifyContent="center"
      alignItems="center"
      marginLeft="0 !important "
      sx={styles.wrapper}
    >
      <Grid item>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox defaultChecked={defaultCheckedBox} />}
            label="Vis bispedømme i søkeresultat"
            onChange={() => setShowBispedomme(!showBispedomme)}
          />
        </FormGroup>
      </Grid>
      <Grid item>
        <OutlinedInput
          placeholder="Søk"
          sx={styles.textField}
          inputProps={{ "aria-label": "Søk", "data-testid": "velg-sokn-input" }}
          type="text"
          onChange={(e) => onChange(e)}
          value={value}
          autoComplete="off"
          startAdornment={
            <InputAdornment position="start">
              <SearchOutlinedIcon
                sx={styles.icon}
                onClick={() => setShowOptions(true)}
              />
            </InputAdornment>
          }
        />
      </Grid>
      {showOptions && (
        <Grid container item sx={styles.listContainer}>
          <Grid
            sx={styles.closeIconBox}
            container
            display="flex"
            justifyContent="flex-end"
            p={1}
          >
            <CloseIcon
              fontSize="small"
              color="disabled"
              sx={styles.icon}
              onClick={() => setShowOptions(false)}
            />
          </Grid>
          <Box sx={styles.list}>
            <List>
              {ingenTreff && <ListItem>Ingen treff</ListItem>}
              {!filteredOptions && !ingenTreff && (
                <ListItem>Begynn å skrive for å søke etter sokn</ListItem>
              )}
              {filteredOptions &&
                filteredOptions.map((sokn: Types.AlleSoknType) => (
                  <ListItemButton
                    key={sokn.id}
                    sx={styles.listItem}
                    onClick={() => setSoknId(sokn.id)}
                  >
                    <Grid
                      container
                      display="flex"
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Grid item>{sokn.navn}</Grid>
                      <Grid item>{showBispedomme && sokn.bispedommeNavn}</Grid>
                    </Grid>
                  </ListItemButton>
                ))}
            </List>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
export default SearchInput;
