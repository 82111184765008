import { useContext, useEffect, useState } from "react";

import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import {
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Typography,
  Table,
  Button,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import { AlleTallPlanerColumn, hasOwn } from "./PlanerTallTable";
import UnderliggendePlanRow from "./UnderliggendePlanRow";
import {
  AccessGroupContext,
  AccessGroupContextType,
  setGroup,
} from "../../../components/contexts/AccessGroupContext";
import {
  SoknContext,
  SoknContextType,
} from "../../../components/contexts/SoknContext";
import { formatertDato } from "../../../utils/datoModifikasjoner";
import kirkeTheme from "../../../utils/kirkeTheme";
import { AlleTallPlanSokn, SoknType } from "../../../utils/types";
import { useGetSokn } from "../../AlleSoknPage/api";

interface Props {
  sokn: AlleTallPlanSokn;
  columns: readonly AlleTallPlanerColumn[];
}

interface CustomizedState {
  goBack: boolean;
}
const UnderliggendeSoknRow = ({ sokn, columns }: Props) => {
  const [open, setOpen] = useState<boolean>();
  const [selectedSoknId, setSelectedSoknId] = useState<string>("");

  const navigate = useNavigate();
  const location = useLocation();
  const { setAccessGroup } = useContext(
    AccessGroupContext
  ) as AccessGroupContextType;
  const { setSokn, setPlaner, setValgtPlan } = useContext(
    SoknContext
  ) as SoknContextType;
  const { data: selectedSokn } = useGetSokn(selectedSoknId);

  useEffect(() => {
    if (selectedSokn) {
      setSokn && setSokn(selectedSokn as SoknType);
      setPlaner && setPlaner([]);
      setValgtPlan && setValgtPlan(undefined);
      setGroup(selectedSokn, setAccessGroup);
      if (location.state) {
        const { goBack } = location.state as CustomizedState;
        if (goBack) {
          navigate(-1);
        } else {
          navigate("/sokn");
        }
      } else {
        navigate("/sokn");
      }
    }
  }, [selectedSokn]);

  const mappedData = columns.map((column) => {
    if (sokn && hasOwn(sokn, column.id)) {
      return sokn[column.id];
    }
    return null;
  });

  return (
    <Table>
      <TableRow>
        {Object.entries(mappedData).map(([key, value]) => {
          if (value == null) {
            return <TableCell key={key}></TableCell>;
          } else {
            if (key === "0") {
              return (
                <TableCell key={key}>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                    sx={{ marginLeft: kirkeTheme.spacing(6) }}
                  >
                    {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                  </IconButton>
                  <Button onClick={() => setSelectedSoknId(sokn.id)}>
                    <Typography>{sokn.navn}</Typography>
                  </Button>
                </TableCell>
              );
            } else {
              if (parseInt(key) == columns.length - 1) {
                return (
                  <TableCell
                    key={key}
                    sx={{
                      width: columns[parseInt(key)].minWidth ?? 0,
                    }}
                  >
                    {formatertDato(value as string, "long")}
                  </TableCell>
                );
              } else {
                return (
                  <TableCell
                    key={key}
                    sx={{
                      width: columns[parseInt(key)].minWidth ?? 0,
                    }}
                  >
                    {value}
                  </TableCell>
                );
              }
            }
          }
        })}
      </TableRow>
      <TableRow>
        <TableCell colSpan={columns.length} sx={{ padding: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {sokn.planer?.map((plan) => (
              <UnderliggendePlanRow
                key={plan.navn}
                plan={plan}
                columns={columns}
              />
            ))}
          </Collapse>
        </TableCell>
      </TableRow>
    </Table>
  );
};

export default UnderliggendeSoknRow;
