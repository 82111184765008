import React, { createContext } from "react";

import { SoknType, PlanType } from "../../utils/types";
import useLocalStorage from "../../utils/useLocalStorage";

export type SoknContextType = {
  setSokn: (sokn: SoknType | undefined) => void;
  sokn: SoknType;
  setOneSoknAccess: (ettSokn: boolean) => void;
  oneSoknAccess: boolean;
  setZeroSoknAccess: (nullSokn: boolean) => void;
  zeroSoknAccess: boolean;
  handleSetValgtPlan: (event: any) => void;
  valgtPlanId: string;
  valgtPlan: PlanType;
  setValgtPlan: (plan: PlanType | undefined) => void;
  planer: PlanType[];
  setPlaner: (plan: PlanType[]) => void;
  oppdaterPlan: (plan: PlanType) => void;
};

export const SoknContext = createContext<SoknContextType | any>({});

interface Props {
  children: React.ReactNode;
}

export const SoknContextProvider: React.FC<Props> = ({ children }) => {
  const [sokn, setSokn] = useLocalStorage("sokn");
  const [oneSoknAccess, setOneSoknAccess] = useLocalStorage("oneSoknAccess");
  const [zeroSoknAccess, setZeroSoknAccess] = useLocalStorage("zeroSoknAccess");
  const [valgtPlanId, setValgtPlanId] = useLocalStorage("planId");
  const [valgtPlan, setValgtPlan] = useLocalStorage("valgtPlan");
  const [planer, setPlaner] = useLocalStorage("planer");

  const handleSetValgtPlan = (planId: string) => {
    const plan = planer.find((p: PlanType) => p.id === planId);
    setValgtPlan(plan);
    setValgtPlanId(planId);
  };

  const oppdaterPlan = (nyPlan: PlanType) => {
    const nyePlaner = planer.map((plan: PlanType) =>
      plan.id === nyPlan.id ? nyPlan : plan
    );
    setPlaner(nyePlaner);
  };

  return (
    <SoknContext.Provider
      value={{
        setSokn,
        sokn,
        setOneSoknAccess,
        oneSoknAccess,
        setZeroSoknAccess,
        zeroSoknAccess,
        valgtPlanId,
        valgtPlan,
        handleSetValgtPlan,
        setValgtPlan,
        planer,
        setPlaner,
        oppdaterPlan,
      }}
    >
      {children}
    </SoknContext.Provider>
  );
};
