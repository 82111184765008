const nullstillContext = (nullstillContextString: string) => {
  switch (nullstillContextString) {
    case "PlanAlleData":
      localStorage.removeItem("showPlanColumns");
      break;
    case "SatsingerAlleData":
      localStorage.removeItem("showSatsingColumns");
      break;
    case "AktiviteterAlleData":
      localStorage.removeItem("showAktivitetColumns");
      break;
    case "GronnkirkeAlleData":
      localStorage.removeItem("showGronnkirkeColumns");
      break;
    case "Aktivitetertabell":
      localStorage.removeItem("showAktivitettabellColumns");
      break;
    default:
      break;
  }
  localStorage.removeItem("antallRaderTabell");
  return;
};

export default nullstillContext;
