import { groq } from "next-sanity";

export const planQuery = groq`
*[_type == "plan" && !(_id in path('drafts.**'))][0] {
  A_A_planlegging {
      "tittelLokal":  coalesce(tittelLocale[$malform], tittelLocale.nb),
      "beskrivelseLokal": beskrivelseLocale {
        "tittel": coalesce(tittel[$malform], tittel.nb),
        "beskrivelse": coalesce(beskrivelse[$malform], beskrivelse.nb),
      },
      "menyNavn": coalesce(menyNavnLocale[$malform], menyNavnLocale.nb),
      leggHjelpRessurser,
      "hjelpRessurser": hjelpRessurser {
        bilde,
        "tittelLokal": coalesce(tittelLocale[$malform], tittelLocale.nb),
        linker [] {
          href,
          "lenketittelLokal": coalesce(lenketittelLocale[$malform], lenketittelLocale.nb),
          blank,
        }
      }
    },
    A_menighetOgLokalsamfunn {
      "tittelLokal":  coalesce(tittelLocale[$malform], tittelLocale.nb),
      "beskrivelseLokal": beskrivelseLocale {
        "tittel": coalesce(tittel[$malform], tittel.nb),
        "beskrivelse": coalesce(beskrivelse[$malform], beskrivelse.nb),
      },
      "menyNavn": coalesce(menyNavnLocale[$malform], menyNavnLocale.nb),
      leggHjelpRessurser,
      "hjelpRessurser": hjelpRessurser {
        bilde,
        "tittelLokal": coalesce(tittelLocale[$malform], tittelLocale.nb),
        linker [] {
          href,
          "lenketittelLokal": coalesce(lenketittelLocale[$malform], lenketittelLocale.nb),
          blank,
        }
      }
    },
    B_menighetensRessurser {
      "tittelLokal":  coalesce(tittelLocale[$malform], tittelLocale.nb),
      "beskrivelseLokal": beskrivelseLocale {
        "tittel": coalesce(tittel[$malform], tittel.nb),
        "beskrivelse": coalesce(beskrivelse[$malform], beskrivelse.nb),
      },
      "menyNavn": coalesce(menyNavnLocale[$malform], menyNavnLocale.nb),
      leggHjelpRessurser,
      "hjelpRessurser": hjelpRessurser {
        bilde,
        "tittelLokal": coalesce(tittelLocale[$malform], tittelLocale.nb),
        linker [] {
          href,
          "lenketittelLokal": coalesce(lenketittelLocale[$malform], lenketittelLocale.nb),
            blank,
        }
      }
    },
    C_utfordringer {
      "tittelLokal":  coalesce(tittelLocale[$malform], tittelLocale.nb),
      "beskrivelseLokal": beskrivelseLocale {
        "tittel": coalesce(tittel[$malform], tittel.nb),
        "beskrivelse": coalesce(beskrivelse[$malform], beskrivelse.nb),
      },
      "menyNavn": coalesce(menyNavnLocale[$malform], menyNavnLocale.nb),
      leggHjelpRessurser,
      "hjelpRessurser": hjelpRessurser {
        bilde,
        "tittelLokal": coalesce(tittelLocale[$malform], tittelLocale.nb),
        linker [] {
          href,
          "lenketittelLokal": coalesce(lenketittelLocale[$malform], lenketittelLocale.nb),
          blank,
        }
      }
    },
    D_satsinger { 
      "tittelLokal":  coalesce(tittelLocale[$malform], tittelLocale.nb),
      "beskrivelseLokal": beskrivelseLocale {
        "tittel": coalesce(tittel[$malform], tittel.nb),
        "beskrivelse": coalesce(beskrivelse[$malform], beskrivelse.nb),
      },
      "menyNavn": coalesce(menyNavnLocale[$malform], menyNavnLocale.nb),
      leggHjelpRessurser,
      "hjelpRessurser": hjelpRessurser {
        bilde,
        "tittelLokal": coalesce(tittelLocale[$malform], tittelLocale.nb),
        linker [] {
          href,
          "lenketittelLokal": coalesce(lenketittelLocale[$malform], lenketittelLocale.nb),
          blank,
        }
      }
    },
    E_uttrekkRapporter {
      "tittelLokal":  coalesce(tittelLocale[$malform], tittelLocale.nb),
      "beskrivelseLokal": beskrivelseLocale {
        "tittel":  coalesce(tittel[$malform], tittel.nb),
        "beskrivelse":  coalesce(beskrivelse[$malform], beskrivelse.nb),
      },
      "menyNavn": coalesce(menyNavnLocale[$malform], menyNavnLocale.nb),
      leggHjelpRessurser,
      "hjelpRessurser": hjelpRessurser {
        bilde,
        "tittelLokal": coalesce(tittelLocale[$malform], tittelLocale.nb),
        linker [] {
          href,
          "lenketittelLokal": coalesce(lenketittelLocale[$malform], lenketittelLocale.nb),
          blank,
        }
      }
    }
  }
`;

export default planQuery;
