import { groq } from "next-sanity";

export const adminQuery = groq`
*[_type == "admin" && !(_id in path('drafts.**'))][0] {
        "administrasjonsSideTitle": coalesce(administrasjonsSideTitle[$malform], administrasjonsSideTitle.nb),
        "adminTittelLokal": coalesce(adminTittelLocale[$malform], adminTittelLocale.nb),
        "adminBeskLokal": coalesce(adminBeskLocale[$malform], adminBeskLocale.nb),
        "velgMalform" : coalesce(velgMalform[$malform], velgMalform.nb),
        "malformNynorsk" : coalesce(malformNynorsk[$malform], malformNynorsk.nb),
        "malformBokmal" : coalesce(malformBokmal[$malform], malformBokmal.nb),
        ledetekster{
                "adminMenighet": coalesce(adminMenighet[$malform], adminMenighet.nb),
                "planperiodeOverskrift": coalesce(planperiodeOverskrift[$malform], planperiodeOverskrift.nb),
                "planperiodeBeskrivelse": coalesce(planperiodeBeskrivelse[$malform], planperiodeBeskrivelse.nb),
                "planperiodeKnapp": coalesce(planperiodeKnapp[$malform], planperiodeKnapp.nb),
                "opprettNyPlanperiodeOverskrift": coalesce(opprettNyPlanperiodeOverskrift[$malform], opprettNyPlanperiodeOverskrift.nb),
                "opprettNyPlanperiodeBeskrivelse": coalesce(opprettNyPlanperiodeBeskrivelse[$malform], opprettNyPlanperiodeBeskrivelse.nb),
                "redigerPlanperiodeOverskrift": coalesce(redigerPlanperiodeOverskrift[$malform], redigerPlanperiodeOverskrift.nb),
                "redigerPlanperiodeBeskrivelse": coalesce(redigerPlanperiodeBeskrivelse[$malform], redigerPlanperiodeBeskrivelse.nb),
                "planperiodeFor": coalesce(planperiodeFor[$malform], planperiodeFor.nb),
                "finnesIngenPlanperiode": coalesce(finnesIngenPlanperiode[$malform], finnesIngenPlanperiode.nb),
                "finnesIngenPlanaar": coalesce(finnesIngenPlanaar[$malform], finnesIngenPlanaar.nb),
                "opprettNyPlanperiodeSuksess": coalesce(opprettNyPlanperiodeSuksess[$malform], opprettNyPlanperiodeSuksess.nb),
                "oppdaterNyPlanperiodeSuksess": coalesce(oppdaterNyPlanperiodeSuksess[$malform], oppdaterNyPlanperiodeSuksess.nb),
                "slettPlanperiodeOverskrift": coalesce(slettPlanperiodeOverskrift[$malform], slettPlanperiodeOverskrift.nb),
                "slettPlanperiodeBeskrivelse": coalesce(slettPlanperiodeBeskrivelse[$malform], slettPlanperiodeBeskrivelse.nb),
                "slettPlanperiodeSuksess": coalesce(slettPlanperiodeSuksess[$malform], slettPlanperiodeSuksess.nb),
                "brukereMedTilgangOverskrift": coalesce(brukereMedTilgangOverskrift[$malform], brukereMedTilgangOverskrift.nb),
                "brukereMedTilgangUndertekst": coalesce(brukereMedTilgangUndertekst[$malform], brukereMedTilgangUndertekst.nb),
                "ikkeTilgangTilAdminsider": coalesce(ikkeTilgangTilAdminsider[$malform], ikkeTilgangTilAdminsider.nb),
                "brukertabellNavn": coalesce(brukertabellNavn[$malform], brukertabellNavn.nb),
                "brukertabellJobbtittel": coalesce(brukertabellJobbtittel[$malform], brukertabellJobbtittel.nb),
                "brukertabellTelefon": coalesce(brukertabellTelefon[$malform], brukertabellTelefon.nb),
                "brukertabellEpost": coalesce(brukertabellEpost[$malform], brukertabellEpost.nb),
                "brukertabellRolle": coalesce(brukertabellRolle[$malform], brukertabellRolle.nb),
                "endreVisjontekst": coalesce(endreVisjontekst[$malform], endreVisjontekst.nb),
                "oppdaterVisjontekst": coalesce(oppdaterVisjontekst[$malform], oppdaterVisjontekst.nb),
                "lastOppBilde": coalesce(lastOppBilde[$malform], lastOppBilde.nb),
                "lastOppBildeBeskrivelse": coalesce(lastOppBildeBeskrivelse[$malform], lastOppBildeBeskrivelse.nb),
                "velgBildeButtonTekst": coalesce(velgBildeButtonTekst[$malform], velgBildeButtonTekst.nb),
                "lastOppBildeButtonTekst": coalesce(lastOppBildeButtonTekst[$malform], lastOppBildeButtonTekst.nb),
                "slettBildeButtonTekst": coalesce(slettBildeButtonTekst[$malform], slettBildeButtonTekst.nb),
        }
   }
`;

export default adminQuery;
