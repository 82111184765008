import { Buffer } from "buffer";

function decodeBase64Fields(obj: any, fieldNames: string[]) {
  if (typeof obj === "string") {
    return Buffer.from(obj, "base64").toString("utf16le");
  }
  let newObj = { ...obj };
  for (const fieldName of fieldNames) {
    const value = newObj[fieldName];
    if (!value || typeof value !== "string") {
      continue;
    }
    const decodedValue = Buffer.from(value, "base64").toString("utf16le");
    newObj = {
      ...newObj,
      [fieldName]: decodedValue,
    };
  }
  return newObj;
}
export default decodeBase64Fields;
