import { lazy, Suspense } from "react";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import Box from "@mui/material/Box";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import AlleDataPage from "../../pages/AlleDataPage/AlleDataPage";
import AlleTallPage from "../../pages/AlleTallPage/AlleTallPage";
import LoginPage from "../../pages/LoginPage/LoginPage";
import NotFoundPage from "../../pages/NotFoundPage";
import NyheterSide from "../../pages/NyheterSide/NyheterSide";
import RegistrerBrukerPage from "../../pages/RegistrerBrukerPage/RegistrerBrukerPage";
import ScrollToTop from "../../utils/ScrollToTop";
import { LoadingSpinner } from "../common/LoadingSpinner";

const AlleSoknPage = lazy(
  () => import("../../pages/AlleSoknPage/AlleSoknPage")
);
const AdministrasjonsSide = lazy(
  () => import("../../pages/AdministrasjonsSide/AdministrasjonsSide")
);
const SoknPage = lazy(() => import("../../pages/SoknPage/SoknPage"));

const SoknetITallPage = lazy(
  () => import("../../pages/SoknetITallPage/SoknetITallPage")
);
const PlanPage = lazy(() => import("../../pages/PlanPage/PlanPage"));
const VeiledningForside = lazy(
  () => import("../../pages/VeiledningPage/VeiledningForside")
);
const AktiviteterPage = lazy(
  () => import("../../pages/AktiviteterPage/AktiviteterPage")
);
const EnkelAktivitetSide = lazy(
  () => import("../../pages/AktiviteterPage/EnkelAktivitetSide")
);
const EnkelEvalueringSide = lazy(
  () =>
    import(
      "../../pages/AktiviteterPage/EvalueringSide/VisEvaluering/EnkelEvalueringSide"
    )
);
const RedigerAktivitetSide = lazy(
  () =>
    import(
      "../../pages/AktiviteterPage/RedigerAktivitetSide/RedigerAktivitetSide"
    )
);
const KapitlerPage = lazy(
  () => import("../../pages/VeiledningPage/KapitlerPage")
);
const GronnkirkePage = lazy(
  () => import("../../pages/GronnkirkePage/GronnkirkePage")
);

function RoutesComponent() {
  return (
    <Suspense
      fallback={
        <Box
          sx={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <LoadingSpinner />
        </Box>
      }
    >
      <BrowserRouter>
        <ScrollToTop />
        <AuthenticatedTemplate>
          <Routes>
            <Route path="/" element={<AlleSoknPage />} />
            <Route path="/administrasjon" element={<AdministrasjonsSide />} />
            <Route path="/data" element={<AlleDataPage />} />
            <Route path="/tall" element={<AlleTallPage />} />
            <Route path="/sokn" element={<SoknPage />} />
            <Route path="/plan" element={<PlanPage />} />
            <Route path="/aktivitet" element={<AktiviteterPage />} />
            <Route
              path="/aktivitet/registrer"
              element={<RedigerAktivitetSide />}
            />
            <Route
              path="/aktivitet/:aktivitetId"
              element={<EnkelAktivitetSide />}
            />
            <Route path="/soknetitall" element={<SoknetITallPage />} />
            <Route path="/veiledning" element={<VeiledningForside />} />
            <Route path="/veiledning/kapitler" element={<KapitlerPage />} />
            <Route
              path="/aktivitet/:aktivitetId/evaluering/:aar"
              element={<EnkelEvalueringSide />}
            />
            <Route path="/gronnkirke" element={<GronnkirkePage />} />
            <Route path="/nyheter" element={<NyheterSide />} />
            <Route
              path="/studio/:path*'"
              element={<Navigate replace to="/studio/index.html" />}
            />
            <Route path="/Registrer" element={<RegistrerBrukerPage />} />
            <Route path="/*" element={<NotFoundPage />} />
          </Routes>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/Registrer" element={<RegistrerBrukerPage />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </UnauthenticatedTemplate>
      </BrowserRouter>
    </Suspense>
  );
}

export default RoutesComponent;
