import { useQuery } from "@tanstack/react-query";

import { httpGet } from "../../../services/HttpService";

const hentSokn = async (id: string) =>
  httpGet(`api/sokn/GetByAktivitetId/${id}`);

export const useGetSoknByAktivitetId = (aktivitetid: string) =>
  useQuery({
    queryKey: ["soknByAktivitetQuery", aktivitetid],
    queryFn: () => hentSokn(aktivitetid),
    enabled: Boolean(aktivitetid),
  });
