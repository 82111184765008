import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { httpGet } from "../../../services/HttpService";
import { SatsingData } from "../../../utils/types";

const hentAlleSatsinger = async () => {
  const alleSatsinger: SatsingData[] = await httpGet(
    "api/Satsing/GetAlleSatsinger"
  );

  return alleSatsinger;
};

export const useGetSatsingerForAlleSokn = (
  onsuccess?: ((data: SatsingData[]) => void) | undefined
) =>
  useQuery<SatsingData[], AxiosError>({
    queryKey: ["satsingAlleSoknQuery"],
    queryFn: () => hentAlleSatsinger(),
    onSuccess: onsuccess,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
