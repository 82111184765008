import { useContext, useEffect, useRef, useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Button, Grid, TableCell, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import { Column } from "./AktiviteterTable";
import {
  AccessGroupContext,
  AccessGroupContextType,
  setGroup,
} from "../../../components/contexts/AccessGroupContext";
import {
  SoknContext,
  SoknContextType,
} from "../../../components/contexts/SoknContext";
import kirkeTheme from "../../../utils/kirkeTheme";
import { AktivitetData, SoknType } from "../../../utils/types";
import { useGetSoknByAktivitetId } from "../api";

const styles = {
  open: {
    height: "100%",
    transition: kirkeTheme.transitions.create(["height"], {
      duration: kirkeTheme.transitions.duration.short,
      easing: kirkeTheme.transitions.easing.easeInOut,
    }),
  },
  close: {
    maxHeight: "200px",
    overflow: "hidden",
    transition: kirkeTheme.transitions.create(["height"], {
      duration: kirkeTheme.transitions.duration.short,
      easing: kirkeTheme.transitions.easing.easeInOut,
    }),
  },
  btnWrapper: {
    width: kirkeTheme.spacing(14),
  },
  btn: {
    textTransform: "none",
    width: "100%",
    marginTop: kirkeTheme.spacing(2),
    marginBottom: kirkeTheme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    background: kirkeTheme.palette.common.black,
  },
  link: {
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
};

interface CustomizedState {
  goBack: boolean;
}

interface Props {
  Aktivitet: AktivitetData;
  column: Column;
}

const AktiviteterRow = ({ Aktivitet, column }: Props) => {
  const [showButton, setShowButton] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [aktivitetId, setAktivitetId] = useState<string>("");
  const textRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const { setAccessGroup } = useContext(
    AccessGroupContext
  ) as AccessGroupContextType;
  const { setSokn, setPlaner } = useContext(SoknContext) as SoknContextType;

  const isOverflow = (e: any) => e.offsetHeight < e.scrollHeight;

  useEffect(() => {
    if (textRef) {
      setShowButton(isOverflow(textRef.current));
    }
  }, [textRef]);

  const { data: sokn } = useGetSoknByAktivitetId(aktivitetId);
  useEffect(() => {
    if (sokn) {
      setSokn && setSokn(sokn as SoknType);
      setPlaner && setPlaner([]);
      setGroup(sokn, setAccessGroup);
      if (location.state) {
        const { goBack } = location.state as CustomizedState;
        if (goBack) {
          navigate(-1);
        } else {
          navigate("/aktivitet/" + aktivitetId);
        }
      } else {
        navigate("/aktivitet/" + aktivitetId);
      }
    }
  }, [sokn]);

  const formaterAktivitetData = () => {
    if (column.id === "aldersgrupper") {
      const sorterteAldersgrupper = Aktivitet[column.id].sort((s, g) =>
        s.localeCompare(g)
      );
      return sorterteAldersgrupper.map((x, i) => {
        if (x === "Over 65 år") {
          return " Over 65";
        }
        if (i === 0) {
          return x.split(" ")[0];
        }
        return `, ${x.split(" ")[0]}`;
      });
    } else if (column.id === "kategorier" || column.id === "satsinger") {
      return Aktivitet[column.id]?.map((x, i) => {
        if (i === 0) {
          return `${x}`;
        }
        return `, ${x}`;
      });
    }
    if (column.id === "tittel") {
      return (
        <Grid sx={styles.link} onClick={() => setAktivitetId(Aktivitet.id)}>
          {Aktivitet[column.id]}
        </Grid>
      );
    } else {
      return Aktivitet[column.id];
    }
  };

  return (
    <TableCell
      key={column.id}
      align={column.align}
      width={column.minWidth}
      sx={{ paddingLeft: 0 }}
    >
      <Box sx={showMore ? styles.open : styles.close} ref={textRef}>
        <Typography sx={{ wordBreak: "keep-all" }}>
          {formaterAktivitetData()}
        </Typography>
      </Box>
      {showButton && (
        <Box sx={styles.btnWrapper}>
          <Button
            variant="contained"
            sx={styles.btn}
            endIcon={
              showMore ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
            }
            onClick={() => setShowMore(!showMore)}
          >
            <b>{showMore ? "Mindre" : "Mer"}</b>
          </Button>
        </Box>
      )}
    </TableCell>
  );
};

export default AktiviteterRow;
