import { useContext, useState } from "react";

import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";

import { useGetPlanerForAlleSokn } from "./../api";
import PlanerOptions from "./PlanerOptions";
import PlanerRow from "./PlanerRow";
import { ErrorInfoBox, LoadingSpinner } from "../../../components/common";
import {
  UserPreferencesContext,
  UserPreferencesContextType,
} from "../../../components/contexts/UserPreferencesContext";
import kirkeTheme from "../../../utils/kirkeTheme";
import { sortAsc, sortDesc } from "../../../utils/tableHelper";
import { AlleDataLedetekster, PlanerData } from "../../../utils/types";
import Pagination from "../Pagination";

export type PlanColumnIdType =
  | "sokn"
  | "fellesrad"
  | "prosti"
  | "bispedomme"
  | "organisasjonsnummer"
  | "planperiode"
  | "planlegging"
  | "beskrivelseLokalsamfunn"
  | "beskrivelseRessurser"
  | "beskrivelseUtfordringer";

export interface Column {
  id: PlanColumnIdType;
  label: string;
  minWidth?: number;
  align?: "right" | "left" | "center";
}

const sortArray = (
  arr: PlanerData[],
  orderBy: "asc" | "desc",
  columnId: PlanColumnIdType
) => {
  const sortMethod = orderBy === "asc" ? sortAsc : sortDesc;
  if (columnId === "planperiode") {
    return arr.sort((a, b) => sortMethod(a.startAar, b.sluttAar));
  } else {
    return arr.sort((a, b) => {
      const aValue = a[columnId];
      const bValue = b[columnId];
      if (!aValue && !bValue) {
        return 0;
      } else if (!aValue) {
        return 1;
      } else if (!bValue) {
        return -1;
      } else {
        return sortMethod(aValue, bValue);
      }
    });
  }
};

interface Props {
  searchValue?: string;
  ledetekster?: AlleDataLedetekster;
}

const PlanerTable = ({ searchValue, ledetekster }: Props) => {
  const [filteredPlanerData, setFilteredPlanerData] = useState<PlanerData[]>();
  const [orderDirection, setOrderDirection] = useState<"asc" | "desc">("asc");
  const [page, setPage] = useState(0);
  const { rowsPerPage = 20, setRowsPerPage } = useContext(
    UserPreferencesContext
  ) as UserPreferencesContextType;
  const [activeColumn, setActiveColumn] = useState<
    PlanColumnIdType | undefined
  >();
  const {
    showPlanColumns = [
      "sokn",
      "planperiode",
      "beskrivelseLokalsamfunn",
      "beskrivelseRessurser",
      "beskrivelseUtfordringer",
    ],
    setShowPlanColumns,
  } = useContext(UserPreferencesContext) as UserPreferencesContextType;

  const {
    data: planerData,
    error,
    isLoading,
  } = useGetPlanerForAlleSokn((data) => {
    setFilteredPlanerData(data);
  });

  const columns: readonly Column[] = [
    {
      id: "sokn",
      label: ledetekster?.soknKolonneTekst || "Sokn",
      minWidth: 200,
    },
    {
      id: "fellesrad",
      label: ledetekster?.fellesradKolonneTekst || "FR",
      minWidth: 200,
    },
    {
      id: "prosti",
      label: ledetekster?.prostiKolonneTekst || "Prosti",
      minWidth: 150,
    },
    {
      id: "bispedomme",
      label: ledetekster?.bispedommeKolonneTekst || "Bispedømme",
      minWidth: 150,
    },
    {
      id: "organisasjonsnummer",
      label: ledetekster?.orgnummerKolonneTekst || "Orgnummer",
      minWidth: 100,
    },
    {
      id: "planperiode",
      label: ledetekster?.planperiodeKolonneTekst || "Planperiode",
      minWidth: 80,
    },
    {
      id: "planlegging",
      label: ledetekster?.beskrivelseKolonneTekst || "Planlegging",
      minWidth: 200,
    },
    {
      id: "beskrivelseLokalsamfunn",
      label: ledetekster?.beskrivelseKolonneTekst || "Beskrivelse",
      minWidth: 200,
    },
    {
      id: "beskrivelseRessurser",
      label: ledetekster?.ressurserKolonneTekst || "Ressurser",
      minWidth: 400,
    },
    {
      id: "beskrivelseUtfordringer",
      label: ledetekster?.ufordringerKolonneTekst || "Utfordringer",
      minWidth: 400,
    },
  ];

  const handleSortRequest = (columnId: PlanColumnIdType) => {
    setActiveColumn(columnId);
    if (filteredPlanerData) {
      sortArray(filteredPlanerData, orderDirection, columnId);
    }
    setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
  };

  const isActive = (columnId: PlanColumnIdType) => {
    if (activeColumn && activeColumn === columnId) {
      return true;
    } else {
      return !activeColumn;
    }
  };

  const showColumn = (column: PlanColumnIdType) =>
    showPlanColumns?.includes(column);

  return (
    <Box sx={{ paddingTop: kirkeTheme.spacing(5), paddingLeft: "16px" }}>
      {isLoading && <LoadingSpinner />}
      {error && <ErrorInfoBox error={error} />}
      {!isLoading && planerData && (
        <>
          <PlanerOptions
            planerData={planerData}
            setFilteredPlanerData={setFilteredPlanerData}
            showColumns={showPlanColumns}
            setShowColumns={setShowPlanColumns}
            searchValue={searchValue}
            columns={columns}
            ledetekster={ledetekster}
          />
          <TableContainer sx={{ overflowX: "visible" }}>
            <Table aria-label="alle data tabell">
              <TableHead>
                <TableRow
                  sx={{
                    borderBottom: "1px solid rgba(224, 224, 224)",
                  }}
                >
                  {columns?.map((column) => {
                    return (
                      showColumn(column.id) && (
                        <TableCell
                          key={column.id}
                          sx={{ minWidth: column.minWidth, paddingLeft: 0 }}
                        >
                          <TableSortLabel
                            onClick={() => handleSortRequest(column.id)}
                            active={isActive(column.id)}
                            direction={orderDirection}
                            IconComponent={ArrowDropDownSharpIcon}
                          >
                            <Typography variant="boldText">
                              {column?.label}
                            </Typography>
                          </TableSortLabel>
                        </TableCell>
                      )
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredPlanerData &&
                  filteredPlanerData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((Plan) => (
                      <TableRow
                        tabIndex={0}
                        key={Plan.id}
                        data-testid="alledata-listitem"
                        sx={{
                          verticalAlign: "top",
                          "&:nth-of-type(even)": {
                            backgroundColor: "#E9EDF5",
                          },
                        }}
                      >
                        {columns?.map(
                          (column: Column) =>
                            showColumn(column.id) && (
                              <PlanerRow
                                key={column.id}
                                plan={Plan}
                                column={column}
                              />
                            )
                        )}
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          {planerData && (
            <Pagination
              count={
                filteredPlanerData !== undefined
                  ? filteredPlanerData?.length
                  : planerData.length
              }
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              page={page}
              setPage={setPage}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default PlanerTable;
