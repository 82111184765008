import React, { createContext } from "react";

import { AktivitettabellColumnIdType } from "../../pages/AktiviteterPage/FormaterAktivitet";
import { AktivitetColumnIdType } from "../../pages/AlleDataPage/AktiviteterData/AktiviteterTable";
import { GronnkirkeColumnIdType } from "../../pages/AlleDataPage/GronnkirkeData/GronnkirkeTable";
import { PlanColumnIdType } from "../../pages/AlleDataPage/PlanerData/PlanerTable";
import { SatsingColumnIdType } from "../../pages/AlleDataPage/SatsingerData/SatsingerTable";
import useLocalStorage from "../../utils/useLocalStorage";

export type UserPreferencesContextType = {
  setShowPlanColumns: (showPlanColumns: PlanColumnIdType[] | undefined) => void;
  showPlanColumns: PlanColumnIdType[];
  setShowAktivitetColumns: (
    showAktivitetColumns: AktivitetColumnIdType[] | undefined
  ) => void;
  showAktivitetColumns: AktivitetColumnIdType[];
  setShowSatsingColumns: (
    showSatsingColumns: SatsingColumnIdType[] | undefined
  ) => void;
  showSatsingColumns: SatsingColumnIdType[];
  setShowGronnkirkeColumns: (
    showGronnkirkeColumns: GronnkirkeColumnIdType[] | undefined
  ) => void;
  showGronnkirkeColumns: GronnkirkeColumnIdType[];
  setShowAktivitettabellColumns: (
    showAktivitettabellColumns: AktivitettabellColumnIdType[] | undefined
  ) => void;
  showAktivitettabellColumns: AktivitettabellColumnIdType[];
  setAarFilter: (alleDataYear: string | undefined) => void;
  aarFilter: string;
  setRowsPerPage: (rowsPerPage: number | undefined) => void;
  rowsPerPage: number;
};

export const UserPreferencesContext = createContext<
  UserPreferencesContextType | any
>({});

interface Props {
  children: React.ReactNode;
}

export const UserPreferencesContextProvider: React.FC<Props> = ({
  children,
}) => {
  const [showPlanColumns, setShowPlanColumns] =
    useLocalStorage("showPlanColumns");
  const [showAktivitetColumns, setShowAktivitetColumns] = useLocalStorage(
    "showAktivitetColumns"
  );
  const [showSatsingColumns, setShowSatsingColumns] =
    useLocalStorage("showSatsingColumns");
  const [showGronnkirkeColumns, setShowGronnkirkeColumns] = useLocalStorage(
    "showGronnkirkeColumns"
  );
  const [showAktivitettabellColumns, setShowAktivitettabellColumns] =
    useLocalStorage("showAktivitettabellColumns");
  const [aarFilter, setAarFilter] = useLocalStorage("alleDataYear");
  const [rowsPerPage, setRowsPerPage] = useLocalStorage("antallRaderTabell");

  return (
    <UserPreferencesContext.Provider
      value={{
        setShowPlanColumns,
        showPlanColumns,
        setShowAktivitetColumns,
        showAktivitetColumns,
        setShowSatsingColumns,
        showSatsingColumns,
        setShowGronnkirkeColumns,
        showGronnkirkeColumns,
        showAktivitettabellColumns,
        setShowAktivitettabellColumns,
        setAarFilter,
        aarFilter,
        rowsPerPage,
        setRowsPerPage,
      }}
    >
      {children}
    </UserPreferencesContext.Provider>
  );
};
