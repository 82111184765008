import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { httpGet } from "../../../services/HttpService";
import * as Types from "../../../utils/types";

const hentAlleBispedommme = async () => {
  const res = await httpGet("api/bispedomme/GetAlleBispedommer");
  return res;
};

export const useGetAlleBispedomme = () =>
  useQuery<Types.BispedommeType[], AxiosError>({
    queryKey: ["alleBispedommeQuery"],
    queryFn: () => hentAlleBispedommme(),
  });
