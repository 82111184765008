import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export const LoadingSpinner = () => (
  <Box>
    <CircularProgress />
  </Box>
);

export default LoadingSpinner;
