import { groq } from "next-sanity";

export const veiledningQuery = groq`
*[_type == "veiledningKapittel" && !(_id in path('drafts.**'))][0] {
    _id,
    "veiledningPageTitle": coalesce(veiledningPageTitle[$malform], veiledningPageTitle.nb),
    topText {
      "tittelLokal": coalesce(tittelLocale[$malform], tittelLocale.nb),
      "beskrivelseLokal": coalesce(beskrivelseLocale[$malform], beskrivelseLocale.nb),
      "knappetekstlLokal": coalesce(knappetekstlLocale[$malform], knappetekstlLocale.nb),
      "boksoverskrift": coalesce(boksoverskrift[$malform], boksoverskrift.nb),
      video,
      "videoTittel": coalesce(videoTittel[$malform], videoTittel.nb),
    },
    veiledningKapitler[] {
      _key,
      slug,
      vispaforsiden,
      faner[] {
        _key,
        visfanen,
        "tittelLokal": coalesce(tittelLocale[$malform], tittelLocale.nb),
        "tekstLokal": coalesce(tekstLocale[$malform], tekstLocale.nb),
      },
      fanerInnhold[] {
        _key,
        visfanen,
        avsnittInnhold[] {
          _key,
          avsnittId,
          "tittelLokal": coalesce(tittelLocale[$malform], tittelLocale.nb),
          "tekstLokal": coalesce(tekstLocale[$malform], tekstLocale.nb),
        }
      },
      "kapTittelLokal": coalesce(kapTittelLocale[$malform], kapTittelLocale.nb),
      kapBoks {
        ikon,
        "beskrivelseLokal": coalesce(beskrivelseLocale[$malform], beskrivelseLocale.nb),
      },
    },
  }
`;

export default veiledningQuery;
