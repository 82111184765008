import { Box } from "@mui/material";

import Video from "../components/common/Video";
import { VimeoVideoType } from "../utils/types";

interface VideoProps {
  content: VimeoVideoType;
}

export function ModuleVideo({ content }: VideoProps) {
  const { tittel, url, bredde = 630, fullbredde, videobeskrivelse } = content;
  return (
    <Box
      sx={{
        marginTop: "20px",
        marginBottom: "20px",
        width: fullbredde ? "100%" : bredde,
      }}
    >
      {tittel && (
        <p>
          <b>{tittel}</b>
        </p>
      )}
      <Video video={url} />
      {videobeskrivelse && (
        <p>
          <small>{videobeskrivelse}</small>
        </p>
      )}
    </Box>
  );
}
