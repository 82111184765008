import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { httpGet } from "../../../services/HttpService";
import decodeBase64Fields from "../../../utils/decodeBase64Fields";
import { PlanerData } from "../../../utils/types";

const hentAllePlaner = async () => {
  const allePlaner: PlanerData[] = await httpGet("api/Plan/GetAllePlaner");
  // Decode fields from Base64 to readable text
  const transformedData = transformPlanData(allePlaner);
  return transformedData;
};

export const useGetPlanerForAlleSokn = (
  onsuccess?: ((data: PlanerData[]) => void) | undefined
) =>
  useQuery<PlanerData[], AxiosError>({
    queryKey: ["planerAlleSoknQuery"],
    queryFn: () => hentAllePlaner(),
    onSuccess: onsuccess,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

const transformPlanData = (data: PlanerData[]) => {
  const transformedData = data.map((plan) => {
    const planTransformed = decodeBase64Fields(plan, [
      "planlegging",
      "beskrivelseLokalsamfunn",
      "beskrivelseRessurser",
      "beskrivelseUtfordringer",
    ]);
    return planTransformed;
  });
  return transformedData;
};
