import { useEffect, useState } from "react";

import { KeyboardArrowRight } from "@mui/icons-material";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";

import UnderliggendeProstiRow from "./UnderliggendeProstiRow";
import { LoadingSpinner } from "../../../components/common";
import { formatertDato } from "../../../utils";
import kirkeTheme from "../../../utils/kirkeTheme";
import {
  AlleDataLedetekster,
  AlleTallPlanBispdomme,
} from "../../../utils/types";
import { useGetPlanerTallForBispedomme } from "../api/useGetPlanerTallForBispedomme";
export type AlleTallPlanerColumnType =
  | "navn"
  | "planperiode"
  | "antallPlaner"
  | "antallPlanlegginger"
  | "antallBeskrivelser"
  | "antallRessurser"
  | "antallUtfordringer"
  | "antallSatsinger"
  | "sistEndret";

export interface AlleTallPlanerColumn {
  id: AlleTallPlanerColumnType;
  label: string;
  minWidth?: number;
  align?: "right" | "left" | "center";
}
export const hasOwn = <RecordKeys extends string>(
  record: Readonly<Record<RecordKeys, unknown>>,
  key: string
): key is RecordKeys => {
  return Object.hasOwn(record, key);
};

interface Props {
  bispedommeId: string;
  ledetekster?: AlleDataLedetekster;
}
const PlanerTable = ({ bispedommeId, ledetekster }: Props) => {
  const [tallData, setTallData] = useState<AlleTallPlanBispdomme>();
  const [columns, setColumns] = useState<AlleTallPlanerColumn[]>([]);
  const isOnMobileDevice = useMediaQuery(kirkeTheme.breakpoints.down("md"));
  const columnsDesktop: AlleTallPlanerColumn[] = [
    {
      id: "navn",
      label: ledetekster?.soknKolonneTekst || "Enhet",
      minWidth: 310,
    },
    {
      id: "antallPlaner",
      label: ledetekster?.beskrivelseKolonneTekst || "Plan",
      minWidth: 100,
    },
    {
      id: "antallPlanlegginger",
      label: ledetekster?.beskrivelseKolonneTekst || "Planlegging",
      minWidth: 100,
    },
    {
      id: "antallBeskrivelser",
      label: ledetekster?.beskrivelseKolonneTekst || "Beskrivelse",
      minWidth: 100,
    },
    {
      id: "antallRessurser",
      label: ledetekster?.ressurserKolonneTekst || "Ressurser",
      minWidth: 100,
    },
    {
      id: "antallUtfordringer",
      label: ledetekster?.ufordringerKolonneTekst || "Utfordringer",
      minWidth: 100,
    },
    {
      id: "antallSatsinger",
      label: ledetekster?.ufordringerKolonneTekst || "Satsinger",
      minWidth: 100,
    },
    {
      id: "sistEndret",
      label: /*ledetekster?.sistEndretKolonneTekst ||*/ "Sist endret",
      minWidth: 150,
    },
  ];

  const columnsMobile: AlleTallPlanerColumn[] = [
    {
      id: "navn",
      label: ledetekster?.soknKolonneTekst || "Enhet",
      minWidth: 310,
    },
    {
      id: "antallPlaner",
      label: ledetekster?.beskrivelseKolonneTekst || "Plan",
      minWidth: 100,
    },
    {
      id: "antallSatsinger",
      label: ledetekster?.ufordringerKolonneTekst || "Satsinger",
      minWidth: 100,
    },
    {
      id: "sistEndret",
      label: /*ledetekster?.sistEndretKolonneTekst ||*/ "Sist endret",
      minWidth: 150,
    },
  ];

  useEffect(() => {
    if (isOnMobileDevice) {
      setColumns(columnsMobile);
    } else {
      setColumns(columnsDesktop);
    }
  }, [isOnMobileDevice]);

  const { error, isLoading } = useGetPlanerTallForBispedomme(
    bispedommeId,
    (data) => setTallData(data)
  );

  const mappedData = columns.map((column) => {
    if (tallData && hasOwn(tallData, column.id)) {
      return tallData[column.id];
    }
    return null;
  });
  const [open, setOpen] = useState(false);
  return (
    <Box
      sx={{
        marginTop: kirkeTheme.spacing(5),
        paddingLeft: kirkeTheme.spacing(2),
      }}
    >
      {isLoading && <LoadingSpinner />}
      {!isLoading && error && (
        <Box>
          <Typography>
            Det skjedde en feil ved lasting av alle tall, prøv igjen senere
          </Typography>
        </Box>
      )}
      {!isLoading && !error && tallData && (
        <Paper>
          <TableContainer sx={{ overflowX: "visible" }}>
            <Table aria-label="alle tall tabell">
              <TableHead>
                <TableRow
                  sx={{
                    borderBottom: "1px solid rgba(224, 224, 224)",
                  }}
                >
                  {columns?.map((column) => {
                    switch (column.id) {
                      case "navn":
                        return (
                          <TableCell
                            key={column.id}
                            sx={{
                              width: column.minWidth,
                            }}
                          >
                            <Typography variant="boldText">
                              {column?.label}
                            </Typography>
                          </TableCell>
                        );
                      default:
                        return (
                          <TableCell
                            key={column.id}
                            sx={{
                              minWidth: column.minWidth,
                            }}
                          >
                            <Typography variant="boldText">
                              {column?.label}
                            </Typography>
                          </TableCell>
                        );
                    }
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {Object.entries(mappedData).map(([key, value]) => {
                    if (value == null) {
                      return <TableCell key={key}></TableCell>;
                    } else {
                      if (key === "0") {
                        return (
                          <TableCell key={key}>
                            <Typography>
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => setOpen(!open)}
                              >
                                {open ? (
                                  <KeyboardArrowDown />
                                ) : (
                                  <KeyboardArrowRight />
                                )}
                              </IconButton>
                              {value}
                            </Typography>
                          </TableCell>
                        );
                      } else {
                        if (parseInt(key) == columns.length - 1) {
                          return (
                            <TableCell key={key}>
                              {formatertDato(value as string, "long")}
                            </TableCell>
                          );
                        } else {
                          return <TableCell key={key}>{value}</TableCell>;
                        }
                      }
                    }
                  })}
                </TableRow>
                <TableRow>
                  <TableCell colSpan={columns.length} sx={{ padding: 0 }}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                      {tallData.underliggendeProstier?.map((prosti) => (
                        <UnderliggendeProstiRow
                          key={prosti.navn}
                          prosti={prosti}
                          columns={columns}
                        />
                      ))}
                    </Collapse>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </Box>
  );
};
export default PlanerTable;
