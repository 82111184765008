import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import { feilmeldingerQuery } from "../../api/feilmeldinger";
import { useFetchSanityData } from "../../api/useFetchSanityData";
import { InfoBox } from "./index";

interface Props {
  error?: Error | unknown;
  customMessage?: string;
}
const styles = {
  icon: {
    fontSize: 70,
  },
};

export const ErrorInfoBox = ({ error, customMessage }: Props) => {
  const { data: feilmeldinger } = useFetchSanityData(
    feilmeldingerQuery,
    "feilmeldinger"
  );

  if (customMessage) {
    return (
      <InfoBox
        icon={<WarningAmberIcon sx={styles.icon} />}
        message={customMessage}
      />
    );
  }
  switch (error instanceof Error && error.message) {
    case "Network Error":
      return (
        <InfoBox
          icon={<WarningAmberIcon sx={styles.icon} />}
          message={
            feilmeldinger?.http?.nettverksfeil ||
            "Nettverksfeil. Prøv igjen senere."
          }
        />
      );
    case "Request failed with status code 500":
      return (
        <InfoBox
          icon={<WarningAmberIcon sx={styles.icon} />}
          message={
            feilmeldinger?.http?.femhundre ||
            "Det skjedde en uventet feil på serveren."
          }
        />
      );
    case "Request failed with status code 401":
    case "Unauthorized":
      return (
        <InfoBox
          icon={<WarningAmberIcon sx={styles.icon} />}
          message={
            feilmeldinger?.http?.firenullen ||
            "Du har ikke tilgang til denne tjenesten."
          }
        />
      );
    default:
      return (
        <InfoBox
          icon={<WarningAmberIcon sx={styles.icon} />}
          message={feilmeldinger?.uventetFeil || "Det skjedde en uventet feil."}
        />
      );
  }
};

export default ErrorInfoBox;
