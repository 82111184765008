import { useCallback, useContext, useEffect, useState } from "react";

import { FormControl, MenuItem, Grid } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import { Column, SatsingColumnIdType } from "./SatsingerTable";
import { LoadingSpinner } from "../../../components/common";
import nullstillContext from "../../../components/contexts/nullstillContext";
import {
  UserPreferencesContext,
  UserPreferencesContextType,
} from "../../../components/contexts/UserPreferencesContext";
import { httpGetBlob } from "../../../services/HttpService";
import varslingPopup from "../../../services/varslinger/varslingsService";
import { aarListe, range } from "../../../utils";
import kirkeTheme from "../../../utils/kirkeTheme";
import { saveFileBlob } from "../../../utils/saveFile";
import { AlleDataLedetekster, SatsingData } from "../../../utils/types";
import DataColumns from "../DataColumns";
import DownloadExcel from "../DownloadExcel";
import TilbakestillValgKnapp from "../TilbakestillValgKnapp";

const styles = {
  aarselect: {
    backgroundColor: kirkeTheme.palette.common.white,
    height: "40px",
    width: "102px",
    maxWidth: "100%",
  },
  uttrekkselect: {
    backgroundColor: kirkeTheme.palette.common.white,
    height: "40px",
    width: "271px",
    maxWidth: "100%",
  },
  formControl: {
    top: kirkeTheme.spacing(2),
    margin: "0",
    padding: "0",
  },
};

interface Props {
  satsingerData: SatsingData[];
  setFilteredSatsingerData: (aktiviteter: SatsingData[]) => void;
  showColumns: SatsingColumnIdType[];
  setShowColumns: (columns: SatsingColumnIdType[]) => void;
  searchValue?: string;
  columns: readonly Column[];
  ledetekster?: AlleDataLedetekster;
}

const SatsingerOptions = ({
  satsingerData,
  setFilteredSatsingerData,
  showColumns,
  setShowColumns,
  searchValue,
  columns,
  ledetekster,
}: Props) => {
  const aar = new Date().getFullYear().toString();
  const [showSelectAar, setShowSelectAar] = useState<boolean>(false);
  const [showSelectColumns, setShowSelectColumns] = useState<boolean>(false);
  const [excelLoading, setExcelLoading] = useState(false);
  const { aarFilter = aar, setAarFilter } = useContext(
    UserPreferencesContext
  ) as UserPreferencesContextType;

  const allColumns: SatsingColumnIdType[] = [
    "sokn",
    "fellesrad",
    "prosti",
    "bispedomme",
    "organisasjonsnummer",
    "planperiode",
    "overskrift",
    "beskrivelse",
    "antallAktiviteter",
  ];

  const tilbakestillBrukervalg = () => {
    setShowColumns([
      "sokn",
      "fellesrad",
      "prosti",
      "overskrift",
      "beskrivelse",
    ]);
    setAarFilter(aar);
    nullstillContext("SatsingerAlleData");
  };

  const mapColumnToText = (column: string) => {
    const matchColumn = columns.find((c) => c.id === column);
    return matchColumn?.label;
  };

  const aarMedAlle = [{ id: "alleAar", tittel: "Alle år" }, ...aarListe];

  const handleChange = (
    event: SelectChangeEvent<string | string[]>,
    filter: any
  ) => filter(event.target.value);

  const downloadExcel = useCallback(async () => {
    try {
      setExcelLoading(true);
      const res = await httpGetBlob("api/satsing/getuttrekkexcelAlleSokn");
      saveFileBlob(res, `Satsinger.xlsx`);
    } catch (e: any) {
      varslingPopup.error(e.message);
    } finally {
      setExcelLoading(false);
    }
  }, []);

  const handleColumnChange = (event: SelectChangeEvent<string | string[]>) => {
    setShowColumns(event.target.value as unknown as SatsingColumnIdType[]);
  };

  const filterBySearch = () => {
    let filteredBySearch: SatsingData[] = [];
    if (!searchValue) {
      filteredBySearch = satsingerData;
    } else {
      filteredBySearch = satsingerData?.filter(
        (satsing) =>
          satsing?.beskrivelse
            ?.toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          satsing?.overskrift
            ?.toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          satsing?.sokn?.toLowerCase().includes(searchValue.toLowerCase())
      );
    }
    return filteredBySearch;
  };

  useEffect(() => {
    if (satsingerData) {
      const arrayThatHoldsFilteredElements: SatsingData[] = [];
      let filteredByAar: SatsingData[] = [];
      if (aarFilter === "alleAar") {
        filteredByAar = satsingerData;
      } else {
        filteredByAar = satsingerData?.filter((a) =>
          range(a.planStartAar, a.planSluttAar).includes(Number(aarFilter))
        );
      }
      const filteredBySearch: SatsingData[] = filterBySearch();
      filteredByAar?.forEach((element) => {
        if (filteredBySearch?.includes(element)) {
          arrayThatHoldsFilteredElements.push(element);
        }
      });
      setFilteredSatsingerData(arrayThatHoldsFilteredElements);
    }
  }, [satsingerData, aarFilter, searchValue]);

  return (
    <Grid
      container
      display="flex"
      direction="row"
      spacing={2}
      mb={kirkeTheme.spacing(3)}
    >
      <Grid item xs={12} md={1.5}>
        <FormControl fullWidth sx={styles.formControl}>
          <Select
            open={showSelectAar}
            onOpen={() => setShowSelectAar(true)}
            onClose={() => setShowSelectAar(false)}
            value={aarFilter}
            displayEmpty
            onChange={(e) => handleChange(e, setAarFilter)}
            sx={styles.aarselect}
          >
            {aarMedAlle.map((ar) => (
              <MenuItem key={ar.id} value={ar.id}>
                {ar.tittel}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={3.2}>
        <FormControl fullWidth sx={styles.formControl}>
          <DataColumns
            columns={allColumns}
            disabledColumns={["sokn", "overskrift"]}
            showColumns={showColumns}
            mapColumnToText={mapColumnToText}
            showSelectColumns={showSelectColumns}
            setShowSelectColumns={setShowSelectColumns}
            handleColumnChange={handleColumnChange}
            ledetekster={ledetekster}
          ></DataColumns>
        </FormControl>
      </Grid>
      <DownloadExcel downloadExcel={downloadExcel} />
      <TilbakestillValgKnapp tilbakestillBrukervalg={tilbakestillBrukervalg} />
      <Grid item sx={{ pt: "30px !important" }}>
        {excelLoading && <LoadingSpinner />}
      </Grid>
    </Grid>
  );
};
export default SatsingerOptions;
