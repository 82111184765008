import { useContext, useEffect, useState } from "react";

import { Menu, MenuItem, Typography } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { useGetAlleSokn, useGetSokn } from "../../pages/AlleSoknPage/api";
import kirkeTheme from "../../utils/kirkeTheme";
import { SoknType } from "../../utils/types";
import {
  AccessGroupContext,
  AccessGroupContextType,
  setGroup,
} from "../contexts/AccessGroupContext";

interface Props {
  anchorEl: null | HTMLElement;
  setAnchorEl: (anchorEl: null | HTMLElement) => void;
  setSokn: (sokn: SoknType) => void;
}

const VelgSoknHeaderMeny = ({ anchorEl, setAnchorEl, setSokn }: Props) => {
  const { data: alleSokn } = useGetAlleSokn();
  const open = Boolean(anchorEl);
  const [soknId, setSoknId] = useState<string>("");
  const { data: valgtSokn } = useGetSokn(soknId);
  const navigate = useNavigate();

  const { setAccessGroup } = useContext(
    AccessGroupContext
  ) as AccessGroupContextType;

  useEffect(() => {
    const curentLocation = window.location.toString().split("/")[3];
    if (valgtSokn) {
      setSokn(valgtSokn as SoknType);
      setGroup(valgtSokn, setAccessGroup);
      setAnchorEl(null);
      navigate("/" + curentLocation ?? "sokn");
    }
  }, [valgtSokn]);

  return (
    <Menu
      sx={{ marginTop: kirkeTheme.spacing(1.5) }}
      id="menu-appbar"
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={() => setAnchorEl(null)}
      data-testid="velgsokn-dropdownmenu"
    >
      {alleSokn?.map((sokn) => {
        return (
          <MenuItem
            onClick={() => setSoknId(sokn.id)}
            key={sokn.id}
            data-testid="velgsokn-menuitem"
          >
            <Typography>{sokn.navn}</Typography>
          </MenuItem>
        );
      })}
    </Menu>
  );
};

export default VelgSoknHeaderMeny;
