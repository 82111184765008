import { InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Button } from "@mui/material";

import kirkeTheme from "../../utils/kirkeTheme";
import { loginRequest } from "../../utils/msal/authConfig";

const styles = {
  sign: {
    background: kirkeTheme.palette.primary.main,
    color: kirkeTheme.palette.common.white,
    width: "100%",
    textTransform: "none",
    ":hover": {
      backgroundColor: kirkeTheme.palette.primary.dark,
    },
  },
};

const SignInButton = () => {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const handleLogin = async () => {
    // check that no other interaction is in progress before invoking loginRedirect
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      localStorage.removeItem("sokn");
      localStorage.removeItem("groups");
      await instance.loginRedirect(loginRequest);
    }
  };

  return (
    <Button
      sx={styles.sign}
      onClick={() => {
        handleLogin();
      }}
    >
      Logg inn
    </Button>
  );
};

export default SignInButton;
