import { groq } from "next-sanity";

export const vedleggQuery = groq`
*[_type == "vedlegg" && !(_id in path('drafts.**'))][0] {
    vedleggPlan {
        "tittel":  coalesce(tittel[$malform], tittel.nb),
        "beskrivelse":  coalesce(beskrivelse[$malform], beskrivelse.nb),
    },
    vedleggAktivitet {
        "tittel":  coalesce(tittel[$malform], tittel.nb),
        "beskrivelse":  coalesce(beskrivelse[$malform], beskrivelse.nb),
    },
    ledeteksterVedlegg {
        "lastOppVedlegg":  coalesce(lastOppVedlegg[$malform], lastOppVedlegg.nb),
        "lastNed":  coalesce(lastNed[$malform], lastNed.nb),
        "slette":  coalesce(slette[$malform], slette.nb),
        "sletteVedlegg":  coalesce(sletteVedlegg[$malform], sletteVedlegg.nb),
        "sikkerSletteVedlegg":  coalesce(sikkerSletteVedlegg[$malform], sikkerSletteVedlegg.nb),
        "ingenVedleggEnnaa":  coalesce(ingenVedleggEnnaa[$malform], ingenVedleggEnnaa.nb),
        "vedleggetBleSlettet":  coalesce(vedleggetBleSlettet[$malform], vedleggetBleSlettet.nb),
    },
  }
`;

export default vedleggQuery;
