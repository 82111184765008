import { useCallback, useEffect, useState } from "react";

import { FormControl, MenuItem, Grid } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import { AktivitetColumnIdType, Column } from "./AktiviteterTable";
import { LoadingSpinner } from "../../../components/common";
import nullstillContext from "../../../components/contexts/nullstillContext";
import { httpGetBlob } from "../../../services/HttpService";
import varslingPopup from "../../../services/varslinger/varslingsService";
import { aarListe } from "../../../utils";
import kirkeTheme from "../../../utils/kirkeTheme";
import { saveFileBlob } from "../../../utils/saveFile";
import { AktivitetData, AlleDataLedetekster } from "../../../utils/types";
import DataColumns from "../DataColumns";
import DownloadExcel from "../DownloadExcel";
import TilbakestillValgKnapp from "../TilbakestillValgKnapp";

const styles = {
  aarselect: {
    backgroundColor: kirkeTheme.palette.common.white,
    height: "40px",
    width: "102px",
    maxWidth: "100%",
  },
  formControl: {
    top: kirkeTheme.spacing(2),
    margin: "0",
    padding: "0",
  },
};

interface Props {
  AktiviteterData: AktivitetData[];
  setFilteredAktiviteterData: (aktiviteter: AktivitetData[]) => void;
  showColumns: AktivitetColumnIdType[];
  setShowColumns: (columns: AktivitetColumnIdType[]) => void;
  searchValue?: string;
  columns: readonly Column[];
  aar: string;
  setAar: (aar: string) => void;
  ledetekster?: AlleDataLedetekster;
}

const AktiviteterOptions = ({
  AktiviteterData,
  setFilteredAktiviteterData,
  showColumns,
  setShowColumns,
  searchValue,
  columns,
  aar,
  setAar,
  ledetekster,
}: Props) => {
  const [showSelectAar, setShowSelectAar] = useState<boolean>(false);
  const [showSelectColumns, setShowSelectColumns] = useState<boolean>(false);
  const [excelLoading, setExcelLoading] = useState(false);
  const currenAar = new Date().getFullYear().toString();

  const allColumns: AktivitetColumnIdType[] = [
    "sokn",
    "fellesrad",
    "prosti",
    "bispedomme",
    "organisasjonsnummer",
    "tittel",
    "aldersgrupper",
    "kategorier",
    "beskrivelse",
    "ansvarlig",
    "satsinger",
    "evalueringTekst",
    "antallDeltakere",
    "antallSamlinger",
  ];

  const tilbakestillBrukervalg = () => {
    setShowColumns(["sokn", "fellesrad", "prosti", "tittel", "beskrivelse"]);
    setAar(currenAar);
    nullstillContext("AktiviteterAlleData");
  };

  const mapColumnToText = (column: string) => {
    const matchColumn = columns.find((c) => c.id === column);
    return matchColumn?.label;
  };

  const handleColumnChange = (event: SelectChangeEvent<string | string[]>) => {
    setShowColumns(event.target.value as unknown as AktivitetColumnIdType[]);
  };

  const filterBySearch = () => {
    let filteredBySearch: AktivitetData[] = [];
    if (!searchValue) {
      filteredBySearch = [...AktiviteterData];
    } else {
      filteredBySearch = AktiviteterData?.filter(
        (Aktivitet) =>
          Aktivitet?.sokn?.toLowerCase().includes(searchValue.toLowerCase())
      );
    }
    return filteredBySearch;
  };

  useEffect(() => {
    if (AktiviteterData) {
      const filteredBySearch: AktivitetData[] = filterBySearch();
      setFilteredAktiviteterData(filteredBySearch);
    }
  }, [AktiviteterData, searchValue]);

  const downloadExcel = useCallback(async () => {
    try {
      setExcelLoading(true);
      const res = await httpGetBlob(
        `api/aktivitet/getuttrekkexcelAlleSokn/${aar}`
      );
      saveFileBlob(res, `Aktiviteter ${aar} - Kirka Vår.xlsx`);
    } catch (e: any) {
      varslingPopup.error(e.message);
    } finally {
      setExcelLoading(false);
    }
  }, []);

  return (
    <Grid
      container
      display="flex"
      direction="row"
      spacing={2}
      mb={kirkeTheme.spacing(3)}
    >
      <Grid item xs={12} md={1.5}>
        <FormControl fullWidth sx={styles.formControl}>
          <Select
            open={showSelectAar}
            onOpen={() => setShowSelectAar(true)}
            onClose={() => setShowSelectAar(false)}
            value={aar}
            displayEmpty
            onChange={(e) => setAar(e.target.value)}
            sx={styles.aarselect}
          >
            {aarListe.map((ar) => (
              <MenuItem key={ar.id} value={ar.id}>
                {ar.tittel}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3.2}>
        <FormControl fullWidth sx={styles.formControl}>
          <DataColumns
            columns={allColumns}
            disabledColumns={["sokn", "tittel"]}
            showColumns={showColumns}
            mapColumnToText={mapColumnToText}
            showSelectColumns={showSelectColumns}
            setShowSelectColumns={setShowSelectColumns}
            handleColumnChange={handleColumnChange}
            ledetekster={ledetekster}
          ></DataColumns>
        </FormControl>
      </Grid>
      <DownloadExcel downloadExcel={downloadExcel} />
      <TilbakestillValgKnapp tilbakestillBrukervalg={tilbakestillBrukervalg} />
      <Grid item sx={{ pt: "30px !important" }}>
        {excelLoading && <LoadingSpinner />}
      </Grid>
    </Grid>
  );
};
export default AktiviteterOptions;
