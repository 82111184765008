export type PlanType = {
  startAar: number;
  sluttAar: number;
  id: string;
  status: number;
  planlegging: string | null;
  beskrivelseLokalsamfunn: string | null;
  beskrivelseRessurser: string | null;
  beskrivelseUtfordringer: string | null;
  sistEndretLokalsamfunn: string;
  sistEndretRessurser: string;
  sistEndretUtfordringer: string;
  sistEndretPlanlegging: string;
  lokalsamfunnSistEndretAv: string | null;
  ressurserSistEndretAv: string | null;
  utfordringerSistEndretAv: string | null;
  planleggingSistEndretAv: string | null;
  sistEndret?: string;
  lenker?: LenkeType[];
};

export type LenkeType = {
  id: string;
  plan: string;
  lenkeTekst: string;
  lenkeUrl: string;
};

export type PlanerData = {
  id: string;
  sokn: string;
  fellesrad: string;
  prosti: string;
  bispedomme: string;
  organisasjonsnummer: number;
  startAar: number;
  sluttAar: number;
  planlegging: string | null;
  beskrivelseLokalsamfunn: string | null;
  beskrivelseRessurser: string | null;
  beskrivelseUtfordringer: string | null;
};
export type PlanerTall = {
  id: string;
  sokn: string;
  fellesrad: string;
  prosti: string;
  bispedomme: string;
  organisasjonsnummer: number;
  planperiode: string;
  planlegging: boolean;
  beskrivelseLokalsamfunn: boolean;
  beskrivelseRessurser: boolean;
  beskrivelseUtfordringer: boolean;
  sistEndret: string;
  satsinger: number;
};
export type BaseAlleTall = {
  id: string;
  navn: string;
  antallPlaner: number;
  antallPlanlegginger: number;
  antallBeskrivelser: number;
  antallRessurser: number;
  antallUtfordringer: number;
  antallSatsinger: number;
  sistEndret: string;
};

export type AlleTallPlan = {
  id: string;
  navn: string;
  antallPlanlegginger: number;
  antallBeskrivelser: number;
  antallRessurser: number;
  antallUtfordringer: number;
  antallSatsinger: number;
  sistEndret: string;
};

export type AlleTallPlanSokn = BaseAlleTall & {
  planer: AlleTallPlan[];
};

export type AlleTallPlanFellesrad = BaseAlleTall & {
  underliggendeSokn: AlleTallPlanSokn[];
};

export type AlleTallPlanProsti = BaseAlleTall & {
  underliggendeFellesrad: AlleTallPlanFellesrad[];
};

export type AlleTallPlanBispdomme = BaseAlleTall & {
  underliggendeProstier: AlleTallPlanProsti[];
};

export type AlleSoknType = {
  id: string;
  navn: string;
  organisasjonsnummer: string;
  bispedommeNavn: string;
  bispedommeId: string;
  sistEndret?: string;
};

export type BispedommeType = {
  id: string;
  navn: string;
  organisasjonsnummer: number;
};

export type FellesradType = {
  id: string;
  navn: string;
  organisasjonsnummer: string;
};

export type SoknType = {
  id: string;
  navn: string;
  visjon: string;
  kommune: string;
  bispedomme: null;
  fellesrad: FellesradType;
  organisasjonsnummer: string;
  opprettetDato: Date;
  sistEndret: Date;
  planer: PlanType[];
  malform: string;
  checked: boolean;
  tilgang: TilgangsNivaa;
  gronnkirke: Gronnkirke;
};

export enum TilgangsNivaa {
  ingen = 0,
  les = 1,
  skriv = 2,
  admin = 3,
}

export type BrukerType = {
  id: string;
  navn: string;
  jobbTittel: string;
  telefon: string;
  epost: string;
  tilgang: TilgangsNivaa;
};

export type SistEndret = {
  endretObjekt: EndretObjekt[];
};

export type EndretObjekt = {
  id: string;
  identifikator: string;
  objektType: number;
  sistEndret: string;
};

export type StatusTallType = {
  satsinger: number;
  endring_Satsinger: number;
  aktiviteter: number;
  endring_Aktiviteter: number;
  filer: number;
  endring_Filer: number;
  evalueringer: number;
  endring_Evalueringer: number;
  deltakere: number;
  endring_Deltakere: number;
  harPlan: boolean;
};

export type SkjerikirkenEvent = {
  id: string;
  title: string;
  slug: string;
  startTime: Date;
  church: { locationName: string };
};

export type AktivitetDto = {
  tittel: string;
  id: string;
  aktivitetSokn: AktivitetSoknType[];
};

export type SatsingData = {
  id: string;
  sokn: string;
  fellesrad: string;
  prosti: string;
  bispedomme: string;
  organisasjonsnummer: number;
  medlemstall?: number;
  overskrift: string;
  beskrivelse: string;
  planStartAar: number;
  planSluttAar: number;
  medlemmerUnge?: number;
  antallAktiviteter?: number;
};

export type SatsingType = {
  id: string;
  overskrift: string;
  beskrivelse: string;
  opprettetDato: string;
  sistEndret: string;
  aktiviteter: AktivitetDto[];
  nummerering: number;
};

export type SatsingDto = {
  id: string;
  overskrift: string;
  soknId: string;
  plan: PlanType;
};

export type SideModulType = {
  _type: string;
  _key: string;
};

export type LinkType = {
  lenketittel: string;
  lenketittelLokal: string;
  href: string;
  blank?: boolean;
};

export type HjelpRessurserType = {
  bilde?: ImageWithAltType;
  tittel: string;
  linker: LinkType[];
  tittelLokal: string;
};

export type SideInnholdType = {
  _id: string;
  menighetensTallPageTitle?: string;
  tittelLokal: string;
  beskrivelseLokal?: BlockType;
  leggHjelpRessurser?: boolean;
  hjelpRessurser?: HjelpRessurserType;
  graf?: string;
  grafTittle?: string;
};

export type BlockType = {
  _key: string;
  _type: string;
  blocks: unknown[] | unknown;
};

export type FaneType = {
  _key: string;
  visfanen: boolean;
  tittel: string;
  tekst: BlockType;
  tittelLokal: string;
  tekstLokal: BlockType;
};

export type FanerType = {
  _key: string;
  visfanen: boolean;
  tittel: string;
  tittelLokal: string;
  avsnittInnhold: AvsnittType[];
};

export type AvsnittType = {
  _key: string;
  avsnittId: string;
  tekst: BlockType;
  tekstLokal: BlockType;
};

export type VeiledningKapitler = {
  _key?: string;
  slug?: string;
  kapTittel?: string;
  kapTittelLokal?: string;
  vispaforsiden?: boolean;
  kapBoks?: {
    beskrivelse?: string;
    beskrivelseLokal?: BlockType;
    ikon?: ImageWithAltType;
  };
  faner?: FaneType[];
  fanerInnhold?: FanerType[];
};

export type VeiledningInnholdType = {
  veiledningPageTitle?: string;
  topText?: {
    tittel?: string;
    tittelLokal?: string;
    beskrivelse?: BlockType;
    beskrivelseLokal?: BlockType;
    knappetekstlLokal?: string;
    boksoverskrift?: string;
    video: any;
    videoTittel?: string;
  };
  veiledningKapitler: VeiledningKapitler[];
};

export type MaanedType = {
  id: string;
  navn: string;
  nummer: number;
  checked: boolean;
};

export type AldersgruppeType = {
  id: string;
  tittel: string;
  fraAar: number;
  tilAar: number;
  opprettetDato: Date | string;
  aktiv: boolean;
  ansvarlig?: string;
  checked: boolean;
};

export type KategoriType = {
  id: string;
  navn: string;
  fraAar?: number;
  tilAar?: number;
  opprettetDato: Date | string;
  aktiv: boolean;
  checked: boolean;
  ansvarlig?: string;
  underkategorier?: KategoriType[];
  hovedkategoriId?: string;
};

export type TrosopplaringType = {
  samarbeidsokn?: string;
  typeTiltak?: TypeTiltak;
  id: string;
  sistEndret: string;
};

export enum TypeTiltak {
  Aktivt,
  Passivt,
  Øvrig,
}

export type AktivitetType = {
  id: string;
  tittel: string;
  beskrivelse: string;
  sistEndret: string;
  sistEndretIKirkaVar?: string;
  sistEndretAv: string;
  startDato: Date;
  sluttDato?: Date | null;
  antallSamlinger?: number | null;
  ansvarlig: string;
  opprettetDato: Date | string;
  maaneder: MaanedType[];
  aldersgrupper: AldersgruppeType[];
  kategorier: KategoriType[];
  vedlegg?: string;
  satsinger: SatsingDto[];
  aktivitetSokn: AktivitetSoknType[];
  trosopplaringAktivitet: TrosopplaringType;
};
export type AktivitetStatistikkType = {
  aar: number;
  antallAktiviteter: number;
  antallAktiviteterEvaluert: number;
  antallSamarbeidAktiviteter: number;
  antallDeltakereTotalt: number;
  antallDeltakereFraTrosopplaring: number;
  antallDeltakereFraKirkaVar: number;
  aktivitetPerAldersgruppe: AktivitetPerAldersgruppe[];
  aktivitetPerKategori: AktivitetPerKategori[];
};

export type AktivitetPerAldersgruppe = {
  aldersgruppe: string;
  antallAktiviteter: number;
};

export type AktivitetPerKategori = {
  kategori: string;
  antallAktiviteter: number;
};

export type AktivitetSoknType = {
  soknId: string;
  soknNavn: string;
  originalSokn: boolean;
  godkjent?: boolean | null;
  godkjentDato?: Date | null;
  checked: boolean;
};

export type AktivitetData = {
  id: string;
  sokn: string;
  fellesrad: string;
  prosti: string;
  bispedomme: string;
  organisasjonsnummer: number;
  tittel: string;
  aldersgrupper: string[];
  kategorier: string[];
  startDato: Date;
  sluttDato?: Date | null;
  antallSamlinger?: number | null;
  beskrivelse: string;
  ansvarlig?: string;
  satsinger?: string[];
  evalueringTekst?: string;
  antallDeltakere?: number;
};

export type EvalueringType = {
  id: string;
  endretAv: string;
  opprettetDato: string;
  sistEndret: string;
  evalueringTekst: string;
  antallDeltakere: number | null;
  aar: number;
  aktivitet?: AktivitetType | null;
  aktivitetId?: string | null;
};

export type Vedlegg = {
  dato: number;
  navn: string;
  blobNavn: string;
  storrelse: number;
};

export type PlanInnhold = {
  _type: string;
  menyNavn: string;
  tittelLokal: string;
  beskrivelseLokal?: TittelOgBeskrivelse;
  leggHjelpRessurser?: boolean;
  hjelpRessurser?: HjelpRessurserType;
};

export type PlanSideInnholdType = {
  planlegging: PlanInnhold;
  menighetOgLokalsamfunn: PlanInnhold;
  menighetensRessurser: PlanInnhold;
  utfordringer: PlanInnhold;
  satsinger: PlanInnhold;
  uttrekkRapporter: PlanInnhold;
};

export type SoknetITallInnholdType = {
  Statistikk: SoknetITallInnhold;
  AktivitetStatistikk: SoknetITallInnhold;
  Kommunefakta: SoknetITallInnhold;
  Kart: SoknetITallInnhold;
};

export type SoknetITallInnhold = {
  _type: string;
  menyNavn: string;
  menyNumber: number;
  tittelLokal: string;
  beskrivelseLokal?: BlockType;
  leggHjelpRessurser?: boolean;
  hjelpRessurser?: HjelpRessurserType;
};

export type AdminLedetekster = {
  adminMenighet?: string;
  planperiodeOverskrift?: string;
  planperiodeBeskrivelse?: string;
  planperiodeKnapp?: string;
  opprettNyPlanperiodeOverskrift?: string;
  opprettNyPlanperiodeBeskrivelse?: string;
  redigerPlanperiodeOverskrift?: string;
  redigerPlanperiodeBeskrivelse?: string;
  planperiodeFor?: string;
  finnesIngenPlanperiode?: string;
  finnesIngenPlanaar?: string;
  opprettNyPlanperiodeSuksess?: string;
  oppdaterNyPlanperiodeSuksess?: string;
  slettPlanperiodeOverskrift?: string;
  slettPlanperiodeBeskrivelse?: string;
  slettPlanperiodeSuksess?: string;
  brukereMedTilgangOverskrift?: string;
  brukereMedTilgangUndertekst?: BlockType;
  ikkeTilgangTilAdminsider?: string;
  brukertabellNavn?: string;
  brukertabellJobbtittel?: string;
  brukertabellTelefon?: string;
  brukertabellEpost?: string;
  brukertabellRolle?: string;
  endreVisjontekst?: string;
  oppdaterVisjontekst?: string;
  lastOppBilde?: string;
  lastOppBildeBeskrivelse?: string;
  velgBildeButtonTekst?: string;
  lastOppBildeButtonTekst?: string;
  slettBildeButtonTekst?: string;
};

export type AdminSideInnholdType = {
  _id: string;
  administrasjonsSideTitle?: string;
  adminTittelLokal?: string;
  adminBeskLokal?: string;
  velgMalform?: string;
  malformNynorsk?: string;
  malformBokmal?: string;
  ledetekster?: AdminLedetekster;
};

export type AlleDataSideInnholdType = {
  _id: string;
  alledataSideTitle?: string;
  alledataTittelLokal?: string;
  alledataBeskLokal?: string;
  ledetekster?: AlleDataLedetekster;
};

export type AlleDataLedetekster = {
  feltKanIkkeFjernes?: string;
  soknKolonneTekst?: string;
  fellesradKolonneTekst?: string;
  prostiKolonneTekst?: string;
  bispedommeKolonneTekst?: string;
  orgnummerKolonneTekst?: string;
  medlemstallKolonneTekst?: string;
  medlemmerungeKolonneTekst?: string;
  planperiodeKolonneTekst?: string;
  satsingnavnKolonneTekst?: string;
  satsingbeskrivelseKolonneTekst?: string;
  antallaktiviteterKolonneTekst?: string;
  beskrivelseKolonneTekst?: string;
  ressurserKolonneTekst?: string;
  ufordringerKolonneTekst?: string;
  tittelKolonneTekst?: string;
  aldersgruppeKolonneTekst?: string;
  kategorierKolonneTekst?: string;
  ansvarligKolonneTekst?: string;
  satsingerKolonneTekst?: string;
  evalueringKolonneTekst?: string;
  deltakereKolonneTekst?: string;
  antallSamlingerKolonneTekst?: string;
  avkryssetKolonneTekst?: string;
  egneTiltakKolonneTekst?: string;
  tilsluttetKolonneTekst?: string;
  innsendtDatoKolonneTekst?: string;
};

export type enkeltAktiviteter = {
  tittel?: string;
  dato?: string;
  tekstLokal?: BlockType;
};

export type SisteAktiviteter = {
  nyheterPageTitle: string;
  tittelLokal?: string;
  linkenTekst?: string;
  enkeltAktiviteter?: enkeltAktiviteter[];
};

export type enkeltSnarvei = {
  tittel: string;
  href: string;
  icon?: ImageWithAltType;
  tooltip?: string;
};
export type Snarveier = {
  tittelLokal?: string;
  enkeltSnarveier?: enkeltSnarvei[];
};

export type enkeltStatusTall = {
  tittel?: string;
  undertittel?: string;
  href?: string;
  tooltip?: string;
};

export type StatusTall = {
  tittelLokal?: string;
  enkeltStatusTall?: enkeltStatusTall[];
};

export type ForsideLedetekster = {
  nyligEndretTekst?: string;
  planTekst?: string;
  aktivitetTekst?: string;
  satsingTekst?: string;
  aktiviteterFraTekst?: string;
  hvaSkjerNesteUkeTekst?: string;
  menighetenTallTekst?: string;
  antallTekst?: string;
  endringTekst?: string;
  dapshandlingerTekst?: string;
  medlemmerTekst?: string;
  konfirmanterTekst?: string;
  seFlereTallForMenighetenanterTekst?: string;
  testVersjonAdvarsel?: string;
  seKartOverMenigheten?: string;
  statusForSoknetsPlan?: string;
  ikkeOpprettetPlanTekst?: string;
  linkTilOpprettPlanTekst?: string;
};

export type MenighetensDokumenter = {
  tittel: string;
  tekst?: BlockType;
};

export type EnkeltPlanNyhet = {
  tittel: string;
  href: string;
};
export type NyttOmPlanProsessen = {
  tittel: string;
  enkeltPlanNyheter: EnkeltPlanNyhet[];
};

export type EnkeltBunntekstBoxType = {
  tittel?: string;
  tekst?: BlockType;
};

export type BunntekstBoxType = {
  bunntekstBokser?: EnkeltBunntekstBoxType[];
  copyrightText?: string;
};

export type RichTextType = SideModulType & {
  content: BlockType[];
  faktaboks?: boolean;
};

export type ForsideInnholdType = {
  _id: string;
  soknPageTitle: string;
  headerTittelLokal: string;
  forsideTittelLokal: string;
  forsideBeskrivelse?: RichTextType;
  kartBoksTittel?: string;
  sisteAktiviteter: SisteAktiviteter;
  snarveier: Snarveier;
  statusTall: StatusTall;
  menighetensDokumenter: MenighetensDokumenter;
  nyttOmPlanProsessen: NyttOmPlanProsessen;
  bunntekstBoks: BunntekstBoxType;
  ledetekster: ForsideLedetekster;
};

export type AktiviteterLedetekster = {
  leggTilAktivitetTekst?: string;
  registrerAktivitetTekst?: string;
  aktivitetFinnesIkke?: string;
  samarbeidVisAktivitetSide?: string;
  detteErEtSamarbeid?: string;
  redigereTrosopplaering?: string;
  navnPaTiltaketTekst?: string;
  aldersgruppeTekst?: string;
  aldersgrupperTekst?: string;
  kategoriTekst?: string;
  kategorierTekst?: string;
  alleKategorierTekst?: string;
  velgKategoriTekst?: string;
  trosopplaringTekst?: string;
  gudstjenesteTekst?: string;
  religionsdialogTekst?: string;
  alleAldersgrupperTekst?: string;
  velgAldersgrupperTekst?: string;
  musikkKulturTekst?: string;
  barnUngeTekst?: string;
  barnehageSkoleKirkeSamarbeidTekst?: string;
  okumenikkTekst?: string;
  misjonTekst?: string;
  diakoniTekst?: string;
  pilegrimTekst?: string;
  vedleggsdeltittel?: string;
  lastOppVedlegg?: string;
  forklaring?: string;
  lastNed?: string;
  slette?: string;
  sletteVedlegg?: string;
  sikkerSletteVedlegg?: string;
  ingenVedleggEnnaa?: string;
  knyttTilSatsingTekst?: string;
  velgSatsingTekst?: string;
  oppstartsmanedTekst?: string;
  velgManedTekst?: string;
  aktivitetRegistrertTekst?: string;
  aktivitetOppdatertTekst?: string;
  sletteAktivitetTekst?: string;
  sikkerSletteAktivitetenTekst?: string;
  aktivitetenBleSlettetTekst?: string;
  vedleggTekst?: string;
  satsingTekst?: string;
  finnerIngenAktiviteterTekst?: string;
  finnerIkkeSatsingTekst?: string;
  harIkkeTilgang?: string;
  opprettSatsingLinkTekst?: string;
  ansvarligTekst?: string;
  settVarighetPaaAktivitetTekst?: string;
  antallSamlingerTekst?: string;
  dataErImportertTekst?: string;
  varighetTekst?: string;
  redigerAlderOgKategori?: string;
  redigerAndreFelter?: string;
  opprettSamarbeid?: string;
  samarbeidCheckTekst?: string;
  aktivitetenErSamarbeid?: string;
  aktivitetenErSamarbeidMellom?: string;
  aktivitetOpprettetAv?: string;
  kanIkkeAvslutteSamarbeid?: string;
  alleredeInngattSamarbeid?: string;
  enesteSoknIProsti?: string;
  trosopplaringKanIkkeFjernes?: string;
  svarIkkeRegistrert?: string;
  henteAktivitetError?: string;
  samarbeidGodkjentTekst?: string;
  samarbeidAvslattTekst?: string;
  samarbeidForesporsel?: string;
  avslaSamarbeid?: string;
  godtaSamarbeid?: string;
  forespurtSamarbeid?: string;
  inngaSamarbeid?: string;
  aktivitetIkkeRegistrert?: string;
  planKunneIkkeHentes?: string;
  aktivitetMaHaTittel?: string;
  aktivitetKunneIkkeOppdateres?: string;
  aktivitetenKunneIkkeSlettes?: string;
  typeTiltak?: string;
  typeTiltak2?: string;
  typeTiltakUtvidet?: string;
  importertTekst?: string;
  samarbeidsoknTekst?: string;
  trosopplaringstypeTekst?: string;
  visAarshjul?: string;
  maaned?: string;
  lastNedUtenEvaluering?: string;
  lastNedMedEvaluering?: string;
  lastNedTabell?: string;
  lastNedAarshjul?: string;
};

export type AktiviteterSideInnholdType = {
  _id: string;
  aktiviteterPageTitle?: string;
  tittelLokal?: string;
  beskrivelseLokal?: BlockType;
  evalueringSeksjon?: EvalueringSeksjonType;
  leggHjelpRessurser?: boolean;
  hjelpRessurser?: HjelpRessurserType;
  ledetekster: AktiviteterLedetekster;
};

export type EvalueringSeksjonType = {
  _id: string;
  evalueringPageTitle?: string;
  tittel?: string;
  beskrivelse?: string;
  velgEvaluering?: string;
  finnesIkke1?: string;
  finnesIkke2?: string;
  registrerEvaluering?: string;
  oppdaterEvaluering?: string;
  redigerEvalueringTekst?: string;
  redigerAntallDeltakere?: string;
  visEvalueringTilbake?: string;
  visEvalueringTittel?: string;
  visEvalueringAar?: string;
  visAntallDeltakere?: string;
  visEvalueringTekst?: string;
  visEvalueringHentetFraTrosopplaring?: string;
  visEvalueringSistEndret1?: string;
  visEvalueringSistEndret2?: string;
  visEvalueringTekstTom?: string;
  popupRegistrert?: string;
  popupOppdatert?: string;
  popupSlettet?: string;
  slettDialogBoxTittel?: string;
  erDuSikkerSlett?: string;
  EvalueringMaaEndresITrosopplaring?: string;
};

export type Gronnkirke = {
  id: string;
  tilsluttetGronnkirke: boolean;
  innsendt: Date;
  sistEndret: Date;
  gronnkirkeEgneTiltak: GronnkirkeEgetTiltak[];
  antallAvkryssedePunkter: number;
  gronnkirkePoints: GronnkirkePointType[];
};

export type GronnkirkeEgetTiltak = {
  id?: string;
  tiltakTekst: string;
};

export type GronnkirkePointType = {
  id: string;
  punktNummer: number;
  checked: boolean;
};

export type ImageType = {
  _ref: string;
  _type: string;
  asset: {
    _ref: string;
    _type: string;
  };
  crop: {
    _type: "sanity.imageCrop";
    bottom: number;
    left: number;
    right: number;
    top: number;
  };
  hotspot: {
    _type: "sanity.imageHotspot";
    height: number;
    width: number;
    x: number;
    y: number;
  };
  metadata?: {
    location?: {
      lat: number;
      lon: number;
    };
    palette?: {
      dominant: {
        background: string;
        foreground: string;
      };
    };
    lqip?: string;
  };
};

export type ImageWithAltType = ImageType &
  SideModulType & {
    _key: string;
    bilde: ImageType;
    alt: string;
    fullbredde: boolean;
    bredde?: number;
    bildetittel?: string;
    bildebeskrivelse?: string;
  };

export type ImageWithDescription = {
  bilde: ImageWithAltType;
  bildetittel?: string;
  bildebeskrivelse?: string;
};

export type VimeoVideoType = {
  url: string;
  tittel?: string;
  videobeskrivelse?: string;
  bredde?: number;
  fullbredde: boolean;
};

export type FaktaboksType = {
  tittel: string;
  tekst: RichTextType;
};

export type LedeteksterPlan = {
  planPageTitle?: string;
  velgPlanTekst?: string;
  opprettNyPlanperiodeTekst?: string;
  adminTilgangTekst?: string;
  leggBeskrivelseTekst?: string;
  redigerBeskrivelseTekst?: string;
  leggSatsingerTekst?: string;
  opprettNySatsingTekst?: string;
  satsingenBleOpprettetTekst?: string;
  satsingenBleSlettetTekst?: string;
  sletteSatsingTekst?: string;
  sikkerSletteSatsingenTekst?: string;
  knyttAktivitetTilSatsingenTekst?: string;
  linkTilAktiviteter?: string;
  linkTilAktiviteterVeileldning?: string;
  aktiviteterOgTiltak?: string;
  ikkeTilknyttetAktivitet?: string;
  satsingBleOppdatert?: string;
  satsingBleIkkeOppdatert?: string;
  satsingBleIkkeOppdatert2?: string;
  ingenTreffSok?: string;
  begynnASkrivSok?: string;
  sokForALeggeTil?: string;
};

export type LedeteksterMeny = {
  veiledningMenypunkt?: string;
  menighetenITallMenypunkt?: string;
  planMenypunkt?: string;
  aktiviteterMenypunkt?: string;
  hjemMenypunkt?: string;
  merMenypunkt?: string;
  gronnkirkeMenypunkt?: string;
  nyheterMenypunkt?: string;
};

export type Ledetekster = {
  alleSoknPageTitle?: string;
  aktivitetTekst?: string;
  helhetligMenighetsplanTekst?: string;
  underoverskriftSoknvelgerside?: string;
  loggBrukernavnPassordTekst?: string;
  beskrivelseTekst?: string;
  tittelTekst?: string;
  evaluereTekst?: string;
  visTekst?: string;
  oppdaterTekst?: string;
  kreverSkrivetilgang?: string;
  detSkjeddeFeil?: string;
  velgModulTekst?: string;
  lagreTekst?: string;
  opprettTekst?: string;
  avbrytTekst?: string;
  tekstenBleLagretTekst?: string;
  overskriftTekst?: string;
  slettTekst?: string;
  redigerTekst?: string;
  forsettRedigereTekst?: string;
  forkastEndringerTekst?: string;
  tilbakeTekst?: string;
  redigertekst?: string;
  ledeteksterPlan?: LedeteksterPlan;
  planarTekst?: string;
  startaarTekst?: string;
  sluttaarTekst?: string;
  ledeteksterMeny?: LedeteksterMeny;
  utskriftEllerPdfButtonTekst?: string;
  uttrekkWordButtonTekst?: string;
  problemerMedLoggInnTekst?: string;
  ingenSoknTilgangTekst?: string;
  skjemaetBekreftTekst?: string;
  soknKolonneTekst?: string;
  bispedommeKolonneTekst?: string;
  sistEndretKolonneTekst?: string;
  administrerSide?: string;
  tilMineSokn?: string;
  seAlleData?: string;
  giTilbakemelding?: string;
  alleData?: string;
  adminSide?: string;
};

export type LedeteksterRegistrerBrukerType = {
  _id?: string;
  errorMessage?: string;
  valideringFornavn?: string;
  valideringMellomnavn?: string;
  valideringEtternavn?: string;
  valideringTelefon?: string;
  valideringRadioButtons?: string;
  valideringKirkenbruker?: string;
  valideringEpost?: string;
  valideringFodselsdato?: string;
  valideringSokn?: string;
  skjemaTitle?: string;
  skjemaUndertekst?: string;
  innsendtSkjemaTittel?: string;
  innsendtSkjemaUndertekst?: string;
  ledetekster?: LedeteksterRegistrerBruker;
};

export type LedeteksterRegistrerBruker = {
  _id?: string;
  fulltNavn?: string;
  placeholderFornavn?: string;
  placeholderMellomnavn?: string;
  placeholderEtternavn?: string;
  telefonnummer?: string;
  placeholderTelefonnummer?: string;
  kirkenbrukerCheckbox?: string;
  kirkenbruker?: string;
  placeholderKirkenbruker?: string;
  epost?: string;
  placeholderEpost?: string;
  fodselsdato?: string;
  placeholderFodselsdato?: string;
  sokn?: string;
  undertekstSokn?: string;
  finnSoknKnapp?: string;
  endreSoknKnapp?: string;
  sendSkjemaKnapp?: string;
};

export type LedeteksterGronnkirkeType = {
  _id?: string;
  gronnkirkeTitle?: string;
  gronnkirkeIngress?: RichTextType;
  registrertinnsending?: string;
  komIGang?: string;
  tilsluttetGronnkirke?: string;
  bleTilsluttet?: string;
  tilslutningsAvtale?: string;
  egneTiltakOppdatert?: string;
  egneTiltakErrorPopup?: string;
  egneTiltak?: string;
  egneTiltakTekst?: string;
  redigerEgneTiltak?: string;
  termsAndConditionsGronnKirke?: string;
  obsHarIkkeNokTiltak?: string;
  tilsluttKnapp?: string;
  kunneIkkeOppdatereGronnkirke?: string;
  automatiskOppdatertGronnkirke?: string;
  gronnkirkeBilde?: ImageWithAltType;
  gronnkirkeMerke?: ImageWithAltType;
  temaer?: GronnkirkeTema[];
};

export type GronnkirkeTema = {
  _id: string;
  tittel?: string;
  undertekst?: string;
  gronnkirkePunkter?: GronnkirkePunkt[];
};

export type GronnkirkePunkt = {
  index?: number;
  gronnkirkePunkt?: string;
};

export type GronnkirkeData = {
  sokn: string;
  fellesrad: string;
  prosti: string;
  bispedomme: string;
  gronnkirkeInfo?: GronnkirkeInfo;
};

export type GronnkirkeInfo = {
  id: string;
  antallAvkryssedePunkter: number;
  antallEgneTiltak: number;
  tilsluttetGronnkirke: boolean;
  innsendt?: Date;
};

export type VedleggInnhold = {
  vedleggPlan?: TittelOgBeskrivelse;
  vedleggAktivitet?: TittelOgBeskrivelse;
  ledeteksterVedlegg?: LedeteksterVedlegg;
};

export type TittelOgBeskrivelse = {
  tittel?: string;
  beskrivelse?: BlockType;
};

export type LedeteksterVedlegg = {
  lastOppVedlegg?: string;
  lastNed?: string;
  slette?: string;
  sletteVedlegg?: string;
  sikkerSletteVedlegg?: string;
  ingenVedleggEnnaa?: string;
  vedleggetBleSlettet?: string;
};

export type LedeteksterLenker = {
  lenkerTittel?: string;
  lenkerUndertekst?: string;
  leggTilLenkeKnapp?: string;
  redigereLenkeKnapp?: string;
  sletteLenkeKnapp?: string;
  lenkeDialogboxTittel?: string;
  sikkerSletteLenke?: string;
  lenkeBleSlettet?: string;
  lenkeBleEndret?: string;
  lenkeBleOpprettet?: string;
};

export type Feilmeldinger = {
  notFoundPageTitle?: string;
  validationError?: string;
  notFoundError?: string;
  planperiodeError?: string;
  http?: {
    firenullfire?: string;
    firenullen?: string;
    femhundre?: string;
    nettverksfeil?: string;
  };
  prostErIkkeLagtTilIBispedommet?: {
    ikkeRegistertIBispedomme?: string;
    sjekkEpost?: string;
    forsattFeil?: string;
  };
  uventetFeil?: string;
  velgModulTekst?: string;
};

export type PowerBIType = {
  type: string;
  embedReport: EmbedReportType[];
  embedToken: EmbedTokenType;
  visualId: string;
  pageName: string;
};

export type EmbedReportType = {
  reportId: string;
  reportName: string;
  embedUrl: string;
};

export type EmbedTokenType = {
  token: string;
  tokenId: string;
  expiration: string;
};
