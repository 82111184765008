import { groq } from "next-sanity";

export const alledataQuery = groq`
*[_type == "alledata" && !(_id in path('drafts.**'))][0] {
        "alledataSideTitle": coalesce(alledataSideTitle[$malform], alledataSideTitle.nb),
        "alledataTittelLokal": coalesce(alledataTittelLocale[$malform], alledataTittelLocale.nb),
        "alledataBeskLokal": coalesce(alledataBeskLocale[$malform], alledataBeskLocale.nb),
        ledetekster {
                "soknKolonneTekst": coalesce(soknKolonneTekst[$malform], soknKolonneTekst.nb),
                "fellesradKolonneTekst": coalesce(fellesradKolonneTekst[$malform], fellesradKolonneTekst.nb),
                "prostiKolonneTekst": coalesce(prostiKolonneTekst[$malform], prostiKolonneTekst.nb),
                "bispedommeKolonneTekst": coalesce(bispedommeKolonneTekst[$malform], bispedommeKolonneTekst.nb),
                "orgnummerKolonneTekst": coalesce(orgnummerKolonneTekst[$malform], orgnummerKolonneTekst.nb),
                "medlemstallKolonneTekst": coalesce(medlemstallKolonneTekst[$malform], medlemstallKolonneTekst.nb),
                "medlemmerungeKolonneTekst": coalesce(medlemmerungeKolonneTekst[$malform], medlemmerungeKolonneTekst.nb),
                "planperiodeKolonneTekst": coalesce(planperiodeKolonneTekst[$malform], planperiodeKolonneTekst.nb),
                "satsingnavnKolonneTekst": coalesce(satsingnavnKolonneTekst[$malform], satsingnavnKolonneTekst.nb),
                "satsingbeskrivelseKolonneTekst": coalesce(satsingbeskrivelseKolonneTekst[$malform], satsingbeskrivelseKolonneTekst.nb),
                "antallaktiviteterKolonneTekst": coalesce(antallaktiviteterKolonneTekst[$malform], antallaktiviteterKolonneTekst.nb),
                "antallSamlingerKolonneTekst": coalesce(antallSamlingerKolonneTekst[$malform], antallSamlingerKolonneTekst.nb),
                "avkryssetKolonneTekst": coalesce(avkryssetKolonneTekst[$malform], avkryssetKolonneTekst.nb),
                "egneTiltakKolonneTekst": coalesce(egneTiltakKolonneTekst[$malform], egneTiltakKolonneTekst.nb),
                "tilsluttetKolonneTekst": coalesce(tilsluttetKolonneTekst[$malform], tilsluttetKolonneTekst.nb),
                "innsendtDatoKolonneTekst": coalesce(innsendtDatoKolonneTekst[$malform], innsendtDatoKolonneTekst.nb),
                "feltKanIkkeFjernes": coalesce(feltKanIkkeFjernes[$malform], feltKanIkkeFjernes.nb),
              },
        
   }
`;

export default alledataQuery;
