import React, { createContext } from "react";

import { SoknType, TilgangsNivaa } from "../../utils/types";
import useLocalStorage from "../../utils/useLocalStorage";

export type AccessGroupContextType = {
  accessGroup: string;
  setAccessGroup: (group: string) => void;
};

export const AccessGroupContext = createContext<
  AccessGroupContextType | Record<string, never>
>({});

interface Props {
  children: React.ReactNode;
}

export const AccessGroupContextProvider: React.FC<Props> = ({ children }) => {
  const [accessGroup, setAccessGroup] = useLocalStorage("groups");

  return (
    <AccessGroupContext.Provider
      value={{
        accessGroup,
        setAccessGroup,
      }}
    >
      {children}
    </AccessGroupContext.Provider>
  );
};

export const setGroup = async (
  sokn: SoknType,
  setAccessGroup: (group: string) => void
) => {
  switch (sokn.tilgang) {
    case TilgangsNivaa.les: {
      setAccessGroup("les");
      break;
    }
    case TilgangsNivaa.skriv: {
      setAccessGroup("skriv");
      break;
    }
    case TilgangsNivaa.admin: {
      setAccessGroup("admin");
      break;
    }
    default: {
      setAccessGroup("ingen");
      break;
    }
  }
};
