import { groq } from "next-sanity";

export const gronnkirkeQuery = groq`
*[_type == "gronnkirke" && !(_id in path('drafts.**'))][0] {
        "gronnkirkeTitle": coalesce(gronnkirkeTitle[$malform], gronnkirkeTitle.nb),
        "gronnkirkeIngress": coalesce(gronnkirkeIngress[$malform], gronnkirkeIngress.nb),
        "registrertinnsending": coalesce(registrertinnsending[$malform], registrertinnsending.nb),
        "komIGang": coalesce(komIGang[$malform], komIGang.nb),
        "bleTilsluttet": coalesce(bleTilsluttet[$malform], bleTilsluttet.nb),
        "tilsluttetGronnkirke": coalesce(tilsluttetGronnkirke[$malform], tilsluttetGronnkirke.nb),
        "tilslutningsAvtale": coalesce(tilslutningsAvtale[$malform], tilslutningsAvtale.nb),
        "egneTiltakOppdatert": coalesce(egneTiltakOppdatert[$malform], egneTiltakOppdatert.nb),
        "egneTiltakErrorPopup": coalesce(egneTiltakErrorPopup[$malform], egneTiltakErrorPopup.nb),
        "egneTiltak": coalesce(egneTiltak[$malform], egneTiltak.nb),
        "egneTiltakTekst": coalesce(egneTiltakTekst[$malform], egneTiltakTekst.nb),
        "redigerEgneTiltak": coalesce(redigerEgneTiltak[$malform], redigerEgneTiltak.nb),
        "termsAndConditionsGronnKirke": coalesce(termsAndConditionsGronnKirke[$malform], termsAndConditionsGronnKirke.nb),
        "obsHarIkkeNokTiltak": coalesce(obsHarIkkeNokTiltak[$malform], obsHarIkkeNokTiltak.nb),
        "tilsluttKnapp": coalesce(tilsluttKnapp[$malform], tilsluttKnapp.nb),
        "kunneIkkeOppdatereGronnkirke": coalesce(kunneIkkeOppdatereGronnkirke[$malform], kunneIkkeOppdatereGronnkirke.nb),
        "automatiskOppdatertGronnkirke": coalesce(automatiskOppdatertGronnkirke[$malform], automatiskOppdatertGronnkirke.nb),
        gronnkirkeBilde,
        gronnkirkeMerke,
        temaer[] {
          _key,
          "tittel": coalesce(tittel[$malform], tittel.nb),
          "undertekst": coalesce(undertekst[$malform], undertekst.nb),
          gronnkirkePunkter[] {
            _key,
            index,
            "gronnkirkePunkt": coalesce(gronnkirkePunkt[$malform], gronnkirkePunkt.nb),
          },
        },
    }
`;

export default gronnkirkeQuery;
