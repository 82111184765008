import { useQuery } from "@tanstack/react-query";

import { httpGet } from "../../../services/HttpService";

const hentSokn = async (id: string) => {
  const res = await httpGet(`api/sokn/get/${id}`);
  return res;
};

export const useGetSokn = (id: string) =>
  useQuery({
    queryKey: ["soknQuery", id],
    queryFn: () => hentSokn(id),
    enabled: Boolean(id),
  });
