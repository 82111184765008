import { useEffect, useState } from "react";

import {
  Button,
  Grid,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { KirkeCard, LoadingSpinner } from "../../components/common";
import SearchInput from "../../components/SearchInput/SearchInput";
import { httpPost } from "../../services/HttpService";
import varslingPopup from "../../services/varslinger/varslingsService";
import kirkeTheme from "../../utils/kirkeTheme";
import {
  AlleSoknType,
  LedeteksterRegistrerBrukerType,
} from "../../utils/types";
import { useGetAlleSoknForRegistrering } from "./api";

interface Props {
  setHarRegistrert: React.Dispatch<React.SetStateAction<boolean>>;
  sanityData?: LedeteksterRegistrerBrukerType;
}

const RegistrerBrukerForm = ({ setHarRegistrert, sanityData }: Props) => {
  const [alleSokn, setAlleSokn] = useState<AlleSoknType[]>();
  const [fornavn, setFornavn] = useState<string>("");
  const [mellomnavn, setMellomnavn] = useState<string>("");
  const [etternavn, setEtternavn] = useState<string>("");
  const [telefonnummer, setTelefonnummer] = useState<string>("");
  const [harJobbetTidligere, setHarJobbetTidligere] = useState<boolean>(false);
  const [kirkenBruker, setKirkenBruker] = useState<string>("");
  const [epost, setEpost] = useState<string>("");
  const [fodselsdato, setFodselsdato] = useState<Date | null>(null);
  const [valgtSokn, setValgtSokn] = useState<AlleSoknType>();
  // Validering:
  const [isValidFornavn, setIsValidFornavn] = useState<boolean>(true);
  const [isValidMellomnavn, setIsValidMellomnavn] = useState<boolean>(true);
  const [isValidEtternavn, setIsValidEtternavn] = useState<boolean>(true);
  const [isValidEpost, setIsValidEpost] = useState<boolean>(true);
  const [isValidTelefonnummer, setIsValidTelefonnummer] =
    useState<boolean>(true);
  const [isValidRadioButton, setIsValidRadioButton] = useState<boolean>(false);
  const [isValidBrukernavn, setIsValidBrukernavn] = useState<boolean>(true);
  const [isValidFodselsdato, setIsValidFodselsdato] = useState<boolean>(true);
  useState<boolean>(true);

  const [registrerLoading, setRegistrerLoading] = useState<boolean>(false);
  const [filteredOptions, setFilteredOptions] = useState<AlleSoknType[]>();
  const [userFilterValue, setUserFilterValue] = useState("");
  const [ingenTreff, setIngenTreff] = useState<boolean>(false);
  const [soknId, setSoknId] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);

  const tenYearsAgo = new Date();
  tenYearsAgo.setFullYear(tenYearsAgo.getFullYear() - 10);
  const popupMeldingError = () => {
    varslingPopup.error(
      sanityData?.errorMessage ||
        "En feil oppstod og skjema ble ikke sendt. Sjekk internettforbindelsen din eller prøv igjen senere."
    );
  };

  useEffect(() => {
    const getFilteredItems = () => {
      let filteredItems;
      if (userFilterValue.length > 1) {
        const valueReadyForRegex = userFilterValue
          .replace(/\s/g, "")
          .toLowerCase();
        filteredItems = Array.isArray(alleSokn)
          ? alleSokn?.filter((sokn: any) =>
              sokn?.navn?.toLowerCase().includes(valueReadyForRegex)
            )
          : [];
      }
      return filteredItems;
    };
    setFilteredOptions(getFilteredItems());
  }, [userFilterValue, alleSokn]);

  useEffect(() => {
    if (filteredOptions?.length === 0) {
      setIngenTreff(true);
    } else {
      setIngenTreff(false);
    }
  }, [filteredOptions]);

  // Handle endringer i input
  const handleEksisterendeBrukerClick = (hasEksisterendeBruker: boolean) => {
    setIsValidRadioButton(true);
    if (!hasEksisterendeBruker) {
      setKirkenBruker("");
    }
    setIsValidBrukernavn(!hasEksisterendeBruker);
    setHarJobbetTidligere(hasEksisterendeBruker);
  };
  const handleFornavnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setFornavn(inputValue);
    validerFornavn(inputValue);
  };
  const handleMellomnavnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;
    setMellomnavn(inputValue);
    validerMellomnavn(inputValue);
  };
  const handleEtternavnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;
    setEtternavn(inputValue);
    validerEtternavn(inputValue);
  };
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setEpost(inputValue);
    validerEmail(inputValue);
  };
  const handleBrukernavnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;
    setKirkenBruker(inputValue);
    validerBrukernavn(inputValue);
  };
  const handleTelefonnummerChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const input = event.target.value;
    const regex = /^[0-9\b]+$/;
    if ((regex.test(input) && input.length <= 8) || input.length === 0) {
      setTelefonnummer(input);
      validerTelefonnummer(input);
    }
  };
  const handleDateChange = (date: Date | null) => {
    setFodselsdato(date);
    validerFodselsdato(date);
  };

  // Valideringsfunksjoner for input
  const validerFornavn = (input: string) => {
    const regex = /^[a-zA-ZÆØÅæøå -]+$/;
    if (input !== null && input?.length !== 0 && regex.test(input)) {
      setIsValidFornavn(true);
      return true;
    } else {
      setIsValidFornavn(false);
      return false;
    }
  };
  const validerMellomnavn = (input: string) => {
    const regex = /^[a-zA-ZÆØÅæøå -]+$/;
    console.log(regex.test(input));
    if (regex.test(input)) {
      setIsValidMellomnavn(true);
      return true;
    } else {
      setIsValidMellomnavn(false);
      return false;
    }
  };
  const validerEtternavn = (input: string) => {
    const regex = /^[a-zA-ZÆØÅæøå-]+$/;
    if (input !== null && input?.length !== 0 && regex.test(input)) {
      setIsValidEtternavn(true);
      return true;
    } else {
      setIsValidEtternavn(false);
      return false;
    }
  };
  const validerEmail = (input: string) => {
    if (input.length === 0) {
      setIsValidEpost(false);
      return false;
    }
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    setIsValidEpost(emailRegex.test(input));
    return emailRegex.test(input);
  };
  const validerTelefonnummer = (input: string) => {
    if (input.length === 0) {
      setIsValidTelefonnummer(false);
      return false;
    } else {
      const regex = /^\d{8}$/;
      setIsValidTelefonnummer(regex.test(input));
      return regex.test(input);
    }
  };
  const validerBrukernavn = (input: string) => {
    if (harJobbetTidligere) {
      const regex = /^[A-Za-z]{2}\d{3}$/;
      setIsValidBrukernavn(regex.test(input));
      return regex.test(input);
    } else {
      setIsValidBrukernavn(true);
      return true;
    }
  };
  const validerFodselsdato = (input: Date | null) => {
    if (input != null) {
      const thisYear = new Date().getFullYear();
      if (input.getFullYear() > thisYear - 10) {
        setIsValidFodselsdato(false);
        return false;
      } else {
        setIsValidFodselsdato(true);
        return true;
      }
    }
  };
  const validerSokn = () => {
    if (valgtSokn === undefined) {
      return false;
    }
    return true;
  };

  const validateAll = () => {
    const val1 = validerFornavn(fornavn);
    const val2 = validerMellomnavn(fornavn);
    const val3 = validerEtternavn(etternavn);
    const val4 = validerEmail(epost);
    const val5 = validerTelefonnummer(telefonnummer);
    const val6 = isValidRadioButton;
    const val7 = validerBrukernavn(kirkenBruker);
    const val8 = validerFodselsdato(fodselsdato);
    const val9 = validerSokn();
    return val1 && val2 && val3 && val4 && val5 && val6 && val7 && val8 && val9;
  };

  const submitForm = () => {
    if (validateAll()) {
      registrerNyBruker();
    }
    // Hvis input ikke er gyldig, gi tilbakemelding.
    else {
      if (!isValidFornavn) {
        varslingPopup.error(
          sanityData?.valideringFornavn ||
            "Sjekk at fornavn er fylt ut og ikke inneholder spesialtegn"
        );
      } else if (!isValidMellomnavn) {
        varslingPopup.error(
          sanityData?.valideringMellomnavn ||
            "Sjekk at mellomnavn ikke inneholder spesialtegn"
        );
      } else if (!isValidEtternavn) {
        varslingPopup.error(
          sanityData?.valideringEtternavn ||
            "Sjekk at etternavn er fylt ut og ikke inneholder mellomrom eller spesialtegn"
        );
      } else if (!isValidTelefonnummer) {
        varslingPopup.error(
          sanityData?.valideringTelefon || "Sjekk at telefonnummer er korrekt"
        );
      } else if (!isValidRadioButton) {
        varslingPopup.error(
          sanityData?.valideringRadioButtons ||
            "Vennligst velg om du allerede har gyldig kirken-bruker"
        );
      } else if (!isValidBrukernavn) {
        varslingPopup.error(
          sanityData?.valideringKirkenbruker ||
            "Sjekk at kirken-brukernavn er skrevet korrekt (eks: HM688)"
        );
      } else if (!isValidEpost) {
        varslingPopup.error(
          sanityData?.valideringEpost || "Sjekk at epostadressen er gyldig"
        );
      } else if (!isValidFodselsdato) {
        varslingPopup.error(
          sanityData?.valideringFodselsdato ||
            "Sjekk at fødselsdato er valgt, med riktig årstall"
        );
      } else if (valgtSokn === undefined) {
        varslingPopup.error(
          sanityData?.valideringSokn ||
            "Vennligst velg hvilket sokn du er valgt inn i"
        );
      }
    }
  };

  const formatDate = (date: Date | null): string => {
    if (date != null) {
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
      const year = date.getFullYear().toString();

      return `${day}.${month}.${year}`;
    } else {
      return "";
    }
  };

  useGetAlleSoknForRegistrering((data) => setAlleSokn(data));

  const registrerNyBruker = async () => {
    try {
      setRegistrerLoading(true);
      const datostring = formatDate(fodselsdato);
      await httpPost("api/NyBruker/PostNyBruker", {
        fornavn,
        mellomnavn,
        etternavn,
        telefonnummer,
        harJobbetTidligere,
        kirkenBruker,
        epost,
        fodselsdato: datostring,
        soknOrganisasjonsnummer: valgtSokn?.organisasjonsnummer,
      });
      setRegistrerLoading(false);
      setHarRegistrert(true);
    } catch (error: any) {
      setRegistrerLoading(false);
      popupMeldingError();
    }
  };

  const onFinnSoknClick = () => {
    setOpen(true);
    setSoknId("");
  };

  useEffect(() => {
    if (soknId !== "") {
      const valg = alleSokn?.find((s) => s.id === soknId);
      if (valg !== undefined) {
        setValgtSokn(valg);
        setOpen(false);
      }
    } else {
      setValgtSokn(undefined);
    }
  }, [soknId]);

  return (
    <Grid
      container
      item
      direction="column"
      flexDirection="column"
      marginBottom={kirkeTheme.spacing(16)}
      spacing={4}
      xs={12}
      sm={12}
      md={12}
      lg={8}
      data-testid="brukerform-container"
    >
      <Grid item>
        <KirkeCard
          title={`${
            sanityData?.skjemaTitle || "Skjema for tilgang til 'Kirka vår'"
          }`}
          subtitle1
          background="none"
          padding="0"
        >
          {sanityData?.skjemaUndertekst ||
            "Dette skjemaet skal sendes inn slik at alle brukere som ønsker det, får en bruker med tilgang til kirka vår. Har du allerede bruker er det ønskelig at du allikevel sender inn skjemaet slik at man får tilgang til korrekt sokn med riktig tilgangsgruppe."}
        </KirkeCard>
      </Grid>

      <Grid item>
        <Typography variant="boldText">
          {sanityData?.ledetekster?.fulltNavn || "Fullt navn"}
        </Typography>
        <br />
        <TextField
          label={sanityData?.ledetekster?.placeholderFornavn || "Fornavn"}
          required
          style={{ width: "30%" }}
          multiline
          error={!isValidFornavn}
          color={"info"}
          sx={{
            backgroundColor: kirkeTheme.palette.common.white,
            marginRight: "5%",
          }}
          onChange={handleFornavnChange}
          inputProps={{
            maxLength: 50,
            "data-testid": "rediger-fornavn-felt",
          }}
        />
        <TextField
          label={sanityData?.ledetekster?.placeholderMellomnavn || "Mellomnavn"}
          style={{ width: "30%" }}
          multiline
          error={!isValidMellomnavn}
          color={"info"}
          sx={{
            backgroundColor: kirkeTheme.palette.common.white,
            marginRight: "5%",
          }}
          onChange={handleMellomnavnChange}
          inputProps={{
            maxLength: 50,
            "data-testid": "rediger-mellomnavn-felt",
          }}
        />
        <TextField
          label={sanityData?.ledetekster?.placeholderEtternavn || "Etternavn"}
          required
          style={{ width: "30%" }}
          multiline
          error={!isValidEtternavn}
          color={"info"}
          sx={{
            backgroundColor: kirkeTheme.palette.common.white,
          }}
          onChange={handleEtternavnChange}
          inputProps={{
            maxLength: 50,
            "data-testid": "rediger-etternavn-felt",
          }}
        />
      </Grid>

      <Grid item>
        <Typography variant="boldText">
          {sanityData?.ledetekster?.telefonnummer || "Telefonnummer"}
        </Typography>
        <TextField
          label={sanityData?.ledetekster?.placeholderTelefonnummer || "Tlf"}
          required
          value={telefonnummer}
          error={!isValidTelefonnummer}
          color={"info"}
          fullWidth
          multiline
          sx={{
            backgroundColor: kirkeTheme.palette.common.white,
          }}
          onChange={handleTelefonnummerChange}
          inputProps={{
            maxLength: 8,
            "data-testid": "rediger-telefonnummer-felt",
          }}
        />
      </Grid>

      <Grid item>
        <Typography variant="boldText">
          {sanityData?.ledetekster?.kirkenbrukerCheckbox ||
            "Jeg har allerede en kirken.no-bruker (eks: HM688@kirken.no)"}
        </Typography>
        <RadioGroup name="radio-buttons-group">
          <FormControlLabel
            control={<Radio />}
            value="outlined"
            label="Ja"
            onClick={() => {
              handleEksisterendeBrukerClick(true);
            }}
            data-testid="eksisterende-bruker-checkbox"
          />
          <FormControlLabel
            control={<Radio />}
            value="soft"
            label="Nei"
            onClick={() => {
              handleEksisterendeBrukerClick(false);
            }}
          />
        </RadioGroup>
      </Grid>
      <Grid item display={harJobbetTidligere ? "" : "none"}>
        <Typography variant="boldText">
          {sanityData?.ledetekster?.kirkenbruker || "Kirkenbruker (eks: HM688)"}
        </Typography>
        <TextField
          label={sanityData?.ledetekster?.placeholderKirkenbruker || "Bruker"}
          value={kirkenBruker}
          fullWidth
          multiline
          error={!isValidBrukernavn}
          color={"info"}
          sx={{
            backgroundColor: kirkeTheme.palette.common.white,
          }}
          onChange={handleBrukernavnChange}
          inputProps={{
            maxLength: 5,
            "data-testid": "rediger-brukernavn-felt",
          }}
        />
      </Grid>

      <Grid item>
        <Typography variant="boldText">
          {sanityData?.ledetekster?.epost || "Personlig epost"}
        </Typography>
        <TextField
          label={sanityData?.ledetekster?.placeholderEpost || "Epost"}
          required
          fullWidth
          multiline
          error={!isValidEpost}
          color={"info"}
          sx={{
            backgroundColor: kirkeTheme.palette.common.white,
          }}
          onChange={handleEmailChange}
          inputProps={{
            maxLength: 100,
            "data-testid": "rediger-epost-felt",
          }}
        />
      </Grid>

      <Grid item>
        <Typography variant="boldText">
          {sanityData?.ledetekster?.fodselsdato || "Fødselsdato"}
        </Typography>
        <br />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label={sanityData?.ledetekster?.placeholderFodselsdato || "Dato*"}
            format="dd/MM/yyyy"
            onChange={handleDateChange}
            maxDate={tenYearsAgo}
            sx={{
              backgroundColor: kirkeTheme.palette.common.white,
              color: kirkeTheme.palette.common.black,
            }}
          />
        </LocalizationProvider>
      </Grid>

      <Grid item>
        <Typography variant="boldText">
          {sanityData?.ledetekster?.sokn || "Sokn"}
        </Typography>
        <br />
        <Typography>
          {sanityData?.ledetekster?.undertekstSokn ||
            "I hvilken menighet har du blitt valgt inn?"}
        </Typography>
        <br />
        {valgtSokn !== undefined ? (
          <TextField
            disabled
            required
            defaultValue={""}
            value={valgtSokn?.navn}
            sx={{
              backgroundColor: kirkeTheme.palette.common.white,
              width: "34%",
            }}
          ></TextField>
        ) : (
          <></>
        )}
        <Button
          variant="contained"
          onClick={onFinnSoknClick}
          color={"primary"}
          sx={{
            marginLeft: kirkeTheme.spacing(4),
            display: open ? "none" : "",
            marginTop: kirkeTheme.spacing(2),
            [kirkeTheme.breakpoints.down("sm")]: {
              maxWidth: "none",
            },
          }}
          data-testid="finn-sokn-button"
        >
          {soknId === ""
            ? sanityData?.ledetekster?.finnSoknKnapp || "Finn ditt sokn"
            : sanityData?.ledetekster?.endreSoknKnapp || "Endre sokn"}
        </Button>
      </Grid>

      <Grid item>
        <Grid
          sx={{
            padding: kirkeTheme.spacing(2),
            paddingTop: "0",
            display: open ? "" : "none",
            [kirkeTheme.breakpoints.down("sm")]: {
              padding: kirkeTheme.spacing(1),
            },
          }}
        >
          <SearchInput
            filteredOptions={filteredOptions}
            handleOnChange={setUserFilterValue}
            value={userFilterValue}
            setSoknId={setSoknId}
            ingenTreff={ingenTreff}
            defaultCheckedBox={true}
          />
        </Grid>
      </Grid>

      <Grid item>
        {registrerLoading ? (
          <LoadingSpinner />
        ) : (
          <Button
            variant="contained"
            onClick={submitForm}
            sx={{
              background: kirkeTheme.palette.success.main,
              [kirkeTheme.breakpoints.down("sm")]: {
                maxWidth: "none",
              },
            }}
            data-testid="send-skjema-button"
          >
            {sanityData?.ledetekster?.sendSkjemaKnapp || "Send inn skjema"}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default RegistrerBrukerForm;
